import image1 from "../../../img/figma/spine-1.png";
import image2 from "../../../img/figma/spine-2.png";
import '../specialties.styles.scss';
import headerImage from "../../../img/figma/spine_banner.png";

const SPINE_DATA = {
    pageTitle: 'Spine',
    headerComponent: {
        imageUrl: headerImage,
        title: "Spine",
        innerCopy: "Care and treatment to relieve pain and improve your quality of life",
        shouldHideHomeButton: true
    },
    textSection1: {
        bodyTextLine1: "The spine supports us in movement, in stillness, and even laying down. Alignment keeps the spine healthy, but sometimes discs slip, get compressed, or can degenerate over time and with overuse. The spine’s 31 segments consist of bones, tendons and ligaments that connect to the rest of the body. Nerve endings in the spine act as a channel to relay information from the brain to the rest of the body.",
        bodyTextLine2: "For this reason, musculoskeletal issues can be quite painful and debilitating, as the spine is central to the body and the brain. The spinal column is a large structure and can be affected by a wide range of issues. With injuries and conditions that can affect the spine, it’s important to find the best care options to preserve function and work toward recovery.",
    },
    interiorSections: [
        {
            title: "Conditions",
            bodyCopy: "Spine conditions we treat include: ",
            htmlCopy: "<ul><li>Bulging disc</li><li>Fracture</li><li>Herniated disc</li><li>Kyphosis</li><li>Osteoarthritis</li><li>Osteoporosis</li><li>Scoliosis</li><li>Slipped disc</li><li>Spinal cord compression</li><li>Spinal deformities</li><li>Spinal stenosis</li><li>Spondylolisthesis</li><li>Trauma injuries</li><li>Vertebral compression fracture</li></ul>",
            imageUrl: image1,
            id: 1
        },
        {
            title: "Treatment",
            htmlCopy: "<ul><li>Cervical and lumbar spine fusions</li><li>Cervical and thoracic discectomy and fusion</li><li>Cervical, thoracic, and lumbar laminectomy</li><li>Disc replacement surgery</li><li>Injections</li><li>Lateral and anterolateral lumbar fusion</li><li>Microsurgical techniques</li><li>Minimally invasive cervical foraminotomy</li><li>Minimally invasive lumbar microdiscectomy</li><li>Reconstructive procedures</li></ul>",
            imageUrl: image2,
            customClassDiv: 'reverse-row',
            id: 2
        }
    ],
    textSection2: {
        bodyTextLine1: "At Optum Care Orthopaedics and Spine, our specialists understand that spinal pain can deeply affect a patient’s overall quality of life. We always recommend conservative or minimally invasive treatments first. When an injury or condition requires more advanced treatment, we offer patients help to make informed decisions based on their unique goals. Our surgeons deliver comprehensive care and use innovative techniques with the goal of full recovery.",
    },
    providersCta: {
        htmlCopy: `<h3>Schedule an appointment</h3>
                    <p>Ready to talk with a top-rated orthopedic specialist in Las Vegas?</p>
                    <p class="small-text">Schedule an appointment with Optum Care Orthopaedics and Spine today.</p>
                  `,
        providersList: [
            {
                id:1,
                providerName: "Michael Daubs, MD",
                link: "/providers/michael-daubs"
            },
            {
                id:2,
                providerName: "Sukanta Maitra, MD",
                link: "/providers/sukanta-maitra"
            },
        ],
        button: {
            id: 1,
            link: 'tel:+17027248877',
            text: 'Call: 1-702-724-8877'
        }
    }
}

export default SPINE_DATA;
