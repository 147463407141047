import image1 from "../../../img/home-5.png";
import image2 from "../../../img/figma/shoulder-2.png";
import '../specialties.styles.scss';
import headerImage from "../../../img/figma/shoulder_banner.png";

const SHOULDER_DATA = {
    pageTitle: 'Shoulder',
    headerComponent: {
        imageUrl: headerImage,
        title: "Shoulder",
        innerCopy: "Care and treatment to relieve pain and improve your quality of life",
        shouldHideHomeButton: true
    },
    textSection1: {
        bodyTextLine1: "The shoulder is a complex ball-and-socket joint due to its numerous muscles and ligaments. It’s also the most flexible joint in the human body, as the arm can move and twist in every direction. The muscle system is significant for the stability and mobility of the shoulder joint, especially the rotator cuff. However, due to its increased range of motion, there’s a decrease in stability which can lead to injury and dislocation.",
        bodyTextLine2: "At Optum Care Orthopaedics and Spine, we perform a high volume of complex shoulder revision surgeries. This means that patients trust us to improve on other surgeons’ work. Shoulders are prone to pain and injury because they often perform repetitive activities. Whether it’s on the job, lifting heavy items, or playing sports like baseball or tennis, repetition can cause stress on the joints. Aging and general wear and tear can also cause pain and issues like the development of osteoarthritis.",
    },
    interiorSections: [
        {
            title: "Conditions",
            bodyCopy: "Shoulder conditions we treat include: ",
            htmlCopy: "<ul><li>Adhesive capsulitis, commonly known as frozen shoulder</li><li>Biceps tears</li><li>Bursitis</li><li>Dislocated or fractured shoulder</li><li>Impingement</li><li>Instability in the shoulder joint</li><li>Labrum tears</li><li>Osteoarthritis</li><li>Pinched nerves</li><li>Rotator cuff injuries</li><li>SLAP tear</li><li>Tendon rupture</li><li>Throwing injuries</li><li>Trauma-related injuries</li></ul>",
            imageUrl: image1,
            id: 1
        },
        {
            title: "Treatment",
            htmlCopy: "<ul><li>Arthroplasty</li><li>Arthroscopic surgery of the shoulder</li><li>Injections</li><li>Labral or biceps surgery</li><li>Ligament reconstruction</li><li>Reverse shoulder replacement</li><li>Rotator cuff tear repair</li><li>Total shoulder replacement</li></ul>",
            imageUrl: image2,
            customClassDiv: 'reverse-row',
            id: 2
        }
    ],
    textSection2: {
        bodyTextLine1: "At Optum Care Orthopaedics and Spine, our specialists understand that shoulder pain can affect a patient’s daily life. Our specialists will consider conservative or minimally invasive treatments first. When an injury or condition requires more advanced treatment, we will offer resources and explain all options so the patient can make informed decisions. We always consider the unique needs and goals of each patient. Our surgeons use innovative techniques and the latest technology with the goal of full recovery.",
    },
    providersCta: {
        htmlCopy: `<h3>Schedule an appointment</h3>
                    <p>Ready to talk with a top-rated orthopedic specialist in Las Vegas?</p>
                    <p class="small-text">Schedule an appointment with Optum Care Orthopaedics and Spine today.</p>
                   `,
        providersList: [
            {
                id:1,
                providerName: "Galen Kam, MD",
                link: "/providers/galen-kam"
            },
        ],
        button: {
            id: 1,
            link: 'tel:17027248877',
            text: 'Call: 1-702-724-8877'
        }
    }
}

export default SHOULDER_DATA;
