import image1 from "../../../img/figma/wrist_hand-1.png";
import image2 from "../../../img/figma/wrist_hand-2.png";
import '../specialties.styles.scss';
import headerImage from "../../../img/figma/wrist_hand_banner.png";

const WRIST_AND_HAND_DATA = {
    pageTitle: 'Wrist and hand',
    headerComponent: {
        imageUrl: headerImage,
        title: "Wrist and hand",
        innerCopy: "Care and treatment to relieve pain and improve your quality of life",
        shouldHideHomeButton: true
    },
    textSection1: {
        bodyTextLine1: "The wrists and hands give us the dexterity to enjoy sports and creative hobbies like drawing and playing guitar. The joints in these structures help us with practical daily tasks such as eating, drinking, bathing and writing.",
        bodyTextLine2: "The structures in the wrists and hands are both complex and delicate. The bones in the hands, fingers and wrists are quite small, in particular the distal bones in the fingertips, as well as the eight tiny bones that connect to the two larger bones in the forearm. Because these structures are in constant use, they’re more prone to accidents and repetitive stress injuries. Carpal tunnel, for example, occurs when the wrists are over worked over time. Depending on the condition, the level of pain and type of pain can vary greatly.",
    },
    interiorSections: [
        {
            title: "Conditions",
            bodyCopy: "Wrist and hand conditions we treat include: ",
            htmlCopy: "<ul><li>Carpal tunnel syndrome</li><li>De Quervain's disease</li><li>Distal or scaphoid fractures</li><li>Dupuytren's contracture</li><li>Ganglion cysts</li><li>Hyperextension injuries</li><li>Kienbock's disease</li><li>Ligament injuries</li><li>Osteoarthritis</li><li>Pinched nerves</li><li>Rheumatoid arthritis</li><li>Sprained wrist</li><li>Tendon injuries</li><li>Tendonitis</li><li>Trauma-related injuries</li><li>Trigger finger</li></ul>",
            imageUrl: image1,
            id: 1
        },
        {
            title: "Treatment",
            htmlCopy: "<ul><li>Arthroscopic surgery of the wrist</li><li>Endoscopic carpal tunnel release</li><li>Fracture care</li><li>Injections</li><li>Joint reconstruction of the wrist</li><li>Ligament reconstruction</li><li>Surgical nerve repair</li></ul>",
            imageUrl: image2,
            customClassDiv: 'reverse-row',
            id: 2
        }
    ],
    textSection2: {
        bodyTextLine1: "At Optum Care Orthopaedics and Spine, our specialists understand that wrist and hand pain can affect many aspects of daily life. We always recommend conservative or minimally invasive treatments first. When an injury or condition requires more advanced treatment, we provide patients the resources and options to make an informed decision based on their unique needs and goals. Our surgeons use innovative techniques and the latest technology with the goal of full recovery.",
    },
    providersCta: {
        htmlCopy: `<h3>Schedule an appointment</h3>
                    <p>Ready to talk with a top-rated orthopedic specialist in Las Vegas?</p>
                    <p class="small-text">Schedule an appointment with Optum Care Orthopaedics and Spine today.</p>
                  `,
        providersList: [
            {
                id:1,
                providerName: "Galen Kam, MD",
                link: "/providers/galen-kam"
            },
            {
                id:2,
                providerName: "Eugene Libby, DO, F.A.C.O.S",
                link: "/providers/eugene-libby"
            },
        ],
        button: {
            id: 1,
            link: 'tel:+17027248877',
            text: 'Call: 1-702-724-8877'
        }
    }
}

export default WRIST_AND_HAND_DATA;
