import { combineReducers } from 'redux';

import providerReducer from './provider/provider.reducer';
import articleReducer from "./article/article.reducer";
import regionReducer from "./region/region.reducer";

// const rootReducer = ({
//     provider: providerReducer,
//     article: articleReducer
// })

export default combineReducers({
    provider: providerReducer,
    article: articleReducer,
    region: regionReducer
})
