import placeHolderMaleGrid from '../../img/providers/provider_placeholder.jpg';
import placeHolderFemaleGrid from '../../img/providers/provider_placeholder.jpg';

import femalePlaceholder from '../../img/figma/Female-Placeholder.png';
import malePlaceholder from '../../img/figma/Male-Placeholder.png';

import imgPatrickAlix from '../../img/providers/patrick_alix.png';
import imgImranShaikh from '../../img/providers/Imran_Shaikh.png';
import imgAgatep from '../../img/providers/Agatep_Elmo.png';
import imgAssefa from '../../img/providers/Assefa_Yohannes.png';
import imgGhandi from '../../img/providers/Gandhi_Pratik.png';
import imgKnappRolf from '../../img/providers/Knapp_Rolf.png';
import imgBenNguyen from '../../img/providers/Nguyen_Ben.png';
import imgTangSusan from '../../img/providers/Tang_Susan.png';

import img1528062106 from '../../img/providers/1528062106.png';
import img1609305945 from '../../img/providers/1609305945.png';

import img1336500594 from '../../img/providers/1336500594.png';
import img1568768323 from '../../img/providers/1568768323.png';
import img1629517792 from '../../img/providers/1629517792.png';
import img1639367584 from '../../img/providers/1639367584.png';
import img1730138678 from '../../img/providers/1730138678.png';
import img1760574164 from '../../img/providers/1760574164.png';
import img1811145113 from '../../img/providers/1811145113.png';
import img1821268434 from '../../img/providers/1821268434.png';
import img1821473190 from '../../img/providers/1821473190.png';
import img1891286464 from '../../img/providers/1891286464.png';
import img1952808305 from '../../img/providers/1952808305.png';

import img1184003840 from '../../img/providers/1184003840.png';
import img1144886599 from '../../img/providers/1144886599.png';
import img1932765450 from '../../img/providers/1932765450.png';


import img1841619277 from '../../img/providers/provider_img-1841619277.png'
import img1477907970 from '../../img/providers/provider_img-1477907970.png'
import img1750477824 from '../../img/providers/provider_img-1750477824.png'
import img1154702157 from '../../img/providers/provider_img-1154702157.png'
import img1144280348 from '../../img/providers/provider_img-1144280348.png'
import img1235491838 from '../../img/providers/provider_img-1235491838.png'
import img1164862728 from '../../img/providers/provider_img-1164862728.png'
import img1386176352 from '../../img/providers/provider_img-1386176352.png'
import img1679860266 from '../../img/providers/provider_img-1679860266.png'
import img1376912691 from '../../img/providers/provider_img-1376912691.png'
import img1538208236 from '../../img/providers/provider_img-1538208236.png'
import img1578084604 from '../../img/providers/provider_img-1578084604.png'
import img1356862684 from '../../img/providers/provider_img-1356862684.png'
import img1093237786 from '../../img/providers/provider_img-1093237786.png'
import img1821027699 from '../../img/providers/provider_img-1821027699.png'
import img1922137173 from '../../img/providers/provider_img-1922137173.png'
import img1396064614 from '../../img/providers/provider_img-1396064614.png'
import img1699295758 from '../../img/providers/provider_img-1699295758.png'
import img1922479930 from '../../img/providers/provider_img-1922479930.png'
import img1235164971 from '../../img/providers/provider_img-1235164971.png'
import img1902816903 from '../../img/providers/provider_img-1902816903.png'
import img1518995133 from '../../img/providers/provider_img-1518995133.png'
import img1104245000 from '../../img/providers/provider_img-1104245000.png'
import img1932526514 from '../../img/providers/provider_img-1932526514.png'
import img1407350622 from '../../img/providers/provider_img-1407350622.png'
import img1578097275 from '../../img/providers/provider_img-1578097275.png'
import img1689193336 from '../../img/providers/provider_img-1689193336.png'
import img1700248879 from '../../img/providers/provider_img-1700248879.png'
import img1306942545 from '../../img/providers/provider_img-1306942545.png'
import img1265708531 from '../../img/providers/provider_img-1265708531.png'
import img1447505284 from '../../img/providers/provider_img-1447505284.png'
import img1447612361 from '../../img/providers/provider_img-1447612361.png'
import img1245736842 from '../../img/providers/provider_img-1245736842.png'
import img1477915452 from '../../img/providers/provider_img-1477915452.png'
import img1164421814 from '../../img/providers/provider_img-1164421814.png'
import img1437654811 from '../../img/providers/provider_img-1437654811.png'
import img1619408697 from '../../img/providers/provider_img-1619408697.png'
import img1932690484 from '../../img/providers/provider_img-1932690484.png'
import img1225370679 from '../../img/providers/provider_img-1225370679.png'
import img1851753370 from '../../img/providers/provider_img-1851753370.png'
import img1952807372 from '../../img/providers/provider_img-1952807372.png'
import img1780620021 from '../../img/providers/provider_img-1780620021.png'
import img1447348628 from '../../img/providers/provider_img-1447348628.png'
import img1568682979 from '../../img/providers/provider_img-1568682979.png'
import img1467457101 from '../../img/providers/provider_img-1467457101.png'
import img1841677390 from '../../img/providers/provider_img-1841677390.png'
import img1710932751 from '../../img/providers/provider_img-1710932751.png'
import img1326466285 from '../../img/providers/provider_img-1326466285.png'
import img1619521556 from '../../img/providers/provider_img-1619521556.png'
import img1841432507 from '../../img/providers/provider_img-1841432507.png'
import imgmichaelkarp from '../../img/providers/Michael-Karp.jpg'
import imglynettea from '../../img/providers/Lynette-Asselin.jpg';
import imgdianeb from '../../img/providers/diane-bedrosian.jpg';
import imgmichaelkrak from '../../img/providers/krak-michael.jpg';
import img1194111070 from '../../img/providers/1194111070.png';
import imgDaishaOrtega from '../../img/providers/DaishaOrtega.png';

const PROVIDERS_DATA = {
    sections: [
            {
                id: 1,
                imageUrl: img1841619277,
                providerInteriorImage: img1841619277,
                sex: "Male",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [1],
                linkUrl: 'adrian-tripp',
                publicationsAndResearch: [],
                providerName: "Adrian Tripp",
                providerTitle: "MD",
                npi: 1841619277,
                specialty: ["Internal Medicine"],
                phone: "1-626-331-6411",
                languages: ["English", "Spanish"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Adrian Tripp, MD, is board certified in internal medicine. Dr. Tripp earned his medical degree from the University of Southern California Keck School of Medicine in Los Angeles, CA. He completed his internship and residency at LAC + USC Medical Center, also in Los Angeles, CA. Dr. Tripp became a doctor to help people in underserved communities live healthier lives. He is passionate about Latinx community health and geriatric health. Dr. Tripp is friendly and works with each of his patients to solve their unique health problems. When he's not caring for his patients, Dr. Tripp enjoys boxing, hiking, gardening, reading and traveling. Dr. Tripp speaks English and Spanish.",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'University of Southern California, Keck School of Medicine'
                    },
                    {
                        type: 'Residency',
                        name: 'LAC + USC Medical Center'
                    },
                    {
                        type: 'Internship',
                        name: 'LAC + USC Medical Center'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - Covina Main",
                location2: " ",
                region: ["East San Gabriel Valley"]
            },
            {
                id: 2,
                imageUrl: img1750477824,
                providerInteriorImage: img1750477824,
                sex: "Female",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [2],
                linkUrl: 'lorindha-argudo',
                publicationsAndResearch: [],
                providerName: "Lorindha Francisca Argudo",
                providerTitle: "MD",
                npi: 1750477824,
                specialty: ["Internal Medicine"],
                phone: "1-909-861-2675",
                languages: ["English", "Indonesian", "Mandarin", "Spanish"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Lorindha Argudo, MD, practices internal medicine. Dr. Argudo earned her medical degree from Ohio State University College of Medicine. She completed her internship and residency at LA County-USC Medical Center. Dr. Argudo has a diverse cultural background and enjoys seeing patients of all ages and from all walks of life. She relates especially well to patients who speak English as a second language. Patients describe her as calm and professional. In her spare time, Dr. Argudo enjoys cooking and gardening. In addition to English, she speaks Mandarin and Indonesian. In addition, she has a basic understanding of Spanish medical terms.",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'Ohio State University Medical School'
                    },
                    {
                        type: 'Residency',
                        name: 'LAC + USC Medical Center'
                    },
                    {
                        type: 'Internship',
                        name: 'LAC + USC Medical Center'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: false,
                location: "Optum - Diamond Bar",
                location2: "",
                region: ["East San Gabriel Valley"]
            },
            {
                id: 3,
                imageUrl: img1154702157, //using existing image as examples
                providerInteriorImage: img1154702157,
                sex: "Male",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [3],
                linkUrl: 'marc-ghabbour',
                publicationsAndResearch: [],
                providerName: "Marc Ghabbour",
                providerTitle: "MD",
                npi: 1154702157,
                specialty: ["Internal Medicine"],
                phone: "1-626-335-4079",
                languages: ["English", "Arabic", "French"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Marc Ghabbour, MD, is board certified in internal medicine. Dr. Ghabbour earned his medical degree from Ross University of Medicine in Miramar, Florida. He completed his internship and residency at Florida Atlantic University in Boca Raton, Florida. An approachable and empathetic problem solver, Dr. Ghabbour provides each of his patients with the tools they need to make informed decisions about their health. His passion for helping people live their healthiest lives motivated him to pursue a career in medicine. Dr. Ghabbour strongly believes in treating every patient like a friend or family member. When he's not caring for his patients, Dr. Ghabbour enjoys boating, traveling, gardening, and spending time with family. In addition to English, Dr. Ghabbour also speaks Arabic and French.",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'Ross University School of Medicine'
                    },
                    {
                        type: 'Residency',
                        name: 'Florida Atlantic University'
                    },
                    {
                        type: 'Internship',
                        name: 'Florida Atlantic University'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - Glendora Foothill",
                location2: " ",
                region: ["East San Gabriel Valley"]
            },
            {
                id: 4,
                imageUrl: img1144280348, //using existing image as examples
                providerInteriorImage: img1144280348,
                sex: "Male",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [4],
                linkUrl: 'danny-lock',
                publicationsAndResearch: [],
                providerName: "Danny Lock",
                providerTitle: "MD",
                npi: 1144280348,
                specialty: ["Family Medicine"],
                phone: "1-909-593-4531",
                languages: ["English", "Spanish"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Danny Lock, MD, is board certified in family medicine. Dr. Lock earned his medical degree from the Universidad Autonoma de Guadalajara School of Medicine in Mexico. He completed two internships: one at LA County-USC Medical Center, and another at UC Irvine Medical Center. He also completed a residency through the USC Family Medicine Residency Program. Dr. Lock really cares about families; that is why he chose family medicine as his medical specialty. He sees a wide variety of patients from children to seniors, and gives them each the attention and time they need. In his spare time, Dr. Lock enjoys spending time with his family and friends. In addition to English, he speaks fluent Spanish.",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'Autonomous University of Guadalajara'
                    },
                    {
                        type: 'Residency',
                        name: 'USC CA Medical Center/Family Practice'
                    },
                    {
                        type: 'Internship',
                        name: 'LAC + USC Medical Center'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - La Verne",
                location2: " ",
                region: ["East San Gabriel Valley"]
            },
            {
            id: 5,
            imageUrl: img1821268434, //using existing image as examples
            providerInteriorImage: img1821268434,
            sex: "Female",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [5],
            linkUrl: 'renee-poole',
            publicationsAndResearch: [],
            providerName: "Renee Monique Poole",
            providerTitle: "MD",
            npi: 1821268434,
            specialty: ["Family Medicine"],
            phone: "1-626-585-4120",
            languages: ["English"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Renee Poole, MD, is board certified in family medicine. Dr. Poole earned her medical degree from Saint Louis University in Missouri. She completed her intership and residency at Advocate Christ Medical Center in Oak Lawn, Illinois. Dr. Poole pursued a career in medicine because of her desire to care of all age groups, working to improve the health of individuals and the community as a whole. Her interests lie heavily in pedicatric and adolescent care, women's health, minority health and obesity-related conditions. Dr. Poole believes in the empowerment of her patients through collaboration and patient education, aiming to inspire them to improve their health through early prevention and interventions. She enjoys community service, culinary experiences and traveling. In addition to English, Dr. Poole speaks medical Spanish.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Saint Louis University'
                },
                {
                    type: 'Residency',
                    name: 'Advocate Christ Medical Center'
                },
                {
                    type: 'Internship',
                    name: 'Advocate Christ Medical Center'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",  boardCertified: true,
            location: "Optum - Pasadena Main",
            location2: " ",
            region: ["West San Gabriel Valley"]
        },
            {
                id: 6,
                imageUrl: img1164862728,
                providerInteriorImage: img1164862728,
                sex: "Female",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [6],
                linkUrl: 'nicole-cheung',
                publicationsAndResearch: [],
                providerName: "Nicole  Cheung",
                providerTitle: "DO",
                npi: 1164862728,
                specialty: ["Family Medicine"],
                phone: "1-626-309-7600",
                languages: ["English", "Spanish"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Nicole Cheung, DO, is board certified in family medicine. Dr. Cheung earned her medical degree from Western University of Health Sciences in Pomona. She completed her internship and residency at Arrowhead Regional Medical Center in California. As a family physician, Dr. Cheung diagnoses and treats a wide variety of medical conditions and sees patients of all ages. She believes in a holistic approach to medicine, involving and educating her patients every step of the way. In her spare time, Dr. Cheung enjoys rock climbing and spending time with her daughter. In addition to English, she speaks basic Spanish.",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'Western University of Health Sciences'
                    },
                    {
                        type: 'Residency',
                        name: 'Arrowhead Regional Medical Center'
                    },
                    {
                        type: 'Internship',
                        name: 'Arrowhead Regional Medical Center'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - Temple City",
                location2: " ",
                region: ["West San Gabriel Valley"]
            },
            {
            id: 7,
            imageUrl: img1639367584,
            providerInteriorImage: img1639367584,
            sex: "Female",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [53],
            linkUrl: 'claudia-pfeil',
            publicationsAndResearch: [],
            providerName: "Claudia Pfeil",
            providerTitle: "MD",
            npi: 1639367584,
            specialty: ["Internal Medicine"],
            phone: "1-626-304-4400",
            languages: ["English", "German"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Claudia Pfeil, MD, is board certified in internal medicine, and practices medicine in the urgent-care setting. Dr. Pfeil earned her medical degree from the University of Maryland School of Medicine. She completed her internship and residency in internal medicine and pediatrics at Cedars-Sinai Medical Center in Los Angeles. Dr. Pfeil enjoys treating patients in the urgent-care setting because she sees positive results immediately. Her patients say that she is warm and friendly, and takes extra time to answer their questions. In her spare time, Dr. Pfeil enjoys outdoor activities, and spending time with family and friends. In addition to English, Dr. Pfeil speaks German.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'University of Maryland at Baltimore'
                },
                {
                    type: 'Residency',
                    name: 'Cedar Sinai Medical Center'
                },
                {
                    type: 'Internship',
                    name: 'Cedar Sinai Medical Center'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",  boardCertified: true,
            location: "Optum - Fairview",
            location2: " ",
            region: ["West San Gabriel Valley"]
        },
            {
            id: 8,
            imageUrl: img1477907970,
            providerInteriorImage: img1477907970,
            sex: "Female",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [7],
            linkUrl: 'ronica-patel',
            publicationsAndResearch: [],
            providerName: "Ronica Patel",
            providerTitle: "DO",
            npi: 1477907970,
            specialty: ["Family Medicine"],
            phone: "1-626-462-1884",
            languages: ["English", "Spanish", "Gujarati"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
                providerBio: "Ronica Patel, DO, practices family medicine. Dr. Patel earned her medical degree from Western University of Health Sciences, College of Osteopathic Medicine in Pomona. She completed her internship and residency at California Hospital Medical Center in Los Angeles. Dr. Patel believes that there is more to good health than the absence of pain or disease. She focuses on disease prevention by gaining a deeper understanding of her patients' lifestyles and environment as well. Patients say they appreciate Dr. Patel's good listening skills, calm demeanor and how she puts them at ease during exams. In her spare time, she participates in CrossFit events, as well as running, hiking and exploring Southern California with her dog. In addition to English, Dr. Patel speaks Spanish.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Western University of Health Sciences'
                },
                {
                    type: 'Residency',
                    name: 'California Hospital Medical Center (Dignity)'
                },
                {
                    type: 'Internship',
                    name: 'California Hospital Medical Center (Dignity)'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",  boardCertified: true,
            location: "Optum - Arcadia",
            location2: " ",
            region: ["West San Gabriel Valley"]
        },
            {
                id: 9,
                imageUrl: img1376912691, //using existing image as examples
                providerInteriorImage: img1376912691,
                sex: "Female",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [9],
                linkUrl: 'siran-sirop',
                publicationsAndResearch: [],
                providerName: "Siran Sirop",
                providerTitle: "MD",
                npi: 1376912691,
                specialty: ["Family Medicine"],
                phone: "1-818-790-1103",
                languages: ["English", "Arabic", "Armenian"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Siran Sirop, MD, practices family medicine. Dr. Sirop earned her medical degree from Yerevan State Medical University in Armenia. She completed her internship and residency at Glendale Adventist Medical Center. As a child, Dr. Sirop watched her father, a doctor, help sick people become well again. Her dad's dedication to others strongly motivated her to follow in his footsteps. Her patients say they appreciate her good listening skills and taking extra time to answer their questions and concerns. Dr. Sirop believes in educating her patients about their medical conditions and treatment options so they can get better sooner and stay healthy. In her spare time, she enjoys traveling, hiking and spending time with her son. In addition to English, Dr. Sirop speaks Armenian and Arabic.",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'Yerevan State Medical University'
                    },
                    {
                        type: 'Residency',
                        name: 'Glendale Adventist Medical Center Family Medicine'
                    },
                    {
                        type: 'Internship',
                        name: 'Adventist Health Glendale'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - Verdugo",
                location2: " ",
                region: ["West San Gabriel Valley"]
            },
            {
                id: 10,
                imageUrl: img1538208236, //using existing image as examples
                providerInteriorImage: img1538208236,
                sex: "Male",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [10],
                linkUrl: 'gustavo-estrella',
                publicationsAndResearch: [],
                providerName: "Gustavo Eduardo Estrella",
                providerTitle: "MD",
                npi: 1538208236,
                specialty: ["Family Medicine"],
                phone: "1-323-261-7520",
                languages: ["English", "Spanish"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Gustavo Estrella, MD, is board certified in family medicine. Dr. Estrella earned his medical degree from the University of California San Francisco School of Medicine in San Francisco, California. He completed both his internship and residency at White Memorial Medical Center in Los Angeles, California. Dr. Estrella is passionate about helping people in underserved communities live healthier lives. He listens to each patient's concerns and creates customized care plans to help them reach their health goals. When he's not caring for his patients, Dr. Estrella enjoys spending time with his family, running and watching sports. Dr. Estrella speaks English and Spanish.",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'University of California San Francisco School of Medicine'
                    },
                    {
                        type: 'Residency',
                        name: 'White Memorial Medical Center'
                    },
                    {
                        type: 'Internship',
                        name: 'White Memorial Medical Center'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - East Los Angeles",
                location2: " ",
                region: ["LA/Downtown"]
            },
            {
                id: 11,
                imageUrl: img1578084604, //using existing image as examples
                providerInteriorImage: img1578084604,
                sex: "Male",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [11],
                linkUrl: 'julio-hernandez',
                publicationsAndResearch: [],
                providerName: "Julio Ivan Alexander Hernandez Rodriguez",
                providerTitle: "MD",
                npi: 1578084604,
                specialty: ["Family Medicine"],
                phone: "1-213-623-2225",
                languages: ["English", "Spanish"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Julio Ivan Alexander Hernandez Rodriguez, MD, is board certified in family medicine. Dr. Rodriguez earned his medical degree from Dr. Jose Matias Delgado University in El Salvador. He completed his internship and residency at Zucker School of Medicine at Hofstra/Northwell in New York. Dr. Rodriguez takes pride in being a good listener and believes in the power of empathy. Rather than dictating care, he prefers coming up with joint solutions with his patients. His biggest clinical interest is in LGBTQ+ health and equitable access to health care. In his spare time, Dr. Rodriguez enjoys traveling. Prior to joining the Optum family, he visited 11 countries in three years. He loves to be in nature and to go on hikes. He has even climbed the summit of Mt. Kilimanjaro. In addition to English, Dr. Rodriguez speaks fluent Spanish.",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'Dr. Jose Matias Delgado University of Health Sciences'
                    },
                    {
                        type: 'Residency',
                        name: 'Zucker School of Medicine at Hofstra/Northwell at Mather Hospital'
                    },
                    {
                        type: 'Internship',
                        name: 'Zucker School of Medicine at Hofstra/Northwell at Mather Hospital'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - Los Angeles",
                location2: " ",
                region: ["LA/Downtown"]
            },
            {
                id: 12,
                imageUrl: img1356862684,
                providerInteriorImage: img1356862684,
                sex: "Female",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [11],
                linkUrl: 'sarah-gahng',
                publicationsAndResearch: [],
                providerName: "Sarah  Minhee  Gahng",
                providerTitle: "MD",
                npi: 1356862684,
                specialty: ["Family Medicine"],
                phone: "1-213-623-2225",
                languages: ["English", "Korean", "Spanish"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Sarah Gahng, MD, is board certified in family medicine. Dr. Gahng earned her medical degree from St. George's University in Grenada. She completed her internship and residency at Arrowhead Regional Medical Center in California. Dr. Gahng's passion for medicine is rooted in her belief that the key to optimal health lies in the partnership between a doctor and patient. She believes in empowering patients through education to help inform decisions that lead to a healthier and happier lifestyle. In her free time, Dr. Gahng enjoys spending time with her family and her dog, Leo, traveling and hiking while being involved in her church community. In addition to English, she speaks Spanish and Korean.",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'St. George\'s University'
                    },
                    {
                        type: 'Residency',
                        name: 'Arrowhead Regional Medical Center'
                    },
                    {
                        type: 'Internship',
                        name: 'Arrowhead Regional Medical Center'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - Los Angeles",
                location2: " ",
                region: ["LA/Downtown"]
            },
            {
                id: 13,
                imageUrl: img1093237786, //using existing image as examples
                providerInteriorImage: img1093237786,
                sex: "Male",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [11],
                linkUrl: 'melvin-rivera',
                publicationsAndResearch: [],
                providerName: "Melvin Rivera",
                providerTitle: "MD",
                npi: 1093237786,
                specialty: ["Internal Medicine"],
                phone: "1-213-623-2225",
                languages: ["English", "Spanish"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Melvin Rivera Carrero, MD, practices internal medicine. Dr. Carrero earned his medical degree from San Juan Bautista School of Medicine in Puerto Rico.  He completed both his internship and residency at Mayaguez Medical Center in Puerto Rico. Dr. Carrero is passionate about forming relationships based on trust and understanding and focuses on using his knowledge and interpersonal skills to help others. Dr. Carrero takes pride in being friendly, approachable and a great listener. He sees preventive care as a priority, as it can greatly improve the quality of his patients' lives. He works to provide his patients with the tools and knowledge that will empower them to make informed decisions and encourages them to actively participate in reaching their health goals. When he's not caring for his patients, Dr. Carrero enjoys the outdoors and sharing quality time with his wife, two daughters and their dog. Their time together usually revolves around forging meaningful connections with nature. Some of their favorite activities to do together are hiking trails, surfing at the beach, skateboarding, swimming and building sandcastles by the sea. In addition to English, Dr. Carrero speaks fluent Spanish.",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'San Juan Bautista School of Medicine'
                    },
                    {
                        type: 'Residency',
                        name: 'Mayagüez Medical Center'
                    },
                    {
                        type: 'Internship',
                        name: 'Mayagüez Medical Center'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - Los Angeles",
                location2: " ",
                region: ["LA/Downtown"]
            },
            {
                id: 14,
                imageUrl: img1821027699,
                providerInteriorImage: img1821027699,
                sex: "Female",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [12],
                linkUrl: 'lilia-zamora',
                publicationsAndResearch: [],
                providerName: "Lilia V. Zamora",
                providerTitle: "MD",
                npi: 1821027699,
                specialty: ["Family Medicine"],
                phone: "1-323-720-1144",
                languages: ["English", "Spanish"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Lilia Zamora, MD, practices family medicine. Dr. Zamora earned her medical degree from the UC Davis School of Medicine. Dr. Zamora completed her residency and a fellowship in obstetrics and gynecology at White Memorial Medical Center in Los Angeles. She prides herself on spending as much time as possible with her patients. In addition to English, Dr. Zamora speaks Spanish.",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'UC Davis, School of Medicine'
                    },
                    {
                        type: 'Residency',
                        name: 'White Memorial Medical Center'
                    },
                    {
                        type: 'Internship',
                        name: 'White Memorial Medical Center'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: false,
                location: "Optum - Montebello",
                location2: " ",
                region: ["LA/Downtown"]
            },
            {
                id: 15,
                imageUrl: img1922137173,
                providerInteriorImage: img1922137173,
                sex: "Female",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [13],
                linkUrl: 'maria-garcia-sioson',
                publicationsAndResearch: [],
                providerName: "Maria Virginia Garcia Sioson-Ayala",
                providerTitle: "MD",
                npi: 1922137173,
                specialty: ["Family Medicine"],
                phone: "1-626-281-6969",
                languages: ["English", "Tagalog"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Maria-Virginia Garcia Sioson-Ayala, MD, is board certified in family medicine. Dr. Sioson-Ayala earned her medical degree from the University of Santo Tomas in the Philippines. She completed her internship and residency at the University of Tennessee Health Center. Dr. Sioson-Ayala became a physician so she could improve her patients' quality of life in the best way possible. She educates them on how to practice a healthy lifestyle with nutritious eating, regular exercise, and stress reduction. In her spare time, she visits new places, listens to music, watches movies, and spends time with her family. In addition to English, Dr. Sioson-Ayala speaks fluent Tagalog and medical Spanish.",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'University of Santo Tomas Faculty of Medicine & Surgery'
                    },
                    {
                        type: 'Residency',
                        name: 'University of Tennessee - Jackson Program'
                    },
                    {
                        type: 'Internship',
                        name: 'University of Tennessee - Jackson Program'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - Monterey Park",
                location2: "Optum - East Los Angeles",
                region: ["LA/Downtown"]
            },
            {
                id: 16,
                imageUrl: img1396064614,
                providerInteriorImage: img1396064614,
                sex: "Male",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [13],
                linkUrl: 'vincent-wu',
                publicationsAndResearch: [],
                providerName: "Vincent Wu",
                providerTitle: "DO",
                npi: 1396064614,
                specialty: ["Family Medicine"],
                phone: "1-626-281-6969",
                languages: ["English", "Mandarin", "Taiwanese"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Vincent Wu, DO, is board certified in family medicine. Dr. Wu earned his medical degree from Western University of Health Sciences, College of Osteopathic Medicine in Pomona. He completed his internship and residency at UCI Medical Center in Irvine. Dr. Wu had a physician mentor who urged him to create special relationships with patients. Inspired by his mentor, Dr. Wu strives to not only diagnose and treat his patients in the best way possible, but also to build trust and rapport. To put them at ease, Dr. Wu sometimes jokes with his patients and engages in small talk. Then he discusses their health issues in detail. He always encourages them to eat nutritious foods, exercise regularly and reduce stress for optimum health. In his spare time, Dr. Wu enjoys spending time with his family, participating in church activities and reading. In addition to English, he speaks Mandarin and Taiwanese.",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'Western University of Health Sciences'
                    },
                    {
                        type: 'Residency',
                        name: 'University of California, Irvine'
                    },
                    {
                        type: 'Internship',
                        name: 'University of California, Irvine'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - Monterey Park",
                location2: " ",
                region: ["LA/Downtown"]
            },
            {
                id: 17,
                imageUrl: img1699295758, //using existing image as examples
                providerInteriorImage: img1699295758,
                sex: "Male",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [14],
                linkUrl: 'feliks-avanesyan',
                publicationsAndResearch: [],
                providerName: "Feliks Avanesyan",
                providerTitle: "MD",
                npi: 1699295758,
                specialty: ["Family Medicine"],
                phone: "1-818-790-1088",
                languages: ["English", "Armenian"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Feliks Avanesyan, MD, practices family medicine. Dr. Avanesyan earned his medical degree from St. George's University in Grenada. He completed his internship and residency at the Southern Illinois University in Illinois. Dr. Avanesyan's passion for medicine is rooted in his belief that the partnership between patient and doctor is part of the key to good health. He believes strongly that the patient is the primary decision maker and the role of the doctor is to provide an understanding of the human body to help inform sound decision-making. Dr. Avanesyan enjoys hiking, classical piano and learning foreign lanaguages in his free time. In addition to English, he speaks fluent Armenian.",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'St. George\'s University'
                    },
                    {
                        type: 'Residency',
                        name: 'Southern Illinois University Healthcare'
                    },
                    {
                        type: 'Internship',
                        name: 'Southern Illinois University Healthcare'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - Descanso",
                location2: "",
                region: ["LA/Downtown"]
            },
            {
                id: 18,
                imageUrl: img1922479930,
                providerInteriorImage: img1922479930,
                sex: "Female",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [68],
                linkUrl: 'katarzyna-nasiadko',
                publicationsAndResearch: [],
                providerName: "Katarzyna Natalia Nasiadko Albuquerque",
                providerTitle: "MD",
                npi: 1922479930,
                specialty: ["Family Medicine"],
                phone: "1-818-886-4028",
                languages: ["English", "Polish"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Katarzyna Nasiadko Albuquerque, MD, practices family medicine. Dr. Albuquerque earned her medical degree from the American University of Antigua, College of Medicine in New York. She completed her internship and residency through the Western Reserve Care System in Ohio. Dr. Albuquerque chose primary care medicine because of the opportunity to build trusting relationships with patients and their families. Patients give Dr. Albuquerque high marks for her warm, caring manner and her open-minded position on treatment options. She is a strong believer in preventive care and encourages her patients to take care of their health by eating nutritious meals, exercising regularly, reducing stress and having a supportive social life. In her spare time, Dr. Albuquerque enjoys traveling, learning about new cultures, practicing yoga, going to the beach and spending time with her dogs. In addition to English, she speaks Polish.",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'American University of Antigua'
                    },
                    {
                        type: 'Residency',
                        name: 'Western Reserve Care System'
                    },
                    {
                        type: 'Internship',
                        name: 'Western Reserve Health Education Inc.'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - Roscoe",
                location2: " ",
                region: ["San Fernando Valley"]
            },
            {
                id: 19,
                imageUrl: img1235164971, //using existing image as examples
                providerInteriorImage: img1235164971,
                sex: "Male",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [16],
                linkUrl: 'alwin-carl',
                publicationsAndResearch: [],
                providerName: "Alwin Carl Lewis",
                providerTitle: "MD",
                npi: 1235164971,
                specialty: ["Internal Medicine"],
                phone: "1-818-955-5773",
                languages: ["English"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Alwin Lewis, MD, is board certified in internal medicine. Dr. Lewis earned his medical degree from Tulane University in New Orleans. He completed his internship and residency at the University of California, Irvine. Dr. Lewis is proud to help improve his patient's state of health through behavior modification and disease prevention, working to reduce medication and increase optimal levels of health. He hopes his patients can appreciate his genuine passion he has for improving their health and believes his job as their doctor is to better each patient's quality of life. Dr. Lewis is an accomplished pianist and plays everyday. In his spare time, he loves to play golf and tennis with his two sons and his wife, who is quite the athlete.",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'Tulane University'
                    },
                    {
                        type: 'Residency',
                        name: 'University of California, Irvine'
                    },
                    {
                        type: 'Internship',
                        name: 'University of California, Irvine'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - Burbank",
                location2: " ",
                region: ["San Fernando Valley"]
            },
            {
                id: 20,
                imageUrl: img1902816903,
                providerInteriorImage: img1902816903,
                sex: "Female",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [51],
                linkUrl: 'taura-michelle',
                publicationsAndResearch: [],
                providerName: "Taura Michelle Olfus",
                providerTitle: "DO",
                npi: 1902816903,
                specialty: ["Family Medicine"],
                phone: "1-661-367-3500",
                languages: ["English"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Taura Olfus, DO, is board certified in family medicine. She earned her medical degree from the New York Institute of Technology College of Osteopathic Medicine in Old Westbury, New York. Dr. Olfus completed her internship at St. Vincent's Midtown Hospital in New York, New York. She completed her residency at Long Beach Medical Center in Long Beach, California. Dr. Olfus is passionate about helping people live healthier lives. She is an open communicator and a good listener. Dr. Olfus strongly believes that the best health outcomes are achieved by working together with her patients. Her clinical interests include diabetes, obesity, heart health and women's health concerns. When she's not caring for her patients, Dr. Olfus enjoys running, traveling and, spending time with her family. Dr. Olfus speaks English and Spanish.",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'New York College of Osteopathic Medicine'
                    },
                    {
                        type: 'Residency',
                        name: 'Long Beach Medical Center'
                    },
                    {
                        type: 'Internship',
                        name: 'St. Vincent\'s Midtown Hospital'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - Valencia",
                location2: "Optum - Canyon Country",
                region: ["San Fernando Valley"]
            },
            {
                id: 21,
                imageUrl: img1518995133,
                providerInteriorImage: img1518995133,
                sex: "Male",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [18,52],
                linkUrl: 'samuel-oregel',
                publicationsAndResearch: [],
                providerName: "Samuel Eduardo Oregel",
                providerTitle: "MD",
                npi: 1518995133,
                specialty: ["Family Medicine"],
                phone: "1-855-464-6505",
                languages: ["English", "Spanish"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Samuel Oregel, MD, is board certified in family medicine. Dr. Oregel earned his medical degree from the University of California, Davis School of Medicine in Sacramento, California. He completed his internship and the California Hospital Medical Center in Los Angeles and his residency at the UC David Medical Center in Sacramento. Dr. Oregel pursued a career in medicine to help and empower his community regarding their health. He believes that patient education is key to helping his patients achieve optimal levels of health and wellness. In his free time, Dr. Oregel enjoys fitness, spending time with his wife and family and sports. In addition to English, he speaks Spanish.",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'UC Davis'
                    },
                    {
                        type: 'Residency',
                        name: 'UC Davis, Medical Center'
                    },
                    {
                        type: 'Internship',
                        name: 'California Hospital Medical Center'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum -Van Nuys",
                location2: "Optum - Vanowen",
                region: [""]
            },
            {
                id: 22,
                imageUrl: img1104245000,
                providerInteriorImage: img1104245000,
                sex: "Female",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [18],
                linkUrl: 'cynthia-pike',
                publicationsAndResearch: [],
                providerName: "Cynthia Rae Pike",
                providerTitle: "MD",
                npi: 1104245000,
                specialty: ["Family Medicine"],
                phone: "1-818-781-7097",
                languages: ["English", "Spanish"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Cynthia Pike, MD, is board certified in family medicine. Dr. Pike earned her medical degree from Texas A & M University in Temple, TX. She completed her internship and residency at UC Irvine Medical Center. Dr. Pike specializes in caring for people of all ages, at all stages of life. She provides patients advice on preventing diseases by getting enough sleep, eating nutritious foods, exercising regularly and reducing stress. In her spare time, Dr. Pike enjoys exploring the Los Angeles area with her husband. In addition to English, she speaks basic Spanish.",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'Texas A&M University'
                    },
                    {
                        type: 'Residency',
                        name: 'University of California Irvine'
                    },
                    {
                        type: 'Internship',
                        name: 'University of California Irvine'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum -Van Nuys",
                location2: " ",
                region: ["San Fernando Valley"]
            },
            {
                id: 23,
                imageUrl: img1932526514,
                providerInteriorImage: img1932526514,
                sex: "Male",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [52],
                linkUrl: 'javier-moncayo',
                publicationsAndResearch: [],
                providerName: "Javier Andres Lopez Moncayo",
                providerTitle: "MD",
                npi: 1932526514,
                specialty: ["Internal Medicine"],
                phone: "1-818-785-7875",
                languages: ["English", "Portuguese", "Spanish"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Javier Lopez Moncayo, MD, is board certified in internal medicine. Dr. Lopez Moncayo earned his medical degree from Catholica University of Santiago Guayaquil in Ecuador. He completed his internship and residency at Seton Hall University-St. Francis Medical Center in New Jersey. Dr. Lopez Moncayo chose internal medicine with a focus on geriatrics because of the profound difference he can make in helping older people achieve their best possible health, even later in life. Patients give him high marks for the extra time he takes to address their questions and address concerns. They also say he is empathetic, patient and involves them in making decisions about their treatment options. In his spare time, Dr. Lopez Moncayo enjoys working out at the gym, running, listening to music and watching comedy on Netflix. In addition to English, he speaks Spanish and Portuguese.",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'Catholic University of Santiago De Guayaquil'
                    },
                    {
                        type: 'Residency',
                        name: 'Seton Hall University - St. Francis Medical Center'
                    },
                    {
                        type: 'Internship',
                        name: 'Seton Hall University - St. Francis Medical Center'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - Vanowen",
                location2: " ",
                region: ["San Fernando Valley"]
            },
            {
                id: 24,
                imageUrl: img1407350622,
                providerInteriorImage: img1407350622,
                sex: "Male",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [19],
                linkUrl: 'ashton-garbutt',
                publicationsAndResearch: [],
                providerName: "Ashton Mark Garbutt",
                providerTitle: "MD",
                npi: 1407350622,
                specialty: ["Family Medicine"],
                phone: "1-855-464-6505",
                languages: ["English", "Spanish"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Ashton Garbutt, MD, is board certified in family medicine. Dr. Garbutt earned his medical degree from Albany Medical College in New York. He completed his internship and residency at Dignity Health Medical Foundation in Northridge, California. Dr. Garbutt believes in helping people lead healthier lives. He was drawn to medicine because he enjoys solving complex problems and is passionate about helping others. Dr. Garbutt focuses on treating every patient as if they were a family member or close friend. He puts patients first by striving to be an active listener who is approachable, empathetic, and friendly. His clinical interests include diabetes, geriatric health, heart health, and pediatric care. When he's not caring for his patients, Dr. Garbutt enjoys cooking, reading, and spending time with family and friends. He also loves to play and watch basketball.",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'Albany Medical College'
                    },
                    {
                        type: 'Residency',
                        name: 'Dignity Health Medical Foundation'
                    },
                    {
                        type: 'Internship',
                        name: 'Dignity Health Medical Foundation'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - West Hills",
                location2: " ",
                region: [""]
            },
            {
                id: 25,
                imageUrl: img1609305945,
                providerInteriorImage: img1609305945,
                sex: "Female",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [7],
                linkUrl: 'isil-smith',
                publicationsAndResearch: [],
                providerName: "Isil Smith",
                providerTitle: "MD",
                npi: 1609305945,
                specialty: ["Internal Medicine"],
                phone: "1-626-462-1884",
                languages: ["English", "Turkish"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Isil Smith, MD, practices internal medicine. Dr. Smith earned her medical degree from Ondokuz Mayis School of Medicine in Turkey. She completed her internship and residency at Steward Carney Hospital in Boston, Massachusetts. Dr. Smith pursued a career in medicine after growing up with family members who suffered from chronic illnesses and as such, believes strongly in preventative medicine as one of the keys to optimal health. She believes her empathy and reliability work together to strengthen her patient relationships. In her spare time, Dr. Smith enjoys hiking, kayaking and gardening. In addition to English, she speaks Turkish.",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'University of Ondokuz Mayis Faculty of Medicine'
                    },
                    {
                        type: 'Residency',
                        name: 'Steward Carney Hospital'
                    },
                    {
                        type: 'Internship',
                        name: 'Steward Carney Hospital'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - Arcadia",
                location2: " ",
                region: ["West San Gabriel Valley"]
            },
            {
                id: 26,
                imageUrl: img1689193336,
                providerInteriorImage: img1689193336,
                sex: "Male",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [22],
                linkUrl: 'muhammad-nouh',
                publicationsAndResearch: [],
                providerName: "Muhammad Nouh",
                providerTitle: "DO",
                npi: 1689193336,
                specialty: ["Family Medicine"],
                phone: "1-562-420-1338",
                languages: ["English", "Spanish", "Arabic"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Muhammad Nouh, DO, is board certified in family medicine. Dr. Nouh earned his medical degree from Touro College of Osteopathic Medicine in New York. He completed his internship and residency at College Medical Center in Long Beach. Dr. Nouh's goal is to help his patients and their families understand the importance of good health. He believes strongly in the adage of \"An ounce of prevention is worth a pound of cure\". He enjoys educating his patients on preventive medicine and develop care plans that they are comfortable with. In his spare time, Dr. Nouh enjoys weight-lifting, swimming, driving and racing cars, video gaming, and spending time with family and friends. In addition to English, he speaks basic Arabic and Spanish.",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'Touro College of Osteopathic Medicine'
                    },
                    {
                        type: 'Residency',
                        name: 'College Medical Center'
                    },
                    {
                        type: 'Internship',
                        name: 'College Medical Center'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - Spring Street",
                location2: " ",
                region: ["Long Beach"]
            },
            {
                id: 27,
                imageUrl: img1700248879,
                providerInteriorImage: img1700248879,
                sex: "Female",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [23],
                linkUrl: 'haivy-nguyen',
                publicationsAndResearch: [],
                providerName: "Haivy Nguyen",
                providerTitle: "MD",
                npi: 1700248879,
                specialty: ["Internal Medicine"],
                phone: "1-562-432-5661",
                languages: ["English", "Vietnamese"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Haivy Nguyen, MD, is board certified in internal medicine. Dr. Nguyen earned her medical degree from Sackler School of Medicine in New York. She completed her internship and her residency at Lenox Hill Hospital in New York City. Dr. Nguyen became a doctor with the goal of helping people lead happier and healthier lives. Dr. Nguyen strongly believes in patient education. She strives to provide the most accessible and easy-to-understand information to help patients with their medical conditions so they can make informed decisions about their health and lifestyle habits. Dr. Nguyen prides hierself on being an active listener. She likes to make people smile and laugh, even if it means telling corny jokes. After all, she believes that laughter is the best medicine. When she's not caring for her patients, Dr. Nguyen loves to explore nature and spend time outdoors. She goes camping, hiking, and rock climbing whenever she can. In addition to English, Dr. Nguyen speaks Vietnamese.",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'Sackler School of Medicine'
                    },
                    {
                        type: 'Residency',
                        name: 'Lenox Hill Hospital'
                    },
                    {
                        type: 'Internship',
                        name: 'Lenox Hill Hospital'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - Downtown Long Beach",
                location2: " ",
                region: ["Long Beach"]
            },
            {
                id: 28,
                imageUrl: img1306942545,
                providerInteriorImage: img1306942545,
                sex: "Male",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [24],
                linkUrl: 'wail-shoga',
                publicationsAndResearch: [],
                providerName: "Wail A. Shoga",
                providerTitle: "DO",
                npi: 1306942545,
                specialty: ["Internal Medicine"],
                phone: "1-562-429-2473",
                languages: ["English", "Arabic"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Wail Shoga, DO, is board certified in internal medicine. He earned his medical degree from Western University of Health Sciences, College of Osteopathic Medicine of the Pacific in Pomona. He completed his internship and residency at the University of Medicine and Dentistry of New Jersey. Dr. Shoga has a special interest in preventive medicine and health enhancement. In his spare time, he enjoys skiing, cycling and gardening. In addition to English, Dr. Shoga speaks Arabic.\r\n",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'College of Osteopathic Medicine of the Pacific'
                    },
                    {
                        type: 'Residency',
                        name: 'University of Medicine & Dentistry of NJ'
                    },
                    {
                        type: 'Internship',
                        name: 'University of Medicine & Dentistry of NJ'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - Airport Plaza",
                location2: " ",
                region: ["Long Beach"]
            },
        /*
            {
                id: 29,
                imageUrl: img1265708531,
                providerInteriorImage: img1265708531,
                sex: "Female",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [25],
                linkUrl: 'johana-flores-rios',
                publicationsAndResearch: [],
                providerName: "Johana  Esther Flores-Rios",
                providerTitle: "MD",
                npi: 1265708531,
                specialty: ["Family Medicine"],
                phone: "1-866-488-5203",
                languages: ["English", "Spanish"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Johana Flores-Rios, MD, is board certified in family medicine. Dr. Flores-Rios earned her medical degree from National Autonomous University of Honduras. She completed her internship and residency at Keck-USC School of Medicine. Dr. Flores-Rios sees patients of all ages and backgrounds. In particular, she has a passion to help and heal the underserved. She also has a strong interest in women's health and obstetrics care. Dr. Flores-Rios believes strongly about educating her patients on how improving their health can make a real difference in their lives. She encourages them to practice good habits such as eating nutritious foods, exercising regularly and reducing stress. In her spare time, Dr. Flores-Rios enjoys hiking, dancing to Hispanic music and traveling. In addition to English, she speaks Spanish.\r\n",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'National Autonomous University of Honduras'
                    },
                    {
                        type: 'Residency',
                        name: 'USC Family Medicine - Residency Program'
                    },
                    {
                        type: 'Internship',
                        name: 'USC - Family Medicine Program California Hospital Medical Center'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - Lakewood",
                location2: " ",
                region: ["Long Beach"]
            },
            {
                id: 30,
                imageUrl: img1447505284,
                providerInteriorImage: img1447505284,
                sex: "Male",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [26],
                linkUrl: 'hung-phan',
                publicationsAndResearch: [],
                providerName: "Hung Canh Phan",
                providerTitle: "MD",
                npi: 1447505284,
                specialty: ["Internal Medicine"],
                phone: "1-866-488-5203",
                languages: ["English", "Vietnamese"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Hung Phan, MD, is board certified in internal medicine. Dr. Phan earned his medical degree from Hue University of Medicine and Pharmacy in Vietnam. He completed three internships and three residencies at the following centers: Louis Weiss Memorial Hospital in Chicago; St. Mary's Hospital and Medical Center in San Francisco; and Charite Humbodlt University in Germany. Dr. Phan's patients give him high marks for his thorough exams, great listening skills and expert knowledge. In his spare time, he enjoys playing tennis and spending time with his family and friends. He also enjoys growing and cultivating Bonsai trees. In addition to English, Dr. Phan speaks fluent Vietnamese.\r\n",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'Hue University of Medicine and Pharmacy in Vietnam'
                    },
                    {
                        type: 'Residency',
                        name: 'Louis Weiss Memorial Hospital in Chicago; St. Mary\'s Hospital and Medical Center in San Francisco; Charite Humboldt University in Germany'
                    },
                    {
                        type: 'Internship',
                        name: 'Louis Weiss Memorial Hospital in Chicago; St. Mary\'s Hospital and Medical Center in San Francisco; Charite Humboldt University in Germany'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - Downey",
                location2: " ",
                region: ["Long Beach"]
            },
            {
                id: 31,
                imageUrl: img1447612361,
                providerInteriorImage: img1447612361,
                sex: "Male",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [26],
                linkUrl: 'kevin-urmaza',
                publicationsAndResearch: [],
                providerName: "Kevin Castro Urmaza",
                providerTitle: "DO",
                npi: 1447612361,
                specialty: ["Family Medicine"],
                phone: "1-866-488-5203",
                languages: ["English"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Kevin Urmaza, DO, is board certified in family medicine. Dr. Urmaza earned his medical degree from the Western University of Health Sciences in California. He completed his internship and residency at the University of Nevada School of Medicine. Dr. Urmaza dedicates himself to his patient relationships and providerTitleits his listening skills to be the key to helping patients live their healthiest lives. He enjoys hiking in the mountains, snowboarding and traveling.\r\n",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'Western University of Health Sciences'
                    },
                    {
                        type: 'Residency',
                        name: 'University of Nevada School of Medicine'
                    },
                    {
                        type: 'Internship',
                        name: 'University of Nevada School of Medicine'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - Downey",
                location2: " ",
                region: ["Long Beach"]
            },

         */
            {
                id: 32,
                imageUrl: img1245736842,
                providerInteriorImage: img1245736842,
                sex: "Female",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [27],
                linkUrl: 'virpal-gill',
                publicationsAndResearch: [],
                providerName: "Virpal Kaur Gill",
                providerTitle: "MD",
                npi: 1245736842,
                specialty: ["Family Medicine"],
                phone: "1-562-988-7000",
                languages: ["English"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Virpal Gill, MD, practices family medicine. Dr. Gill earned her medical degree from Oakland University. She completed her internship and residency at UCSF Fresno. Dr. Gill decided to become a doctor when she went on a public health trip to Tanzania. The experience left her with the desire to help others who were suffering from illnesses that could have easily been prevented through education and compassion. Dr. Gill strongly believes in treating every patient like a family member. She prides herself on being present with each patient through active listening and keeping her patients calm in difficult situations. When she's not caring for her patients, Dr. Gill loves to travel, try new foods, and go on walks with her dog. In addition to English, Dr. Gill speaks Hindi and Punjabi.\r\n",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'Oakland University'
                    },
                    {
                        type: 'Residency',
                        name: 'Emanate Health Medical Group'
                    },
                    {
                        type: 'Internship',
                        name: 'Emanate Health Medical Group'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - Willow",
                location2: " ",
                region: ["Long Beach"]
            },
            {
                id: 33,
                imageUrl: img1477915452,
                providerInteriorImage: img1477915452,
                sex: "Female",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [28],
                linkUrl: 'jan-nguyen',
                publicationsAndResearch: [],
                providerName: "Jan Thanh Nguyen",
                providerTitle: "DO",
                npi: 1477915452,
                specialty: ["Internal Medicine"],
                phone: "1-562-304-2100",
                languages: ["English", "Vietnamese"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Jan Nguyen, DO, practices internal medicine. Dr. Nguyen earned her medical degree from the College of Osteopathic Medicine of the Pacific in Pomona, California. She completed her internship and residency at the University of Washington School of Medicine in Seattle, Washington. Dr. Nguyen became a doctor to help people in underserved communities live healthier lives. She treats everyone in her care like friends or family. Dr. Nguyen listens carefully to each patient's health problems and works with them to find the best solution. She is passionate about women's health and Asian community health. Dr. Nguyen is also interested in helping patients manage diabetes, obesity and geriatric health concerns. When she's not caring for her patients, Dr. Nguyen enjoys reading, traveling, hiking and spending time with her family. She speaks English and Vietnamese.",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'College of Osteopathic Medicine of the Pacific'
                    },
                    {
                        type: 'Residency',
                        name: 'University of Washington School of Medicine'
                    },
                    {
                        type: 'Internship',
                        name: 'University of Washington School of Medicine'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - Bixby Knolls",
                location2: " ",
                region: ["Long Beach"]
            },
            {
                id: 34,
                imageUrl: img1164421814,
                providerInteriorImage: img1164421814,
                sex: "Female",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [29],
                linkUrl: 'valerie-green',
                publicationsAndResearch: [],
                providerName: "Valerie Green-Amos",
                providerTitle: "MD",
                npi: 1164421814,
                specialty: ["Family Medicine"],
                phone: "1-310-537-1337",
                languages: ["English", "Spanish"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Valerie Green, MD, is board certified in family medicine. Dr. Green earned her medical degree from Temple University in Philadelphia. She completed her internship and residency at the University of Maryland Medical Center. Dr. Green decided she wanted to be a doctor at a young age because of her interest in helping to heal others. Patients say that she is an advocate for their health and listens intently to their concerns. In her spare time, Dr. Green enjoys going to the beach, exercising, dancing and spending time with her family. In addition to English, she speaks Spanish.\r\n",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'Temple University'
                    },
                    {
                        type: 'Residency',
                        name: 'University of Maryland'
                    },
                    {
                        type: 'Internship',
                        name: 'University of Maryland'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - Compton",
                location2: " ",
                region: ["Long Beach"]
            },
            {
                id: 35,
                imageUrl: img1437654811,
                providerInteriorImage: img1437654811,
                sex: "Male",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [30],
                linkUrl: 'jeffrey-pan',
                publicationsAndResearch: [],
                providerName: "Jeffrey Pan",
                providerTitle: "MD",
                npi: 1437654811,
                specialty: ["Internal Medicine"],
                phone: "1-310-214-0811",
                languages: ["English"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Jeffrey Pan, MD, practices internal medicine. Dr. Pan earned his medical degree from St. George's University School of Medicine. He completed both his internship and residency at the University of Hawaii. Dr. Pan decided to pursue medicine because he has a passion for helping people live their healthiest lives. Dr. Pan believes in a strong patient-doctor relationship to help his patients make informed decisions about their health. He treats every patient as if they were his family. Dr. Pan prides himself on being a good listener and strong communicator. He has an empathetic approach and believes in making his patients feel comfortable. When he's not caring for his patients, Dr. Pan loves to hike, read, and spend time with his family. In addition to English, Dr. Pan speaks Mandarin.\r\n",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'St. George\'s University School of Medicine'
                    },
                    {
                        type: 'Residency',
                        name: 'University of Hawaii'
                    },
                    {
                        type: 'Internship',
                        name: 'University of Hawaii'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - Del Amo",
                location2: " ",
                region: ["South Bay"]
            },
            {
                id: 36,
                imageUrl: img1619408697,
                providerInteriorImage: img1619408697,
                sex: "Female",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [30],
                linkUrl: 'stephanie-brianne',
                publicationsAndResearch: [],
                providerName: "Stephanie Brianne Matza",
                providerTitle: "DO",
                npi: 1619408697,
                specialty: ["Family Medicine"],
                phone: "1-310-214-0811",
                languages: ["English"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Stephanie Matza, DO, practices Family Medicine. Dr. Matza earned her medical degree from Touro University in Nevada. She completed her internship at Eastern Connecticut Health Professionals and her residency at St. Luke's Memorial.",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'Touro University in Nevada'
                    },
                    {
                        type: 'Residency',
                        name: 'St. Luke\'s Memorial Hospital Center'
                    },
                    {
                        type: 'Internship',
                        name: 'Eastern Connecticut Health Professionals'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum -Del Amo",
                location2: " ",
                region: ["South Bay"]
            },
            {
                id: 37,
                imageUrl: img1932690484,
                providerInteriorImage: img1932690484,
                sex: "Male",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [31],
                linkUrl: 'daniel-placik',
                publicationsAndResearch: [],
                providerName: "Daniel Placik",
                providerTitle: "MD",
                npi: 1932690484,
                specialty: ["Family Medicine"],
                phone: "1-310-832-4225",
                languages: ["English", "Spanish"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Daniel Placik, MD, is board-certified in family medicine. Dr. Placik earned his medical degree from the Autonomous University of Guadalajara in Guadalajara, Mexico. He completed his internship and residency at Yuma Regional Medical Center in Yuma, Arizona. Dr. Placik became a doctor to help his patients live healthier lives. He believes the best health care starts with open communication and education. Dr. Placik is a good listener who works with his patients to solve their health problems. He is passionate about preventive care, sports medicine and integrative medicine. Dr. Placik is also interested in geriatric health, heart health and Latinx community health. His research on COVID-19 has been published in Radiology Case Reports. When he's not caring for his patients, Dr. Placik enjoys staying active, playing piano and spending time with his family. He speaks English and Spanish.",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'Universidad Autonoma de Guadalajara'
                    },
                    {
                        type: 'Residency',
                        name: 'Yuma Regional Medical Center'
                    },
                    {
                        type: 'Internship',
                        name: 'Yuma Regional Medical Center'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - Western San Pedro",
                location2: " ",
                region: ["South Bay"]
            },
            {
                id: 38,
                imageUrl: img1225370679,
                providerInteriorImage: img1225370679,
                sex: "Male",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [32],
                linkUrl: 'keith-kagahastian',
                publicationsAndResearch: [],
                providerName: "Keith Andrew Kagahastian Ebilane",
                providerTitle: "MD",
                npi: 1225370679,
                specialty: ["Internal Medicine"],
                phone: "1-310-324-2592",
                languages: ["English", "Spanish", "Tagalog"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Keith Ebilane, MD, is board certified in internal medicine. Dr. Ebilane earned his medical degree from the University of Santo Tomas Faculty of Medicine and Surgery in the Philippines. He completed his internship and residency at the University of Nevada School of Medicine. Dr. Ebilane chose the medical profession because of his passion to help people optimize their health. He believes strongly in educating his patients so they can make informed decisions about their treatment options. In his spare time, Dr. Ebilane enjoys outdoor activities with his family, especially exploring beaches and parks. In addition to English, he speaks fluent Tagalog.\r\n",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'University of Santo Tomas'
                    },
                    {
                        type: 'Residency',
                        name: 'University of Nevada School of Medicine'
                    },
                    {
                        type: 'Internship',
                        name: 'University of Nevada School of Medicine'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - Gardena",
                location2: " ",
                region: ["South Bay"]
            },
            {
                id: 39,
                imageUrl: img1851753370,
                providerInteriorImage: img1851753370,
                sex: "Male",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [33],
                linkUrl: 'philip-simmons',
                publicationsAndResearch: [],
                providerName: "Phil Simmons",
                providerTitle: "DO",
                npi: 1851753370,
                specialty: ["Family Medicine"],
                phone: "1-310-830-9706",
                languages: ["English"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Philip Simmons, DO, practices family medicine. Dr. Simmons earned his medical degree from Kirksville College of Osteopathic Medicine in Missouri. He completed his internship and residency at Honor Health Scottsdale Osborn Medical Center in Arizona. As an osteopathic doctor, Dr. Simmons believes in a holistic approach to health care. This means that while he prescribes medication, he also emphasizes positive lifestyle habits to naturally heal the body. These include eating nutritious foods, exercising regularly and reducing stress. Most importantly, Dr. Simmons partners with his patients to help them get healthy and stay that way. Patients give him high marks for his sincere interest in their well-being and lively sense of humor. In his spare time, Dr. Simmons enjoys cycling, hiking and spending time with his wife and dog.\r\n",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'Kirksville College of Osteopathic Medicine in Missouri'
                    },
                    {
                        type: 'Residency',
                        name: 'Honor Health Osborn'
                    },
                    {
                        type: 'Internship',
                        name: 'Honor Health Osborn'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - Carson",
                location2: " ",
                region: ["South Bay"]
            },
            {
                id: 40,
                imageUrl: img1952807372,
                providerInteriorImage: img1952807372,
                sex: "Male",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [34],
                linkUrl: 'rafik-shereen',
                publicationsAndResearch: [],
                providerName: "Rafik A. Shereen",
                providerTitle: "MD",
                npi: 1952807372,
                specialty: ["Internal Medicine"],
                phone: "1-310-643-8500",
                languages: ["English", "Spanish", "Arabic"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Rafik Shereen, MD, practices internal medicine. Dr. Shereen earned his medical degree from St. George's University School of Medicine in Grenada. He completed his internship and residency at Arrowhead Regional Medical Center in California. Dr. Shereen strives to collaborate directly with his patients to create a treatment plan that best serves them. Dr. Shereen strongly believes in tailoring each treatment plan to a patient's needs and goals. His mission is to provide evidence-based care in a way that is approachable, empathetic and friendly. His clinical interests include geriatric health, heart health and the treatment of diabetes and obesity. Dr. Shereen prides himself on being a problem solver who always has each patient's best interest in mind. He's happy to help his patients navigate complex health care systems so they can experience benefits sooner. When he's not caring for his patients, Dr. Shereen loves to enjoy nature and spend time outdoors. He goes hiking and surfing as often as he can.\r\n",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'St. George\'s University School of Medicine in Grenada'
                    },
                    {
                        type: 'Residency',
                        name: 'Arrowhead Regional Medical Center in California'
                    },
                    {
                        type: 'Internship',
                        name: 'Arrowhead Regional Medical Center in California'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - Manhattan Beach",
                location2: " ",
                region: ["South Bay"]
            },
        /*
            {
                id: 41,
                imageUrl: img1780620021,
                providerInteriorImage: img1780620021,
                sex: "Male",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [35],
                linkUrl: 'john-d-ro',
                publicationsAndResearch: [],
                providerName: "John Daerin Ro",
                providerTitle: "MD",
                npi: 1780620021,
                specialty: ["Family Medicine"],
                phone: "1-844-958-4189",
                languages: ["English"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "John Ro, MD, is board certified in family medicine. Dr. Ro earned his medical degree from Loma Linda University School of Medicine in California. He completed his internship and residency at Glendale Adventist Medical Center. Dr. Ro's patients say he is friendly and easy to talk to. He emphasizes preventive care and encourages his patients to make healthy lifestyle choices in order to achieve optimal health. In his spare time, Dr. Ro enjoys running, biking, snow sports, and photography, as well as spending time with his family.\r\n",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'Loma Linda University Medicine Center: Glendale Adventist Medical Center'
                    },
                    {
                        type: 'Residency',
                        name: 'Adventist Health Glendale'
                    },
                    {
                        type: 'Internship',
                        name: 'Adventist Health Glendale'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum -Torrance",
                location2: " ",
                region: ["South Bay"]
            },

         */
            {
                id: 42,
                imageUrl: img1447348628,
                providerInteriorImage: img1447348628,
                sex: "Male",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [37],
                linkUrl: 'steven-j-becker',
                publicationsAndResearch: [],
                providerName: "Steven Joseph Becker",
                providerTitle: "DO",
                npi: 1447348628,
                specialty: ["Internal Medicine"],
                phone: "1-866-324-0903",
                languages: ["English"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Steven J. Becker, D.O. has a wide range of experience in internal medicine, from preventive care to management of chronic conditions for adults of all ages. He says, I see my role as a facilitator to get patients interested in an ongoing discussion about their health. I want them to feel comfortable making choices and having control over their medical decisions. Dr. Becker has been practicing medicine locally since 2000 and opened his private practice in 2005. Dr. Becker enjoys reading, cycling and spending his leisure time with his beautiful wife and two young daughters.\r\n",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'Ohio University College of Osteopathic Medicine'
                    },
                    {
                        type: 'Residency',
                        name: 'College Medical Center'
                    },
                    {
                        type: 'Internship',
                        name: 'College Medical Center'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - Seal Beach Village",
                location2: " ",
                region: ["North OC"]
            },
        /*
            {
                id: 43,
                imageUrl: img1568682979,
                providerInteriorImage: img1568682979,
                sex: "Male",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [38],
                linkUrl: 'steven-l-smith',
                publicationsAndResearch: [],
                providerName: "Steven Lawrence Smith",
                providerTitle: "MD",
                npi: 1568682979,
                specialty: ["Family Medicine"],
                phone: "1-866-324-0903",
                languages: ["English"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Steven Smith, MD, is board certified in family medicine. Dr. Smith earned his medical degree from Creighton University Medical Center in Nebraska. He completed his internship and his residency at the University of Colorado. Dr. Smith aims to put his patients first and practices evidence-based and individualized care so that each patient can lead a happy and healthy life. Dr. Smith strongly believes in seeing the patient-doctor relationship as a partnership. His goal is to educate the patient to help them meet their health goals together. Dr. Smith makes sure he offers patients all the tools they need to make informed and smart decisions about their care. A few of Dr. Smith's clinical interests include diabetes, geriatric care, and heart health. When he's not caring for his patients, Dr. Smith loves to spend time outdoors and with family. He enjoys hiking, biking and maintains a garden. He's an avid reader and a guitar player.\r\n",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'Creighton University Medical Center'
                    },
                    {
                        type: 'Residency',
                        name: 'University of Colorado'
                    },
                    {
                        type: 'Internship',
                        name: 'University of Colorado'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - Los Alamitos",
                location2: " ",
                region: ["North OC"]
            },
            {
                id: 44,
                imageUrl: img1467457101,
                providerInteriorImage: img1467457101,
                sex: "Male",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [39],
                linkUrl: 'victor-caluya',
                publicationsAndResearch: [],
                providerName: "Victor C. Caluya",
                providerTitle: "MD",
                npi: 1467457101,
                specialty: ["Internal Medicine"],
                phone: "1-866-324-0903",
                languages: ["English"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Victor Caluya, MD, is board certified in internal medicine. Dr. Caluya earned his medical degree from Baylor College of Medicine in Houston. He completed his internship and residency at UC Irvine Medical Center. Dr. Caluya's patients say he is a good listener and spends extra time with him when needed. In his spare time, he enjoys playing the piano, computers, racquet sports, hiking and spending time with his family.\r\n",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'Baylor College of Medicine'
                    },
                    {
                        type: 'Residency',
                        name: 'UCI Medical Center'
                    },
                    {
                        type: 'Internship',
                        name: 'UCI Medical Center'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - Buena Park",
                location2: " ",
                region: ["North OC"]
            },

         */
            {
                id: 45,
                imageUrl: img1841677390,
                providerInteriorImage: img1841677390,
                sex: "Female",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [41],
                linkUrl: 'kelly-gee',
                publicationsAndResearch: [],
                providerName: "Kelly Gee",
                providerTitle: "DO",
                npi: 1841677390,
                specialty: ["Family Medicine"],
                phone: "1-949-305-2660",
                languages: ["English"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Kelly Gee, DO, is board certified in family medicine. Dr. Gee earned her medical degree from Touro University Nevada College of Osteopathic Medicine in Henderson, Nevada. She completed her internship and residency through the Still OPTI/Wright Center National Family Medicine HealthSource Program in New Richmond, Ohio.",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'Touro University Nevada College of Osteopathic Medicine'
                    },
                    {
                        type: 'Residency',
                        name: 'OPTI/Wright Ctr National Family Med-HealthSource'
                    },
                    {
                        type: 'Internship',
                        name: 'OPTI/Wright Ctr National Family Med-HealthSource'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                school: "",
                internship: "Still OPTI/Wright Ctr National Family Med-HealthSource",
                residency: "Still OPTI/Wright Ctr National Family Med-HealthSource",
                digital: "Yes",  boardCertified: true,
                location: "Optum - Laguna Hills",
                location2: " ",
                region: ["South OC"]
            },
            {
                id: 46,
                imageUrl: img1710932751,
                providerInteriorImage: img1710932751,
                sex: "Male",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [42],
                linkUrl: 'bakul-patel',
                publicationsAndResearch: [],
                providerName: "Bakul Patel",
                providerTitle: "MD",
                npi: 1710932751,
                specialty: ["Internal Medicine"],
                phone: "1-949-588-9293",
                languages: ["English", "Gujarati", "Hindi"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "I spend time listening to my patients, and I empower them to make educated medical decisions regarding their health, says Dr. Patel. Dr. Patel helps patients prevent and manage chronic conditions, including cardiovascular disease, diabetes, asthma, depression, anxiety, osteoporosis, dementia and sleeping disorders. He carefully reviews each patient's use of prescription drugs, over-the-counter medications and herbal supplements.",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'Welsh National School'
                    },
                    {
                        type: 'Residency',
                        name: 'University of Nebraska'
                    },
                    {
                        type: 'Internship',
                        name: 'University of Nebraska'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - Foothill Ranch",
                location2: " ",
                region: ["South OC"]
            },
            {
                id: 47,
                imageUrl: img1326466285,
                providerInteriorImage: img1326466285,
                sex: "Female",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [43],
                linkUrl: 'tracy-pantig',
                publicationsAndResearch: [],
                providerName: "Tracy Pantig",
                providerTitle: "MD",
                npi: 1326466285,
                specialty: ["Family Medicine"],
                phone: "1-949-493-6113",
                languages: ["English"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Tracy Pantig, MD, is board certified in family medicine. Dr. Pantig earned her medical degree from St. George’s University School of Medicine in Grenada. She completed her internship and residency at Zucker School of Medicine at Hofstra/Northwell at Phelps Hospital in Hempstead, New York.",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'George\'s University School of Medicine'
                    },
                    {
                        type: 'Residency',
                        name: 'Zucker School of Medicine at Hofstra/Northwell at Phelps Hospital '
                    },
                    {
                        type: 'Internship',
                        name: 'Zucker School of Medicine at Hofstra/Northwell at Phelps Hospital'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - San Clemente",
                location2: " ",
                region: ["South OC"]
            },
            {
                id: 48,
                imageUrl: img1619521556,
                providerInteriorImage: img1619521556,
                sex: "Female",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [44],
                linkUrl: 'mahsa-safavi',
                publicationsAndResearch: [],
                providerName: "Mahsa Safavi",
                providerTitle: "MD",
                npi: 1619521556,
                specialty: ["Family Medicine"],
                phone: "1-949-709-0988",
                languages: ["English", "Persian/Farsi"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Mahsa Safavi, MD, is board certified in family medicine. Dr. Safavi earned her medical degree from Tehran University of Medical Sciences in Tehran, Iran. She completed her residency at the University of Toronto Women’s College Hospital in Toronto, Canada. Dr. Safavi speaks English and Persian/Farsi.",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'Tehran University of Medical Sciences'
                    },
                    {
                        type: 'Residency',
                        name: 'University of Toronto'
                    },
                    {
                        type: 'Internship',
                        name: ' '
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - Rancho Santa Margarita",
                location2: " ",
                region: ["South OC"]
            },
        /*
            {
                id: 49,
                imageUrl: img1841432507,
                providerInteriorImage: img1841432507,
                sex: "Male",
                providerPhilosophy: [],
                organizations: [],
                professionalAssociations: [],
                providerLocations: [48],
                linkUrl: 'allen-jahroumi',
                publicationsAndResearch: [],
                providerName: "Allen Jahroumi",
                providerTitle: "MD",
                npi: 1841432507,
                specialty: ["Family Medicine"],
                phone: "1-844-939-3730",
                languages: ["English", "Persian/Farsi"],
                telehealth: "Yes",
                panel: "Accepting new patients",
                affiliation: "Optum",
                status: "Employed",
                providerBio: "Allen Jahroumi, MD, is board certified in family medicine. Dr. Jahroumi earned his medical degree from St. George’s University in Grenada. He completed his internship and residency at Stony Brook University in Stony Brook, New York. Dr. Jahroumi speaks English and Persian/Farsi.",
                qualifications: [
                    {
                        type: 'Medical School',
                        name: 'St Georges University'
                    },
                    {
                        type: 'Residency',
                        name: 'Stony Brook University'
                    },
                    {
                        type: 'Internship',
                        name: 'Stony Brook University'
                    },
                    {
                        type: "Fellowship",
                        name: ""
                    }
                ],
                digital: "Yes",  boardCertified: true,
                location: "Optum - Mission Viejo",
                location2: " ",
                region: ["South OC"]
            },

         */
        {
            id: 50,
            imageUrl: imgmichaelkarp,
            providerInteriorImage: imgmichaelkarp,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [49],
            linkUrl: 'michael-karp',
            publicationsAndResearch: [],
            providerName: "Michael W Karp",
            providerTitle: "MD",
            npi: '',
            specialty: ["Pediatrics"],
            phone: "1-833-934-3658",
            languages: ["English"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Northwestern University Medical School'
                },
                {
                    type: 'Residency',
                    name: 'University Of California, Irvine School Of Medicine'
                },
                {
                    type: 'Internship',
                    name: 'University of California, Irvine School Of Medicine'
                },
                {
                    type: "Fellowship",
                    name: "Tri-City Medical Center"
                }
            ],
            digital: "Yes",  boardCertified: true,
            location: "Optum - Vista",
            location2: " ",
            region: ["Vista"]
        },
        {
            id: 51,
            imageUrl: imglynettea,
            providerInteriorImage: imglynettea,
            sex: "Female",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [49],
            linkUrl: 'lynette-asselin',
            publicationsAndResearch: [],
            providerName: "Lynette M Asselin",
            providerTitle: "DO",
            npi: 1053484568,
            specialty: ["Pediatrics"],
            phone: "1-833-934-3658",
            languages: ["English", "Spanish"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'College of Osteopathic Medicine of the Pacific'
                },
                {
                    type: 'Residency',
                    name: 'University Of California Irvine Medical Center'
                },
                {
                    type: 'Internship',
                    name: 'University Of California Irvine Medical Center'
                },
                {
                    type: "Fellowship",
                    name: "Tri-City Medical Center"
                }
            ],
            digital: "Yes",  boardCertified: true,
            location: "Optum - Vista",
            location2: " ",
            region: ["Vista"]
        },
        {
            id: 52,
            imageUrl: imgdianeb,
            providerInteriorImage: imgdianeb,
            sex: "Female",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [49],
            linkUrl: 'diane-bedrosian',
            publicationsAndResearch: [],
            providerName: "Diane H Bedrosian",
            providerTitle: "MD",
            npi: 1447323951,
            specialty: ["Pediatrics"],
            phone: "1-833-934-3658",
            languages: ["English", "Spanish"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'University of Southern California School of Medicine'
                },
                {
                    type: 'Residency',
                    name: 'Kaiser Permanente Los Angeles'
                },
                {
                    type: 'Internship',
                    name: 'Kaiser Permanente Los Angeles'
                },
                {
                    type: "Fellowship",
                    name: "Tri-City Medical Center"
                }
            ],
            digital: "Yes",  boardCertified: true,
            location: "Optum - Vista",
            location2: " ",
            region: ["Vista"]
        },
        {
            id: 53,
            imageUrl: imgmichaelkrak,
            providerInteriorImage: imgmichaelkrak,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [49],
            linkUrl: 'michael-krak',
            publicationsAndResearch: [],
            providerName: "Michael D Krak",
            providerTitle: "MD",
            npi: 1003989419,
            specialty: ["Pediatrics"],
            phone: "1-833-934-3658",
            languages: ["English"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'University of Pennsylvania'
                },
                {
                    type: 'Residency',
                    name: 'Children\'s Hospital of Los Angeles'
                },
                {
                    type: 'Internship',
                    name: 'Children\'s Hospital of Los Angeles'
                },
                {
                    type: "Fellowship",
                    name: "Tri-City Medical Center"
                }
            ],
            digital: "Yes",  boardCertified: true,
            location: "Optum - Vista",
            location2: " ",
            region: ["Vista"]
        },
        {
            id: 54,
            imageUrl: img1629517792,
            providerInteriorImage: img1629517792,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [54],
            linkUrl: 'truongson-nguyen',
            publicationsAndResearch: [],
            providerName: "Truongson Huu Nguyen",
            providerTitle: "MD",
            npi: 1629517792,
            specialty: ["Family Medicine"],
            phone: "1-626-335-4610",
            languages: ["English", "Vietnamese"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Truongson Nguyen, DO, practices family medicine. Dr. Nguyen earned his medical degree from Western University of Health Sciences in California. He completed his internship and residency at WellSpan Good Samaritan Hospital in Pennsylvania. Dr. Nguyen plays an active role in providing quality care to all by empowering his patients to take control of their health. He creates collaborative partnerships with his patients and gives them the tools, knowledge and resources to make informed decisions for preventive care. When he's not caring for his patients, Dr. Nguyen enjoys cooking and spending time outdoors. He loves traveling and exploring different cultures. In addition to English, Dr. Nguyen speaks Vietnamese.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Western University of Health Sciences'
                },
                {
                    type: 'Residency',
                    name: 'WellSpan Good Samaritan Hospital'
                },
                {
                    type: 'Internship',
                    name: 'WellSpan Good Samaritan Hospital'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",  boardCertified: true,
            location: "Optum - Glendora",
            location2: " ",
            region: ["East San Gabriel Valley"]
        },
        {
            id: 55,
            imageUrl: imgDaishaOrtega,
            providerInteriorImage: imgDaishaOrtega,
            sex: "Female",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [21],
            linkUrl: 'daisha-ortega',
            publicationsAndResearch: [],
            providerName: "Daisha Ortega",
            providerTitle: "MD",
            npi: '',
            specialty: ["Family Medicine"],
            phone: "",
            languages: ["English","Spanish"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Daisha Ortega, MD, is board certified in family medicine. Dr. Ortega earned her medical degree from Ohio State University in Ohio. She completed both her internship and residency at Adventist Health Glendale in California. Dr. Ortega believes in patient education. She works closely with her patients to navigate complex health systems and find solutions to their health problems. Dr. Ortega is empathetic, friendly and a good listener. She creates care plans that help her patients reach their health goals. Dr. Ortega has experience with diabetes, Latinx community health and women's health. In her spare time, Dr. Ortega likes to garden, read and spend time with her family. Dr. Ortega speaks English and Spanish.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Ohio State University'
                },
                {
                    type: 'Residency',
                    name: 'Adventist Health Glendale'
                },
                {
                    type: 'Internship',
                    name: 'Adventist Health Glendale'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",  boardCertified: true,
            location: "",
            location2: " ",
            region: ["West San Gabriel Valley"]
        },
        {
            id: 56,
            imageUrl: imgPatrickAlix,
            providerInteriorImage: imgPatrickAlix,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [54],
            linkUrl: 'patrick-soriente',
            publicationsAndResearch: [],
            providerName: "Patrick Joseph Soriente Alix",
            providerTitle: "MD",
            npi: '1457352783',
            specialty: ["Internal Medicine,", " Geriatric Medicine,", " Hospice & Palliative Medicine"],
            phone: "1-626-335-4610",
            languages: ["English", "Tagalog"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Patrick Alix, MD, is board certified in internal medicine, geriatric medicine and palliative medicine. Dr. Alix earned his medical degree from the University of Santo Tomas Faculty of Medicine and Surgery in the Philippines. He completed his internship and residency at the Mercy Hospital & Medical Center in Illinois. Dr. Alix pursued health care to connect personally with people in need. He cares about improving each patient's health and happiness. He has experience working with older adults to find customized solutions to their health problems. Dr. Alix empowers his patients to be active in their health care. He likes when patients come to him with questions, so he can guide them toward better health decisions. In his spare time, Dr. Alix enjoys hiking and spending time with his family. He relaxes by cooking, gardening and listening to music. Dr. Alix speaks English and Tagalog.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'University of Santo Tomas Faculty of Medicine & Surgery'
                },
                {
                    type: 'Residency',
                    name: 'Mercy Hospital & Medical Center'
                },
                {
                    type: 'Internship',
                    name: 'Mercy Hospital & Medical Center'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",  boardCertified: true,
            location: "Optum - Glendora",
            location2: " ",
            region: ["East San Gabriel Valley"]
        },
        {
            id: 57,
            imageUrl: img1932765450,
            providerInteriorImage: img1932765450,
            sex: "Female",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [20],
            linkUrl: 'stephanie-huang',
            publicationsAndResearch: [],
            providerName: "Stephanie Huang",
            providerTitle: "MD",
            npi: '1932765450',
            specialty: ["Family Medicine"],
            phone: "1-626-576-0800",
            languages: ["English", "Mandarin"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Stephanie Huang, MD, MPH, is board certified in family medicine. Dr. Huang earned her medical degree from St. George's University School of Medicine in Grenada. She completed her internship and residency at Arrowhead Regional Medical Center in California. Dr. Huang has helped patients manage a variety of health concerns and conditions. She has cared for members of the Asian and Latinx communities, older adults, women and children. Dr. Huang also has experience helping patients manage obesity, diabetes and heart health. She pursued health care to connect with people in need and help them live happy and healthy lives. Dr. Huang enjoys working together with her patients to solve their health problems. She listens closely to each patient and treats them like friends or family. Dr. Huang believes patients should be included in creating a custom care plan that meets their needs. She is patient, friendly and takes time to answer any questions her patients have. In her spare time, she likes to relax by reading and listening to music. She enjoys hiking and spending time with her family. Dr. Huang speaks English and Mandarin.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'St. George\'s University'
                },
                {
                    type: 'Residency',
                    name: 'Arrowhead Regional Medical Center'
                },
                {
                    type: 'Internship',
                    name: 'Arrowhead Regional Medical Center'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",  boardCertified: true,
            location: "Optum - San Gabriel",
            location2: " ",
            region: ["West San Gabriel Valley"]
        },
        {
            id: 58,
            imageUrl: img1144886599,
            providerInteriorImage: img1144886599,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [21],
            linkUrl: 'zaw-ye',
            publicationsAndResearch: [],
            providerName: "Zaw Ye",
            providerTitle: "MD",
            npi: '1144886599',
            specialty: ["Family Medicine"],
            phone: "1-626-358-0269",
            languages: ["English", "Burmese"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Zaw Ye, MD, practices general medicine. Dr. Ye earned his medical degree from the Ross University School of Medicine in Florida. He completed his internship and residency at Arrowhead Regional Medical Center in California. Dr. Ye pursued a career in health care to connect with people and help them live happy and healthy lives. He has cared for patients with diabetes, obesity and heart health, with focused care for women and children. Dr. Ye is an approachable problem solver. He takes time to listen to his patients, answer their questions and create care plans that treat the whole person. Dr. Ye speaks to his patients with empathy, like friends or family. He believes in educating patients to help them make good health choices. Dr. Ye enjoys guiding his patients through the complex health care system. In his spare time, Dr. Ye hikes, travels, listens to music and spends time with his family. Dr. Ye speaks English and Burmese.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Ross University'
                },
                {
                    type: 'Residency',
                    name: 'Arrowhead Regional Medical Center'
                },
                {
                    type: 'Internship',
                    name: 'Arrowhead Regional Medical Center'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",  boardCertified: true,
            location: "Optum - Monrovia",
            location2: " ",
            region: ["West San Gabriel Valley"]
        },
        {
            id: 59,
            imageUrl: img1568768323,
            providerInteriorImage: img1568768323,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [8],
            linkUrl: 'josh-baek',
            publicationsAndResearch: [],
            providerName: "Josh Syungrul Baek",
            providerTitle: "DO",
            npi: '1568768323',
            specialty: ["Family Medicine"],
            phone: "1-626-799-4191",
            languages: ["Korean", "English"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Josh Baek, DO, is board certified in family medicine. Dr. Baek earned his medical degree from Western University of Health Sciences, College of Osteopathic Medicine of the Pacific in Pomona. He completed his internship and residency at Glendale Adventist Medical Center. Patients say that Dr. Baek is very thorough and takes extra time to address their questions and concerns. He encourages his patients to become involved in their own healthcare by eating nutritious foods, exercising, and reducing stress. In his spare time, Dr. Baek enjoys playing and watching a variety of sports. In addition to English, he speaks Korean.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Western University of Health Sciences'
                },
                {
                    type: 'Residency',
                    name: 'Glendale Adventist Medical Center Family Medicine'
                },
                {
                    type: 'Internship',
                    name: 'Glendale Adventist Medical Center Family Medicine'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",  boardCertified: true,
            location: "Optum - Glen Oaks",
            location2: " ",
            region: ["West San Gabriel Valley"]
        },
        {
            id: 60,
            imageUrl: img1891286464,
            providerInteriorImage: img1891286464,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [55],
            linkUrl: 'jamshid-akhavan',
            publicationsAndResearch: [],
            providerName: "Jamshid (Jam) Akhavan",
            providerTitle: "MD",
            npi: '1891286464',
            specialty: ["Family Medicine"],
            phone: "1-818-838-4500",
            languages: ["English", "Farsi", "Spanish"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Jamshid Akhavan, MD, is board certified in family medicine. Dr. Akhavan earned his medical degree from the University of Texas. He completed his internship and residency at Glendale Adventist Medical Center. Dr. Akhavan speaks English, Farsi and Spanish.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'University of Texas Medical Branch'
                },
                {
                    type: 'Residency',
                    name: 'Glendale Adventist Medical Center'
                },
                {
                    type: 'Internship',
                    name: 'Glendale Adventist Medical Center'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",  boardCertified: true,
            location: "Optum - Mission Hills",
            location2: " ",
            region: ["San Fernando Valley"]
        },
        {
            id: 61,
            imageUrl: img1336500594,
            providerInteriorImage: img1336500594,
            sex: "Female",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [55],
            linkUrl: 'arpine-bagerdjian',
            publicationsAndResearch: [],
            providerName: "Arpine Bagerdjian",
            providerTitle: "DO",
            npi: '1336500594',
            specialty: ["Family Medicine"],
            phone: "1-818-838-4500",
            languages: ["Armenian", "English"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Arpine Bagerdjian, DO, practices family medicine. Dr. Bagerdjian earned her medical degree from Touro University Nevada College of Osteopathic Medicine. She completed her internship and residency at Chino Valley Medical Center. Dr. Bagerdjian has wanted to be a physician since childhood. Her interest in the medical profession increased during her college years, as she volunteered at hospitals and at a center for disabled children. Dr. Bagerdjian's patients say that she is compassionate, down-to-earth, caring and attentive to their needs. Dr. Bagerdjian believes that patient education is vital because it leads to compliance with medications and lifestyle changes for improved health and well-being. In her spare time, Dr. Bagerdjian enjoys swimming, reading, traveling and spending time with her family. In addition to English, she speaks Armenian.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Touro University Nevada'
                },
                {
                    type: 'Residency',
                    name: 'Chino Valley Medical Center'
                },
                {
                    type: 'Internship',
                    name: 'Chino Valley Medical Center'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",  boardCertified: true,
            location: "Optum - Mission Hills",
            location2: "",
            region: ["San Fernando Valley"]
        },
        {
            id: 62,
            imageUrl: img1952808305,
            providerInteriorImage: img1952808305,
            sex: "Female",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [56],
            linkUrl: 'jessica-dimian',
            publicationsAndResearch: [],
            providerName: "Jessica Dimian",
            providerTitle: "MD",
            npi: '1952808305',
            specialty: ["Family Medicine"],
            phone: "1-310-316-0811",
            languages: ["English"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Jessica Dimian, MD, practices family medicine. Dr. Dimian earned her medical degree from the American University of the Caribbean School of Medicine in Saint Maarten. She completed her internship and residency at Arrowhead Regional Medical Center in California. Dr. Dimian is committed to helping people live healthier lives. She focuses on giving patients realistic, evidenced-based, and individualized health plans. Dr. Dimian is an excellent listener and works to keep an open dialogue with her patients. She's friendly and helpful by answering all her patient's questions in ways they can best understand. Her key clinical interests include helping patients treat obesity and diabetes as well as sports and integrative medicine. When she's not caring for her patients, Dr. Dimian loves to spend time with her family. She leads an active lifestyle and makes frequent visits to the gym where she enjoys weightlifting. She also loves cooking nutritious meals to support her healthy habits.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'American University of the Caribbean School of Medicine'
                },
                {
                    type: 'Residency',
                    name: 'Arrowhead Regional Medical Center'
                },
                {
                    type: 'Internship',
                    name: 'Arrowhead Regional Medical Center'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",  boardCertified: true,
            location: "Optum - Redondo Beach 502",
            location2: " ",
            region: ["South Bay"]
        },
        {
            id: 63,
            imageUrl: imgAssefa,
            providerInteriorImage: imgAssefa,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [62],
            linkUrl: 'yohannes-assefa',
            publicationsAndResearch: [],
            providerName: "Yohannes Assefa",
            providerTitle: "DO",
            npi: '',
            specialty: ["Family Medicine"],
            phone: "1-310-631-5000",
            languages: ["English"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Yohannes Assefa, DO, practices family medicine. Dr. Assefa earned his medical degree from the Western University of Health Sciences, College of Osteopathic Medicine of the Pacific in Pomona, CA. He completed his internship and residency at Presbyterian Intercommunity Hospital (PIH) in Downey, CA.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Western University, College of Osteopathic Medicine of the Pacific'
                },
                {
                    type: 'Residency',
                    name: 'Presbyterian Intercommunity Hospital (PIH)'
                },
                {
                    type: 'Internship',
                    name: 'Presbyterian Intercommunity Hospital (PIH)'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes", boardCertified: true,
            location: "Optum - Lynwood",
            location2: " ",
            region: ["Long Beach"]
        },
        {
            id: 64,
            imageUrl: imgBenNguyen,
            providerInteriorImage: imgBenNguyen,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [63],
            linkUrl: 'ben-nguyen',
            publicationsAndResearch: [],
            providerName: "Ben Nguyen",
            providerTitle: "MD",
            npi: '',
            specialty: ["Family Medicine"],
            phone: "1-562-945-6440",
            languages: ["English"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Ben Nguyen, MD, practices family medicine. Dr. Nguyen earned his medical degree from St. George's University School of Medicine. He completed his internship and residency at the Valley Consortium for Medical Education.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'St. George\'s University School of Medicine'
                },
                {
                    type: 'Residency',
                    name: 'Valley Consortium for Medical Education'
                },
                {
                    type: 'Internship',
                    name: 'Valley Consortium for Medical Education'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes", boardCertified: true,
            location: "Optum - Whittier",
            location2: " ",
            region: ["Long Beach"]
        },
        {
            id: 65,
            imageUrl: imgKnappRolf,
            providerInteriorImage: imgKnappRolf,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [64],
            linkUrl: 'rolf-knapp',
            publicationsAndResearch: [],
            providerName: "Rolf Knapp",
            providerTitle: "DO",
            npi: '1053355982',
            specialty: ["Family Medicine"],
            phone: "1-714-921-1030",
            languages: ["English"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Rolf Knapp, DO, practices family medicine. Dr. Knapp earned his medical degree from Western University of Health Sciences. He completed his internship at Deaconess Medical Center - North. Dr. Knapp completed his residency at Pacific Hospital of Long Beach.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Western University of Health Sciences'
                },
                {
                    type: 'Residency',
                    name: 'Pacific Hospital Long Beach'
                },
                {
                    type: 'Internship',
                    name: 'Deaconess Medical Center-North'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes", boardCertified: true,
            location: "Optum - Orange",
            location2: " ",
            region: ["North OC"]
        },
        {
            id: 66,
            imageUrl: imgAgatep,
            providerInteriorImage: imgAgatep,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [65],
            linkUrl: 'elmo-agatep',
            publicationsAndResearch: [],
            providerName: "Elmo Agatep",
            providerTitle: "MD",
            npi: '1043228224',
            specialty: ["Family Medicine"],
            phone: "1-562-799-7071",
            languages: ["English", "Tagalog"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Medicine offered Dr. Elmo Agatep the opportunity to combine his love of science and desire to help others. He believes in collaborative relationships with his patients and being available to provide consistent care. In his spare time, Dr. Agatep enjoys surfing, yoga, combat sports, science fiction novels, playing with his children and all things Stars Wars related.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'UC Davis School of Medicine'
                },
                {
                    type: 'Residency',
                    name: 'Long Beach Memorial Medical Center'
                },
                {
                    type: 'Internship',
                    name: 'Long Beach Memorial Medical Center'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes", boardCertified: true,
            location: "Optum - Seal Beach Family",
            location2: " ",
            region: ["North OC"]
        },
        {
            id: 67,
            imageUrl: img1528062106,
            providerInteriorImage: img1528062106,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [57],
            linkUrl: 'richard-aldana',
            publicationsAndResearch: [],
            providerName: "Richard Aldana",
            providerTitle: "MD",
            npi: '1528062106',
            specialty: ["Family Medicine"],
            phone: "1-714-995-1000",
            languages: ["English"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Richard Aldana, MD, is board certified in family medicine. Dr. Aldana earned his medical degree from USC Keck School of Medicine. He completed his internship and residency at Western Medical Center in Santa Ana. Dr. Aldana is known for his good sense of humor and ability to put his patients at ease. He encourages his patients to improve their health by practicing healthy lifestyle habits such as good nutrition, exercise and stress reduction. In his spare time, Dr. Aldana enjoys playing tennis. In addition to English, he speaks Spanish.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'USC Keck School of Medicine'
                },
                {
                    type: 'Residency',
                    name: 'Western Medical Center Santa Ana'
                },
                {
                    type: 'Internship',
                    name: 'Western Medical Center Santa Ana'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes", boardCertified: true,
            location: "Optum - Euclid",
            location2: "",
            region: ["North OC"]
        },
        {
            id: 68,
            imageUrl: img1811145113,
            providerInteriorImage: img1811145113,
            sex: "Female",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [58],
            linkUrl: 'gina-rocillo',
            publicationsAndResearch: [],
            providerName: "Gina Rocillo",
            providerTitle: "MD",
            npi: '1811145113',
            specialty: ["Family Medicine"],
            phone: "1-562-379-8000",
            languages: ["English, Tagalog"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Gina Rocillo, MD, is board certified in family medicine. Dr. Rocillo earned her medical degree from the University of Santo Tomas in the Philippines. She completed her internship and residency at Indiana University Family Medicine Center. Patients say that Dr. Rocillo is approachable, a good listener and attentive to their needs. She believes strongly in educating her patients about their health. In her spare time, she enjoys playing the piano, watching movies and spending time with her family. In addition to English, Dr. Rocillo speaks fluent Tagalog. ",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'University of Santo Tomas College of Science'
                },
                {
                    type: 'Residency',
                    name: 'Indiana University Family Practice'
                },
                {
                    type: 'Internship',
                    name: 'Indiana University Methodist Family Medicine'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes", boardCertified: true,
            location: "Optum - Cerritos",
            location2: "",
            region: ["North OC"]
        },
        {
            id: 69,
            imageUrl: imgTangSusan,
            providerInteriorImage: imgTangSusan,
            sex: "Female",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [66],
            linkUrl: 'susan-tang',
            publicationsAndResearch: [],
            providerName: "Susan Tang",
            providerTitle: "MD",
            npi: '1952780017',
            specialty: ["Family Medicine"],
            phone: "1-714-252-8311",
            languages: ["English"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Susan Tang, DO, practices family medicine. Dr. Tang earned her medical degree from Western University of Health Sciences in Pomona, California. She completed her internship and residency at Mercy Medical Center.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Western University of Health Sciences'
                },
                {
                    type: 'Residency',
                    name: 'Mercy Medical Center'
                },
                {
                    type: 'Internship',
                    name: 'Mercy Medical Center'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",  boardCertified: true,
            location: "Optum - Cypress",
            location2: " ",
            region: ["North OC"]
        },
        {
            id: 70,
            imageUrl: imgGhandi,
            providerInteriorImage: imgGhandi,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [67],
            linkUrl: 'pratik-ghandi',
            publicationsAndResearch: [],
            providerName: "Pratik Ghandi",
            providerTitle: "MD",
            npi: '1811314941',
            specialty: ["Family Medicine"],
            phone: "1-714-282-6934",
            languages: ["English", "Gujarati", "Hindi", "Spanish"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Pratik Gandhi, MD, practices family medicine. Dr. Gandhi earned his medical degree from the Universidad Autónoma de Guadalajara in Mexico. He completed his internship and residency at the New York Medical College Phelps Program. Dr. Gandhi speaks English, Gujarati, Hindi, and Spanish.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Universidad Autónoma de Guadalajara'

                },
                {
                    type: 'Residency',
                    name: 'New York Medical College Phelps Program'
                },
                {
                    type: 'Internship',
                    name: 'New York Medical College Phelps Program'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",  boardCertified: true,
            location: "Optum - Anaheim Hills",
            location2: " ",
            region: ["North OC"]
        },

        {
            id: 71,
            imageUrl: img1760574164,
            providerInteriorImage: img1760574164,
            sex: "Female",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [59],
            linkUrl: 'mahshid-elmzadeh',
            publicationsAndResearch: [],
            providerName: "Mahshid Elmzadeh",
            providerTitle: "MD",
            npi: '1760574164',
            specialty: ["Family Medicine"],
            phone: "1-949-453-4308",
            languages: ["English","Persian","Farsi"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Mahshid Elmzadeh, MD, is board certified in family medicine. Dr. Elmzadeh earned her medical degree from the American University of the Caribbean. She completed her internship and residency at St. Joseph's Hospital. Dr. Elmzadeh speaks English and Farsi.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'American University of Caribbean'
                },
                {
                    type: 'Residency',
                    name: 'St Joseph\'s Hospital'
                },
                {
                    type: 'Internship',
                    name: 'St Joseph\'s Hospital'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",  boardCertified: true,
            location: "Optum - Irvine",
            location2: " ",
            region: ["South OC"]
        },
        {
            id: 72,
            imageUrl: img1730138678,
            providerInteriorImage: img1730138678,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [60],
            linkUrl: 'mitchell-wainwright',
            publicationsAndResearch: [],
            providerName: "Mitchell Wainwright",
            providerTitle: "MD",
            npi: '1730138678',
            specialty: ["Family Medicine"],
            phone: "1-949-364-9112",
            languages: ["Spanish"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Mitchell Wainwright, MD, practices family medicine. Dr. Wainwright earned his medical degree from Universidad Autonoma de Guadalajara. He completed his internship at Martin Luther King Jr. Hospital and his residency at University of California, Irvine. ",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Universidad Autonoma de Guadalajara'
                },
                {
                    type: 'Residency',
                    name: 'Martin Luther King Jr Hospital'
                },
                {
                    type: 'Internship',
                    name: 'University of California, Irvine'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",  boardCertified: true,
            location: "Optum - Ladera Ranch",
            location2: "",
            region: ["South OC"]
        },
        {
            id: 73,
            imageUrl: img1821473190,
            providerInteriorImage: img1821473190,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [61],
            linkUrl: 'steve-yoo',
            publicationsAndResearch: [],
            providerName: "Steve Yoo",
            providerTitle: "MD",
            npi: '1821473190',
            specialty: ["Family Medicine"],
            phone: "1-714-739-4325",
            languages: ["English", "Korean"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Steve Yoo, DO, is board certified in family medicine. Dr. Yoo earned his medical degree from the Western University of Health Sciences in Pomona, California. He completed both his internship and residency at the University of Nevada, Las Vegas, in Las Vegas, Nevada. Dr. Yoo pursued a career in medicine to help people live happier and healthier lives. He treats his patients like friends or family. Dr Yoo listens to each patient's needs and works with them to find solutions to their health concerns. His clinical interests include diabetes, obesity, and Asian community health concerns. When he's not caring for his patients, Dr. Yoo enjoys reading, spending time with his family, going on walks with his dog and driving. Dr. Yoo speaks English and Korean.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Western University of health Science'
                },
                {
                    type: 'Residency',
                    name: 'University of Nevada, Las Vegas'
                },
                {
                    type: 'Internship',
                    name: 'University of Nevada, Las Vegas'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",  boardCertified: true,
            location: "Optum - Stanton",
            location2: " ",
            region: ["South OC"]
        },
        {
            id: 74,
            imageUrl: img1194111070,
            providerInteriorImage: img1194111070,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [44],
            linkUrl: 'faranak-ghazi',
            publicationsAndResearch: [],
            providerName: "Faranak Ghazi",
            providerTitle: "MD",
            npi: '1194111070',
            specialty: ["Family Medicine"],
            phone: "1-949-709-0988",
            languages: ["English", "Farsi"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Faranak Ghazi, MD, practices family medicine. Dr. Ghazi earned her medical degree from the Qazvin University of Medical Sciences in Iran. She completed her internship and residency at the Kaweah Delta Health Care District in Visalia, CA. Dr. Ghazi speaks English and Farsi.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Qazvin University of Medical Sciences'
                },
                {
                    type: 'Residency',
                    name: 'Kaweah Delta Health Care District'
                },
                {
                    type: 'Internship',
                    name: 'Kaweah Delta Health Care District'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",  boardCertified: true,
            location: "Optum - Rancho Santa Margarita",
            location2: "",
            region: ["South OC"]
        },
        {
            id: 75,
            imageUrl: imgImranShaikh,
            providerInteriorImage: imgImranShaikh,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [30],
            linkUrl: 'imran-shaikh',
            publicationsAndResearch: [],
            providerName: "Imran Shaikh",
            providerTitle: "MD",
            npi: '1043875131',
            specialty: ["Family Medicine"],
            phone: "1-310-316-0811",
            languages: ["English"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Imran Shaikh, MD, is board certified in family medicine. Dr. Shaikh earned his medical degree from the Universidad Autonoma de Guadalajara in Mexico. He completed his internship and residency at DeTar Healthcare System in Texas. Dr. Shaikh is an approachable and friendly problem solver. He pursued medicine to connect and care for people by helping them find custom health solutions. Dr. Shaikh helps his patients navigate the health system and provides answers to all their health questions. He takes time to care for patients like friends or family. In his spare time, Dr. Shaikh enjoys reading, listening to music and spending time with family. He also likes exploring the world through hiking and traveling.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Universidad Autonoma de Guadalajara'
                },
                {
                    type: 'Residency',
                    name: 'DeTar Healthcare System'
                },
                {
                    type: 'Internship',
                    name: 'DeTar Healthcare System'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes", boardCertified: true,
            location: "Optum - Del Amo",
            location2: " ",
            region: ["South Bay"]
        },
        {
            id: 76,
            imageUrl: placeHolderFemaleGrid,
            providerInteriorImage: placeHolderFemaleGrid,
            sex: "Female",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [40],
            linkUrl: 'arielle-tucker',
            publicationsAndResearch: [],
            providerName: "Arielle Tucker",
            providerTitle: "MD",
            npi: '',
            specialty: [""],
            phone: "",
            languages: [""],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "",
            qualifications: [
                {
                    type: 'Medical School',
                    name: ''
                },
                {
                    type: 'Residency',
                    name: ''
                },
                {
                    type: 'Internship',
                    name: ''
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes", boardCertified: true,
            location: "",
            location2: " ",
            region: ["removed"]
        },
        {
            id: 77,
            imageUrl: img1184003840,
            providerInteriorImage: img1184003840,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [19],
            linkUrl: 'patricio-guaiquil',
            publicationsAndResearch: [],
            providerName: "Patricio Guaiquil",
            providerTitle: "DO",
            npi: '1184003840',
            specialty: ["Family Medicine"],
            phone: "1-818-226-3666",
            languages: ["English", "French", "Spanish"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Patricio Guaiquil, DO, is board certified in family medicine. Dr. Guaiquil earned his medical degree from the Touro College of Osteopathic Medicine in New York. He completed his internship and residency at Jamaica Hospital Medical Center in New York. Dr. Guaiquil entered health care to help people live happier and healthier lives. He has experience caring for children, older adults and women, and has managed heart health concerns. Dr. Guaiquil sees his patients as partners in solving problems and finding custom care plans that suit their needs. He listens to his patients and takes time to answer their questions, helping them make informed decisions about their health care. Dr. Guaiquil uses empathy to guide his patients through health systems. He speaks English, Spanish and French.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Touro College of Osteopathic Medicine'
                },
                {
                    type: 'Residency',
                    name: 'Jamaica Hospital'
                },
                {
                    type: 'Internship',
                    name: 'Jamaica Hospital'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes", boardCertified: true,
            location: "Optum - West Hills",
            location2: " ",
            region: ["San Fernando Valley"]
        },

    ],


    buttons: [
        {
            id: 1,
            buttonCopy: "East San Gabriel Valley",
            buttonLink: "#san-gabriel-valley",
        },
        {
            id: 2,
            buttonCopy: "San Fernando Valley",
            buttonLink: "#san-fernando-valley"
        },
        {
            id: 3,
            buttonCopy: "South Bay",
            buttonLink: "#south-bay"
        },
        {
            id: 4,
            buttonCopy: "LA/Downtown",
            buttonLink: "#la-downtown"
        },
        {
            id: 5,
            buttonCopy: "South OC",
            buttonLink: "#south-oc"
        },
        {
            id: 6,
            buttonCopy: "North OC",
            buttonLink: "#north-oc"
        },
        {
            id: 7,
            buttonCopy: "Long Beach",
            buttonLink: "#long-beach"
        },
    ]
}

export default PROVIDERS_DATA;

