import React from 'react';
//import {render} from "@testing-library/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight} from "@fortawesome/free-solid-svg-icons";
import "./button.styles.scss";

const ButtonComponent = ( {id, buttonCopy, buttonLink, className, icon, hasSecondLine, buttonCopy2 } ) => {
    return (
        <>
            <a key={id} className={`${className ? className : "btn" } no-underline btn-spacing"`}
             href={`${buttonLink}`} target="_self">
                <h6 className={"font-body-1 font-white m-0 p-0 pb-1"}>
                    <b>{buttonCopy}</b>
                </h6>
                <h6 className={"font-body-1 font-white m-0 p-0"}>
                    { hasSecondLine && window.innerWidth > 525 ? <b> {buttonCopy2} </b> : "" }
                </h6>
            </a>
        </>
    )
}

export default ButtonComponent;
