import React from 'react';
import HeaderComponent from "../../components/header/header.component";
import HomepageGalleryComponent from "../../components/homepage-gallery/homepage-gallery.component";

import headerImage from "../../img/home/home-hero-header.png";
import ctaImage from "../../img/home/home-cta.png";

import HOMEPAGE_DATA from "./homepage.data";
import CTAComponent from "../../components/call-to-action/cta-footer.component";
import TextSectionButtonComponent from "../../components/text-section-button/text-section-button.component";
import LinkGalleryComponent from "../../components/link-gallery/link-gallery.component";
import {hideMask} from "../../components/navigation/navigation.component";
import './homepage.styles.scss'
import {Helmet} from "react-helmet";

class HomePage extends React.Component {
    state = HOMEPAGE_DATA;
    render() {
        return(
            <>
                <main className='page-body' onClick={hideMask}>
                        <HeaderComponent imageUrl={headerImage}
                                         title="Optum"
                                         innerCopy="Orthopedic specialists providing the care you need to live the life you want"
                                         topText="Optum Care in Nevada"
                                         isTitleDash={true}
                                         shouldHideHomeButton={true}
                        />
                        <div className="container-large">
                            <HomepageGalleryComponent/>
                            <TextSectionButtonComponent title={this.state.textSection.title}
                                                        customTitle1Class={this.state.textSection.customTitle1Class}
                                                        bodyTextLine1={this.state.textSection.bodyTextLine1}
                                                        bodyTextLine2={this.state.textSection.bodyTextLine2}
                                                        bodyTextLine3={this.state.textSection.bodyTextLine3}
                                                        customSectionClass={this.state.textSection.customSectionClass}
                            />
                        </div>
                       <LinkGalleryComponent/>
                       <div className="cta-container">
                           <CTAComponent
                               imageUrl={ctaImage}
                               htmlCopy={this.state.ctaInfo.htmlCopy}
                           />
                       </div>
                </main>
            </>
        );
    }
}

export default HomePage;
