import image1 from "../../img/home/home-gallery-1.png";
import image2 from "../../img/home/home-gallery-2.png";
import image3 from "../../img/home/home-gallery-3.png";

const HOMEPAGE_DATA = {
    interiorSections: [
        {
            title: "About us",
            bodyCopy: "Urology Specialists of Nevada (USONV) was founded in 1996 by Dr. Robert McBeath, a lifelong Nevada resident and second-generation physician. His goal was to create the highest-quality and most technologically advanced urology practice in the Las Vegas valley. Today, USONV is one of the largest urology practices in Southern Nevada.",
            imageUrl: image1,
            id: 1
        },
        {
            bodyCopy: "We are dedicated to continuously offering the most advanced surgical procedures and technology. USONV continues to use the latest in diagnostic and treatment options for urologic conditions.",
            imageUrl: image2,
            imageLeft: true,
            id: 2
        },
        {
            bodyCopy: 'Our innovative technology and ongoing commitment to implement best practices in urologic care make USONV the preferred choice for patients in Southern Nevada, Northern Arizona and California.',
            imageUrl: image3,
            id: 3
        }
    ],
    galleryItems : [
        {
            title: 'The Optum Care difference',
            imageUrl: image1,
            bodyCopy: "We provide ethical, compassionate and technically outstanding care. We regularly evaluate our methods so we can deliver the right care to each patient. From the moment a patient begins their journey with us, to months even years after treatment, we're always looking out for their best interests. Our focus is on giving our patients a better and pain-free life.",
            // buttonText: 'See the difference',
            // linkUrl: '#',
            id: 1
        },
        {
            title: 'UNLV Kirk Kerkorian School of Medicine Partnership',
            imageUrl: image2,
            bodyCopy: 'The UNLV orthopedic surgeon residency program is the first of its kind in Nevada. Residents learn from our surgeons and train at UMC, a busy level 1 trauma center. This education provides a solid foundation for their future as leading orthopedic surgeons who deliver excellent care.',
            buttonText: 'Learn more',
            linkUrl: '/articles/UNLV-Partnership/',
            id: 2
        },
        {
            title: 'Our expertise/research',
            imageUrl: image3,
            bodyCopy: "Our collaboration with UNLV keeps us up to date on the latest in technology and evidence-based medicine. We’re continuously learning, conducting research, and testing the most innovative technologies to ensure we deliver the most positive impact on our patient's lives.",
            buttonText: 'Learn more',
            linkUrl: '/articles/expertise-and-research',
            id: 3
        }
    ],
    textSection: {
        title: 'Serving Las Vegas through orthopedic excellence',
        customTitle1Class: 'dash',
        bodyTextLine1:'We offer highly specialized orthopedic care in a professional and friendly setting. Our focus is delivering outstanding care to every patient experiencing orthopedic-related challenges and injuries. We approach each patient with compassion and understanding.',
        bodyTextLine2: 'As our patient, we’ll review all your options and provide educational resources to develop a treatment plan personalized to your condition. We’re committed to providing you with the highest quality of care and treatment available.',
        customSectionClass: 'container-fluid col-12 col-lg-8'
    },
    linkGallery: [
            {
                title: 'General orthopedics',
                buttonText: 'Learn more',
                linkUrl: '/specialties/general-orthopedics/',
                id: 1
            },
            {
                title: 'Joint replacement and revision',
                buttonText: 'Learn more',
                linkUrl: '/specialties/joint-replacement-and-revision/',
                id: 2
            },
            {
                title: 'Sports medicine',
                buttonText: 'Learn more',
                linkUrl: '/specialties/sports-medicine/',
                id: 3
            },
            {
                title: 'Shoulder',
                buttonText: 'Learn more',
                linkUrl: '/specialties/shoulder/',
                id: 4
            },
            {
                title: 'Elbow',
                buttonText: 'Learn more',
                linkUrl: '/specialties/elbow/',
                id: 5
            },
            {
                title: 'Wrist and hand',
                buttonText: 'Learn more',
                linkUrl: '/specialties/wrist-and-hand',
                id: 6
            },
            {
                title: 'Spine',
                buttonText: 'Learn more',
                linkUrl: '/specialties/spine/',
                id: 7
            },
            {
                title: 'Hips and pelvis',
                buttonText: 'Learn more',
                linkUrl: '/specialties/hips-and-pelvis/',
                id: 8
            },
            {
                title: 'Knee',
                buttonText: 'Learn more',
                linkUrl: '/specialties/knee/',
                id: 9
            },
            {
                title: 'Foot and ankle',
                buttonText: 'Learn more',
                linkUrl: '/specialties/foot-and-ankle/',
                id: 10
            },
            {
                title: 'Trauma and fracture care',
                buttonText: 'Learn more',
                linkUrl: '/specialties/trauma-and-fracture-care/',
                id: 11
            },
            {
                title: 'Pediatric',
                buttonText: 'Learn more',
                linkUrl: '/specialties/pediatric/',
                id: 12
            }
    ],
    ctaInfo : {
        title: 'Schedule a consultation',
        htmlCopy: `<h3 class='dash'>Schedule a consultation</h3>
                    <p>To consult with an orthopedic specialist, call to schedule an appointment with Optum Care Orthopaedics and Spine today.</p>
                    <button class="btn-alt"><a href="tel:+17027248877"><b>Call: 1-702-724-8877</b></a></button>
                  `
    }
}

export default HOMEPAGE_DATA;
