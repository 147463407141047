import image1 from "../../../img/figma/hips_pelvis-1.png";
import image2 from "../../../img/figma/hips_pelvis-2.png";
import headerImage from "../../../img/figma/hips_pelvis_banner.png";
import '../specialties.styles.scss';

const HIPS_AND_PELVIS_DATA = {
    pageTitle: 'Hips and pelvis',
    headerComponent: {
        imageUrl: headerImage,
        title: "Hips and pelvis",
        innerCopy: "Care and treatment to relieve pain and improve your quality of life",
        shouldHideHomeButton: true
    },
    textSection1: {
        bodyTextLine1: "The hip aids in movement while walking and is the largest ball-and-socket joint in the body. The hip joints bear a lot of weight, and due to its size, even the slightest discomfort can affect the way the entire body moves. Hip pain can often make it difficult to walk, turn, or pick something off the ground. Hip conditions can be caused by sports-related wear and tear, trauma, direct injuries or the natural degenerative nature of joints over time depending on age, weight, and activity.",
        bodyTextLine2: "At OptumCare Orthopaedics and Spine, we offer a cutting-edge approach to navigate hip surgeries. Our use of OrthoGrid technology results in a more accurate positioning of hip replacement, which decreases both the rate of wear and tear, as well as complications such as hip infection. From diagnosis to recovery, we offer comprehensive and the highest quality care.",
    },
    interiorSections: [
        {
            title: "Conditions",
            bodyCopy: "Hips and pelvis conditions we treat include: ",
            htmlCopy: "<ul><li>Arthritis</li><li>Fractures</li><li>Tendinitis and bursitis</li><li>Bone spurs</li><li>Bursitis</li><li>Cartilage degeneration</li><li>Dysplasia</li><li>Fractures</li><li>Hip joint deformities</li><li>Hip impingement</li><li>Labral tears</li><li>Nerve conditions, such as a pinched nerve</li><li>Osteoarthritis</li><li>Osteoporosis</li><li>Avascular necrosis</li><li>Paget’s disease</li><li>Post-traumatic arthritis</li></ul>",
            imageUrl: image1,
            id: 1
        },
        {
            title: "Treatment",
            htmlCopy: "<ul><li>Hip replacement</li><li>Joint replacement</li><li>Physical therapy</li><li>Dislocation</li><li>Arthroplasty</li><li>Arthroscopy</li><li>Complex hip revision surgery</li><li>Direct anterior hip replacement</li><li>Labral repair</li><li>Preservation procedures, such as pelvic osteotomy</li><li>Resurfacing</li><li>Total hip replacement</li></ul>",
            imageUrl: image2,
            customClassDiv: 'reverse-row',
            id: 2
        }
    ],
    textSection2: {
        bodyTextLine1: "At OptumCare Orthopaedics and Spine, our specialists understand that hip and pelvis pain can prevent our patients from a full quality of life. We strive to treat conservatively with minimally invasive treatments. When an injury or condition requires more advanced treatment, we offer patients options and tools to choose the best course of action for their unique health goals. Our surgeons use the latest technology with the goal of complete recovery.",
    },
    providersCta: {
        htmlCopy: "<h3>Schedule an appointment</h3><p>Ready to talk with a top-rated orthopedic specialist in Las Vegas?</p><p class='small-text'>Schedule an appointment with OptumCare Orthopaedics and Spine today.</p>",
        providersList: [
            {
                id:1,
                providerName: "Benjamin J Hansen, MD",
                link: "/providers/benjamin-hansen"
            },
            {
                id:2,
                providerName: "Erik Kubiak, MD",
                link: "/providers/erik-kubiak"
            },
            {
                id: 3,
                providerName: "Brittany Hough, PA-C",
                link: "/providers/brittany-hough"
            }
        ],
        button: {
            id: 1,
            link: 'tel:+17027248877',
            text: 'Call: 1-702-724-8877'
        }
    }
}

export default HIPS_AND_PELVIS_DATA;
