import './App.css';
import './main'
import 'bootstrap/dist/css/bootstrap.min.css';
import './main.styles.scss'
import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';



import AuxiliaryComponent from "./components/auxiliary/auxiliary-bar.component"
import NavigationComponent from "./components/navigation/navigation.component";
import HomePage from './pages/homepage/homepage.component';
import ProvidersPage from "./pages/providers-gallery/providers-gallery.component";
import LocationsPage from "./pages/locations/locations.component";
import MensHealthPage from "./pages/services/mens-health/mens-health.component";
import FooterComponent from "./components/footer/footer.component"
import WomensHealthPage from "./pages/services/womens-health.component.jsx/womens-health.component";
import NewPatientInformationPage
    from "./pages/patient-resources/new-patient-information/new-patient-information.component";
import ProcedureConsentFormsPage
    from "./pages/patient-resources/procedure-consent-forms/procedure-consent-forms.component";
import HowToPayYourBillPage from "./pages/patient-resources/how-to-pay-your-bill/how-to-pay-your-bill.component";
import AboutUsPage from "./pages/about-us/about-us.component";
import CancerCarePage from "./pages/services/cancer-care/cancer-care.component";
import ProviderTemplatePage from "./pages/providers-single-page/providers-template.component";
import GeneralOrthopedicsPage from "./pages/specialties/general-orthopedics/general-orthopedics.component";
import JointReplacementRevisionPage from './pages/specialties/joint-replacement-revision/joint-replacement-revision.component';
import SportsMedicinePage from './pages/specialties/sports-medicine/sports-medicine.component';
import ShoulderPage from './pages/specialties/shoulder/shoulder.component';
import ElbowPage from './pages/specialties/elbow/elbow.component';
import WristHandPage from './pages/specialties/wrist-and-hand/wrist-and-hand.component';
import SpinePage from "./pages/specialties/spine/spine.component";
import HipsPelvisPage from './pages/specialties/hips-and-pelvis/hips-and-pelvis.component';
import KneePage from './pages/specialties/knee/knee.component';
import FootAnklePage from './pages/specialties/foot-and-ankle/foot-and-ankle.component';
import TraumaFracturePage from './pages/specialties/trauma-and-fracture-care/trauma-and-fracture-care.component';
import PediatricPage from './pages/specialties/pediatric/pediatric.component';
import ArticlePage from "./articles/articles.component";
import OncologyPage from './pages/specialties/oncology/oncology.component';
import RegionTemplateComponent from "./pages/regional-pages/regions-template.component";
import StickyCTA from "./components/sticky-header/sticky-header.component";
import NoRouteRedirect from "./pages/no-route-redirect/no-route-redirect.component";
import ProvidersTemplateComponent from "./pages/providers-single-page/providers-template.component";
import Login from "./components/form/login.component";
import FooterAuxComponent from "./components/footer/footer-aux/footer-aux.component";
import FooterBottom from "./components/footer/footer-bottom.component";

class App extends React.Component {
    render(){
        return (
            <main>
                    <Switch>
                        <Route exact
                               path='/'
                               component={() => {
                                   window.location.replace('https://www.optum.com/california');
                                   return null;
                               }}
                        />

                        <Route exact path='/providers'
                            component={() => {
                            window.location.replace('https://www.optum.com/california');
                            return null;
                        }}
                        />

                        <Route exact path='/regions' component={() => {
                                window.location.replace('https://www.optum.com/california');
                                return null;
                            }}
                        />

                        <Route exact path='/about-us'
                               component={() => {
                                window.location.replace('https://www.optum.com/california');
                                return null;
                            }}
                        />
                        <Route path='/providers/:providerId' component={ProviderTemplatePage}/>
                        <Route path='/regions/:regionId' component={RegionTemplateComponent} />
                    </Switch>
                <FooterAuxComponent />
                <FooterBottom />
            </main>
        );
    }
}

export default App;
