import React, {Fragment} from 'react';
import {hideMask} from "../navigation/navigation.component";
import HeaderComponent from "../header/header.component";
import TextSectionButtonComponent from "../text-section-button/text-section-button.component";
import Interior5050Component from "../interior-50-50/interior-50-50.component";
import DividerArrowDownComponent from "../divider-arrow-down/divider-arrow-down.component";
import CTAProviderComponent from "../call-to-action/cta-provider/cta-provider.component";
import ctaImage from "../../img/cta-image.png";
import {Helmet} from "react-helmet";

export default function SpecialtyLayout(props){
    console.log(props.data.headerComponent);
    return(
        <Fragment>
            <Helmet>
                <title>{props.data.pageTitle} | Optum Care Orthopaedics and Spine</title>
            </Helmet>
            <main className='page-body' onClick={hideMask}>
                <HeaderComponent
                    imageUrl={props.data.headerComponent.imageUrl}
                    title={props.data.headerComponent.title}
                    innerCopy={props.data.headerComponent.innerCopy}
                    shouldHideHomeButton={props.data.headerComponent.shouldHideHomeButton}
                />
                <TextSectionButtonComponent
                    bodyTextLine1={props.data.textSection1.bodyTextLine1}
                    bodyTextLine2={props.data.textSection1.bodyTextLine2}
                    customDivClass="cards-no-border col-12 col-lg-9"
                    customSectionClass="container-fluid"
                />
                {
                    props.data.interiorSections.map(({id, ...otherSectionprops } ) => (
                        <Interior5050Component key={id} {...otherSectionprops}/>
                    ))
                }
                <TextSectionButtonComponent
                    bodyTextLine1={props.data.textSection2.bodyTextLine1}
                    bodyTextLine2={props.data.textSection2.bodyTextLine2}
                    customDivClass="cards-no-border col-12 col-lg-9 mt-5"
                    customSectionClass="container-fluid"
                />
                <DividerArrowDownComponent title={props.data.pageTitle + " specialist"}/>
                <div className="cta-container">
                    <CTAProviderComponent
                        imageUrl={ctaImage}
                        htmlCopy={props.data.providersCta.htmlCopy}
                        providersList={props.data.providersCta.providersList}
                        button={props.data.providersCta.button}
                    />
                </div>
            </main>
        </Fragment>
    )
}
