import React from 'react';
import {connect} from "react-redux";
import {Route} from "react-router";
import {selectIndividualRegion} from "../../redux/region/region.selectors";
import './regions-template.styles.scss';
import {Helmet} from "react-helmet";
import NavigationComponent, {hideMask} from "../../components/navigation/navigation.component";
import providerData from "../providers-gallery/provider.data";
import ProviderGallery from "../../components/provider-gallery/provider-gallery.component";
import RegionIndividual from "../../components/region-individual/region-individual.component";
import heroImage from "../../img/hero_region.png";
import HeaderComponent from "../../components/header/header.component";
import StickyCTA from "../../components/sticky-header/sticky-header.component";
import AuxiliaryComponent from "../../components/auxiliary/auxiliary-bar.component";
import FooterComponent from "../../components/footer/footer.component";
import HeaderHero from "../../components/header-hero/header-hero.component";
import PAGE_DATA from "./regions.data";



const RegionTemplatePage = ({ region, match }) => {
    return (
        <>
            <Helmet>
                <title>{region.regionName} | Optum</title>
            </Helmet>
            <StickyCTA/>
            <NavigationComponent/>
            <main className='page-body' onClick={hideMask}>
                <HeaderHero info={PAGE_DATA.hero} />
                <Route exact path={`${match.path}`} component={RegionIndividual} phone={region.regionPhone}/>
            </main>
        </>
    )
};

const mapToStateProps = (state, ownProps) => ({
    region: selectIndividualRegion(ownProps.match.params.regionId)(state)
})

export default connect(mapToStateProps)(RegionTemplatePage);
