import React from 'react';
import SpecialtyLayout from "../../../components/Layouts/SpecialtyPage.component";
import SPORTS_MEDICINE_DATA from "./sports-medicine.data";

class SportsMedicinePage extends React.Component {
    state = SPORTS_MEDICINE_DATA;
    render() {
        return(
            <SpecialtyLayout data={this.state}/>
        );
    }
}

export default SportsMedicinePage;
