import React from 'react';
import SpecialtyLayout from "../../../components/Layouts/SpecialtyPage.component";
import PEDIATRIC_DATA from "./pediatric.data";

class PediatricPage extends React.Component {
    state = PEDIATRIC_DATA;
    render() {
        return(
            <SpecialtyLayout data={this.state}/>
        );
    }
}

export default PediatricPage;
