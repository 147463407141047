import React from 'react';
import SpecialtyLayout from "../../../components/Layouts/SpecialtyPage.component";
import SPINE_DATA from "./spine.data";

class SpinePage extends React.Component {
    state = SPINE_DATA;
    render() {
        return(
            <SpecialtyLayout data={this.state}/>
        );
    }
}

export default SpinePage;
