
import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import firebase from "firebase/compat";
import { getDatabase, set, ref } from "firebase/database";
import config from "../../configs";

class FormComponent extends React.Component {
    constructor(props) {
        super(props);
        firebase.initializeApp(config);

        this.state = {
            firstname: "",
            lastname: "",
            phone: "",
            province: "",
            zip: "",
            providerUrl: window.location.pathname,
            message: "",
            timestamp: "",
            show: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.writeUserData = this.writeUserData.bind(this);
    }

    componentDidMount() {
        let currentdate = new Date();
        let pst = currentdate.toLocaleString('en-US', {
            timeZone: 'America/Los_Angeles',
        });
        pst = pst.replace(","," -");
        let datetime = currentdate.getDate() + "/"
            + (currentdate.getMonth()+1)  + "/"
            + currentdate.getFullYear() + " @ "
            + currentdate.getHours() + ":"
            + currentdate.getMinutes() + ":"
            + currentdate.getSeconds();
        this.setState({timestamp: pst});
    }

    writeUserData = async (e) => {
        e.preventDefault();
        const db = firebase.firestore();
        let date = Date.now();
        const {firstname, lastname, phone, zip, province, providerUrl, timestamp} = this.state;
        const res = await db.collection('formSubmissions').doc(date.toString()).set({
            firstname: firstname,
            lastname: lastname,
            phone: phone,
            state: province,
            zip: zip,
            providerurl: providerUrl,
            timestamp: timestamp,
            status: "Live",
        });
        console.log('DATA SAVED');
        console.log(res);
        this.setState({ show: true,
        firstname: "",
        lastname: "",
        zip: "",
        phone: ""});

    }
    handleChange(event) {
        switch (event.target.name) {
            case 'firstname':
                this.setState({firstname : event.target.value});
                break;
            case 'lastname':
                this.setState({lastname: event.target.value});
                break;
            case 'phone':
                this.setState({phone: event.target.value});
                break;
            case 'zip':
                this.setState({zip: event.target.value});
                break;
            case 'province':
                this.setState({province: event.target.value});
                break;
        }
    }

    render() {
    const {show, message, firstname, lastname, zip, phone, province} = this.state;
    return (
        <>
            <Alert show={show} variant="info">
                <p className={"font-body-3"}>
                    Thanks for your submission, our specialists will contact you within 72 hours.
                </p>
            </Alert>

            <div className={"row"} id={""}>
                <div className={"col-12 text-center"}>
                    <h3 className={"font-body-1"}>Request a callback</h3>
                    <h5 className={"font-body-2"}>Our representatives can call you back to schedule an appointment with an Optum doctor or answer questions. Please leave your information below and our specialists will contact you within 72 hours.</h5>
                </div>

                <form className={"form-group"} onSubmit={this.writeUserData} name={"callback-form"} id={"callback-form"}>
                    <div className={"row mx-2"}>
                        <div className={"col-6"}>
                            <label htmlFor="firstname">First Name *</label>
                            <input
                                required
                                type="text"
                                id={"firstname"}
                                name={"firstname"}
                                value={firstname}
                                className={"form-control mb-3"}
                                placeholder="First Name"
                                onChange={(e) => this.handleChange(e)}
                            />
                        </div>
                        <div className={"col-6"}>
                            <label htmlFor="lastname">Last Name *</label>
                            <input
                                required
                                type="text"
                                id={"lastname"}
                                name={"lastname"}
                                value={lastname}
                                className={"form-control mb-3"}
                                placeholder="Last Name"
                                onChange={(e) => this.handleChange(e)}
                            />
                        </div>
                        <div className={"col-6"}>
                            <label htmlFor="province">State of Residence</label>
                            <select
                                value={province}
                                id={"province"}
                                name={"province"}
                                placeholder="State"
                                className={"form-control mb-2"}
                                onChange={(e) => this.handleChange(e)}
                            >
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="DC">District Of Columbia</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                            </select>
                        </div>
                        <div className={"col-6"}>
                            <label htmlFor="zip">Zip Code *</label>
                            <input
                                required
                                type="text"
                                value={zip}
                                id={"zip"}
                                name={"zip"}
                                placeholder="Zip code"
                                className={"form-control mb-2"}
                                onChange={(e) => this.handleChange(e)}
                            />
                        </div>
                    </div>
                    <div className={"row mx-2"}>
                        <div className={"col-12"}>
                            <label htmlFor="phone">Phone Number *</label>
                            <input
                                required
                                type="text"
                                value={phone}
                                id={"phone"}
                                name={"phone"}
                                placeholder="Phone number"
                                className={"form-control mb-2"}
                                onChange={(e) => this.handleChange(e)}
                            />
                        </div>
                    </div>
                    <div className={"row mt-2"}>
                        <div className={"col-12 text-center"}>
                            <h5 className={"font-small"}>Fields marked with an asterisk * are required.</h5>
                            <button className={'btn'} type="submit">Request a callback</button>
                            <h5 className={"mt-2 mx-2 font-body-3"}>By clicking submit, I agree to receive notification emails from Optum
                                regarding my health including reminders, services, wellness tips or
                                products available to me. You understand that these emails containing
                                protected health information will be unencrypted, and there is some risk
                                of interception.
                                To opt out at a later time, visit: <a href={"https://optumcare.com/optout"}>optumcare.com/optout</a></h5>
                        </div>
                    </div>
                </form>
            </div>
        </>


    );
}

}

export default FormComponent;