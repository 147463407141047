import navLogo from "../../img/nav-logo.png";
import navLogoUNLV from '../../img/UNLV_banner.png'; 

const NAVIGATION_DATA = {
    navLogo: navLogo,
    navLogoUNLV: navLogoUNLV,
    homeUrl: '/',
    linkItems: [
        {
            id: 2,
            name: 'Regions',
            link: '#',
            children: [
                {
                    name: 'San Gabriel Valley',
                    link: "/regions/san-gabriel-valley"
                },
                {
                    name: 'San Fernando Valley',
                    link: "/regions/san-fernando-valley"
                },
                {
                    name: 'South Bay',
                    link: "/regions/south-bay"
                },
                {
                    name: 'Long Beach',
                    link: "/regions/long-beach"
                },
                {
                    name: 'LA/Downtown',
                    link: "/regions/la-downtown"
                },
                {
                    name: 'South OC',
                    link: "/regions/south-oc"
                },
                {
                    name: 'North OC',
                    link: "/regions/north-oc"
                },
            ]
        },
        /*
        {
            id: 2,
            name: 'Specialties',
            link: '#',
            children: [
                {
                    name: 'Elbow',
                    link: "/specialties/elbow"
                },
                {
                    name: 'Foot and ankle',
                    link: "/specialties/foot-and-ankle"
                },
                {
                    name: 'General orthopedics',
                    link: "/specialties/general-orthopedics"
                },
                {
                    name: 'Hips and pelvis',
                    link: "/specialties/hips-and-pelvis"
                },
                {
                    name: 'Joint replacement and revision',
                    link: "/specialties/joint-replacement-and-revision"
                },
                {
                    name: 'Knee',
                    link: "/specialties/knee"
                },
                {
                    name: 'Oncology',
                    link: '/specialties/oncology'
                },
                {
                    name: 'Pediatric',
                    link: "/specialties/pediatric"
                },
                {
                    name: 'Shoulder',
                    link: "/specialties/shoulder"
                },  
                {
                    name: 'Spine',
                    link: "/specialties/spine"
                },             
                {
                    name: 'Sports medicine',
                    link: "/specialties/sports-medicine"
                },
                {
                    name: 'Trauma and fracture care',
                    link: "/specialties/trauma-and-fracture-care"
                },
                {
                    name: 'Wrist and hand',
                    link: "/specialties/wrist-and-hand"
                },
            ]
        },
        */


        // {
        //     id: 5,
        //     name: 'About us',
        //     link: '#',
        //     children: []
        // },
    ],
    rightHtml: ``
}

export default NAVIGATION_DATA;
