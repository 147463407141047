import React from 'react';
import ProviderThumbnail from "../provider-gallery/provider-thumbnail/provider-thumbnail.component";
import "./region-individual.styles.scss"
import {connect} from "react-redux";
import { createStructuredSelector } from 'reselect';
import { selectProviderSections } from "../../redux/provider/provider.selectors";
import { selectIndividualRegion } from "../../redux/region/region.selectors";
import DividerArrowDownComponent from "../divider-arrow-down/divider-arrow-down.component";
// import { getProvidersBySpecialty } from "../../main";
import { getProvidersByRegion } from "../../main";
import {selectRegionSections} from "../../redux/region/region.selectors";
import ButtonComponent from "../buttons/button.component";
import ModalComponent from '../modal/modal.component';

const RegionIndividual = ({ match, sections: regions, phone}) => {

    let regionName = match.params.regionId.replace(/-/g, ' ');

    let regionPhone = regions.find(name => regionName === name.linkUrl.replace(/-/g, ' ')).regionPhone;
    let phoneLink = regionPhone.replace('-', '');

    console.log(phoneLink)

    switch (regionName) {
        case 'south oc':
            regionName = 'South OC'
            break
        case 'north oc':
            regionName = 'North OC'
            break
        case 'la downtown':
            regionName = 'LA/Downtown'
            break
        default:
            regionName = regionName.split(' ').map(w => w[0].toUpperCase() + w.substring(1).toLowerCase()).join(' ');
    }
    var providerMap = getProvidersByRegion(regionName);

    return (
        <div className="provider-gallery-component w-100 h-100 container-fluid bg-beige">
            <div className={"row justify-content-center my-xs-2 my-md-3 bg-white"}>
                <div className={"col-10 col-md-12 text-center justify-content-center my-5"}>
                    <div className={"row justify-content-center"}>
                        <div className={"col-12 col-md-6 text-center"}>
                            <h1>Get the care you need, when and where you need it</h1>
                        </div>
                    </div>
                    <h1 className="d-none d-md-block text-center align--center">  </h1>
                    <div className={"row w-100 mt-3"}/>
                    <ButtonComponent hasSecondLine={false} buttonCopy={"Call to schedule your visit: "+regionPhone} buttonLink={"tel:+"+phoneLink}/>
                    <div className={"row w-100 my-2"}/>
                    <ModalComponent />
                </div>
            </div>
            <div className={"row justify-content-center mt-5 pb-5"} id={"ProvidersAnchor"}>
                <div className={'col-12 text-center'}>
                    <h1 className="align--center my-5">Meet your {regionName !== 'San Gabriel Valley 2' ? regionName : 'San Gabriel Valley'} Providers</h1>

                </div>
            </div>
            <div className="row h-100 pb-3">
                {
                    providerMap.map(({id, ...otherSectionProps}) => (
                        <ProviderThumbnail key={id} {...otherSectionProps} />
                    ))
                }
            </div>
           {/* <div className={"row justify-content-center"}>
                {
                    regionName === "San Gabriel Valley" ? (
                        <div className={"col-10 col-md-12 text-center justify-content-center pb-5"}>
                            <a className={"font-gray font-body-2"} href={"#"}>Page 1</a>
                            <span className={"font-body-2"}> | </span>
                            <a className={"font-blue font-body-2"} href={"/regions/san-gabriel-valley-2#ProvidersAnchor"}>Page 2</a>
                        </div>
                    ) : null
                }
                {
                    regionName === "San Gabriel Valley 2" ? (
                        <div className={"col-10 col-md-12 text-center justify-content-center pb-5"}>
                            <a className={"font-blue font-body-2"} href={"/regions/san-gabriel-valley#ProvidersAnchor"}>Page 1</a>
                            <span className={"font-body-2"}> | </span>
                            <a className={"font-gray font-body-2"} href={"#"}>Page 2</a>
                        </div>
                    ) : null
                }
            </div>*/}
            <div className={"row justify-content-center pb-5"}>
                <div className={"col-10 col-md-12 text-center justify-content-center"}>

                    <ButtonComponent hasSecondLine={false} buttonCopy={"Call to schedule your visit: "+regionPhone} buttonLink={"tel:+"+phoneLink}/>
                    <div className={"row w-100 my-2"}/>
                    <ModalComponent/>
                </div>
            </div>
            <div className={"row justify-content-center py-5 bg-white"}>
                <div className={"col-10 col-md-7 text-center"}>
                    <h6 className={"font-body-3 font-gray my-3"}>
                        This program should not be used for emergency or urgent care needs. In an emergency, call 911 or go to the nearest emergency room. The information provided through this service is for your information only. It is provided as part of your health plan. Program nurses and other representatives cannot diagnose problems or suggest treatment. This program is not a substitute for your doctor’s care. Your health information is kept confidential in accordance with the law. This is not an insurance program and may be discontinued at any time.
                    </h6>
                        <h6 className={"font-body-3 font-gray my-3"}>
                        * Press Ganey patient satisfaction survey for patients visit in California from July 1, 2019 to June 30, 2020. Inclusive of in-person and video visits for Group/Employed Providers for Optum and Optum Care branded clinics.
                    </h6>
                </div>
            </div>
        </div>)
};

const mapStateToProps = createStructuredSelector({
    sections: selectRegionSections,
});

export default connect(mapStateToProps)(RegionIndividual);
