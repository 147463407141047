import React from "react";
import '../header-hero/header-hero.styles.scss';
import HEADER_HERO_DATA from "./header-hero.data";

class HeaderHero extends React.Component {
    state = HEADER_HERO_DATA;
    info = this.props.info;
    render() {
        return (<>
            <div className={"row hero-div"}>
                <div className={"hero-div"} style={{
                    position:"absolute", left: "0",
                    width: "100%",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundImage: `url(${this.props.info.backgroundImg})`,
                    //filter: "contrast(50%) brightness(50%)",
                }}>
                </div>
                <div className={"row w-100 hero-div align-items-center mb-5"} style={{position: "relative"}}>

                    <div className={"col-12 col-md-12 col-lg-5 pl-5 pl-md-5"} style={{paddingLeft: "100px"}} >
                        <span className={"pl-5"}>
                            <h1 className={"mb-5 ml-5 d-none d-md-none d-lg-block"}>{this.props.info.title}</h1>
                            <p className={"font-body-1 my-3 ml-5 font-navy-blue d-none d-md-none d-lg-block"}>{this.props.info.text}</p>
                            <p className={"font-body-1 my-3 ml-5 font-navy-blue d-none  d-md-none d-lg-block"}>{this.props.info.text2}</p>
                        </span>
                    </div>
                    <div className={"col-md-8"}>

                    </div>
                </div>
            </div>
                <div className={"row text-block d-lg-none"}>
                    <div className={"col-10 text-center"}>
                        <h1 className={"my-2"}>{this.props.info.title}</h1>
                        <p className={"font-body-2 my-3 font-navy-blue"}>{this.props.info.text}</p>
                        <p className={"font-body-2 my-3 font-navy-blue"}>{this.props.info.text2}</p>
                    </div>
                </div>
        </>


        )
    }
}

export default HeaderHero