import React, {useRef, useState} from 'react';
import "./sticky-header.styles.scss";
import {useEffect} from "react";
import REGIONS_DATA from "../../pages/regional-pages/regions.data";
import PROVIDERS_DATA from "../../pages/providers-gallery/provider.data";

const StickyCTA = ({}) => {
    // Sticky Menu Area
    useEffect(() => {
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    });

    const prevScrollY = useRef(0);
    const [goingUp, setGoingUp] = useState(false);

    const isSticky = () => {
        const header = document.querySelector('.header-section');
        const scrollTop = window.scrollY;
        const viewportHeight = window.visualViewport.offsetTop;

        scrollTop >= 30 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
        scrollTop >= 30 ? header.classList.remove('d-none') : header.classList.add('d-none');
        viewportHeight === 0 ? header.classList.add('is-scroll-up') : header.classList.remove('is-scroll-up');
    };

    const onScroll = (e) => {
        const currentScrollY = e.target.scrollTop;
        if (prevScrollY.current < currentScrollY && goingUp) {
            setGoingUp(false);
        }
        if (prevScrollY.current > currentScrollY && !goingUp) {
            setGoingUp(true);
        }
        isSticky();
        prevScrollY.current = currentScrollY;
    };

    const regions = REGIONS_DATA;
    const phoneInfo = PROVIDERS_DATA;

    const provider = phoneInfo.sections.find(provider => provider.linkUrl === window.location.pathname.replace("/providers/", ""));
    const region = regions.sections.find(region => region.linkUrl === window.location.pathname.replace("/regions/", ""));
    let phoneLink = '';
    if (window.location.pathname !== '/' && window.location.pathname !== '/providers' && window.location.pathname !== '/regions' ) {
        phoneLink = region ? ("tel:+"+region.regionPhone.replaceAll("-", "")) : ("tel:+"+regions.sections.find(region => region.regionName === provider.region[0]).regionPhone.replaceAll("-", ""));
    } else {
        phoneLink = '';
    }


    /* Method that will fix header after a specific scrollable */
    return (
        <div className={"row"} style={{background: "#4177CB"}}>
        {phoneLink ? (<div className="header-section d-none d-md-none overflow-hidden" onScroll={onScroll}>

                    <a href={phoneLink} className={"text-center"}>
                        <div className={"col-12 d-md-none mt-3 p-0"}>
                            <h4 style={{color: "white"}}>Call to schedule your visit</h4>
                        </div>
                    </a>
                </div>
            ) : "" }
        </div>
    );
}

export default StickyCTA;
/*
    window.onscroll = function() {myFunction()};

// Get the navbar


// Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
  function myFunction() {
        var navbar = document.getElementById("sticky-nav");
// Get the offset position of the navbar
        var sticky = navbar.offsetTop;
        console.log(sticky);
        console.log(window.scrollY);
        if (window.scrollY <= 50) {
            navbar.classList.add("d-none");
            navbar.classList.remove("sticky-top");
        } else {
            navbar.classList.remove("d-none");
            navbar.classList.add("sticky-top");
        }
    }

    return (
        <div className={"container-fluid"} id="sticky-nav">
            <div className={"row p-0"} style={{background: "#4177CB"}}>
                <a href={"tel:+17027248877"} className={"text-center"}>
                    <div className={"col-12 d-md-none mt-3 p-0"}>
                        <h4 style={{color: "white"}}>Call to schedule your visit</h4>
                    </div>
                </a>
            </div>
        </div>

    )
};
*/

