import React from 'react';
import FOOTER_AUX_DATA from './footer-bottom-aux.data'
import './footer-bottom-aux.styles.scss'

const data = FOOTER_AUX_DATA

const FooterBottomAuxComponent = ({imageUrl, linkUrl, bodyCopy, title, id, buttonText, columnQuarter, isBlank}) => (
    <>
        <div className="footer-top">
            <div className="container-fluid">
                <div className="row justify-content-start">
                    <div className="col-xs-4 col-md-4 col-lg-4 text-centered mobile-link-center"><a
                        href="https://www.optumcare.com/nondiscrimination-notice.html" target="_blank" rel="noreferrer"> Language Assistance /
                        Non-Discrimination Notice </a></div>
                    <div className="col-xs-4 col-md-4 col-lg-5 text-centered mobile-link-center"><a
                        href="https://www.optumcare.com/nondiscrimination-notice.html" target="_blank" rel="noreferrer"> Asistencia de Idiomas /
                        Aviso de no Discriminación </a></div>
                    <div className="col-xs-4 col-md-4 col-lg-3 text-centered mobile-link-center"><a
                        href="https://www.optumcare.com/nondiscrimination-notice.html" target="_blank" rel="noreferrer"> 語言協助 / 不歧視通知</a></div>
                </div>
            </div>
        </div>
        {/*<div className="row footer-aux mt-5">
            <div className="col-md-2 col-sm-12 mobile-first px-2 mr-3">
              <h6 className={"pr-5"}>{data.section1.title}</h6>
              <ul className="top-footer-menu mt-3 pr-5">
                  {
                      data.section1.items.map( ({id, title, link}) => (
                          <li className={"my-2"} key={id}><a href={link} target="_blank"><span className={"font-body-3"}>{title}</span></a></li>
                      ))
                  }
              </ul>
            </div>
            <div className="col-md-2 px-2 mr-3 col-sm-12 ">
                <h6 className={"pr-5"}>{data.section2.title}</h6>
                <ul className={"mt-2"}>
                {
                    data.section2.items.map( ({id, title, link}) => (
                        <li className={"my-2"} key={id}><a href={link} target="_blank"><span className={"font-body-3"}>{title}</span></a></li>
                    ))
                }
                </ul>
            </div>
            <div className="col-md-2 px-2 mr-3 col-sm-12 ">
                <h6 className={"pr-5"}>{data.section3.title}</h6>
                <ul className={"mt-2"}>
                    {
                        data.section3.items.map( ({id, title, link}) => (
                            <li className={"my-2"} key={id}><a href={link} target="_blank"><span className={"font-body-3"}>{title}</span></a></li>
                        ))
                    }
                </ul>
            </div>
            <div className="col-md-2 px-2 mr-3 col-sm-12 ">
                <h6 className={"pr-5"}>{data.section4.title}</h6>
                <ul className={"mt-2"}>
                    {
                        data.section4.items.map( ({id, title, link}) => (
                            <li className={"my-2"} key={id}><a href={link} target="_blank"><span className={"font-body-3"}>{title}</span></a></li>
                        ))
                    }
                </ul>
            </div>
            <div className="col-md-2 px-2 mr-3 col-sm-12 ">
                <h6 className={"pr-5"}>{data.section5.title}</h6>
                <ul className={"mt-2"}>
                    {
                        data.section5.items.map( ({id, title, link}) => (
                            <li className={"my-2"} key={id}><a href={link} target="_blank"><span className={"font-body-3"}>{title}</span></a></li>
                        ))
                    }
                </ul>
            </div>
        </div>
        */}
    </>
)

export default FooterBottomAuxComponent;
