import React from 'react';
import SpecialtyLayout from "../../../components/Layouts/SpecialtyPage.component";
import ELBOW_DATA from "./elbow.data";

class ElbowPage extends React.Component {
    state = ELBOW_DATA;
    render() {
        return(
            <SpecialtyLayout data={this.state}/>
        );
    }
}

export default ElbowPage;
