import image1 from "../../../img/home-5.png";
import image3 from "../../../img/home-6.png";
import headerImage from "../../../img/figma/general_ortho_banner.png";
import '../specialties.styles.scss';

const GENERAL_ORTHOPEDICS_DATA = {
    pageTitle: 'General orthopedics',
    headerComponent: {
        imageUrl: headerImage,
        title: "General orthopedics",
        innerCopy: "Care and treatment to relieve pain and improve your quality of life",
        shouldHideHomeButton: true
    },
    textSection1: {
        bodyTextLine1: "Together the bones, joints, tendons, ligaments, nerves and muscles make up the musculoskeletal system. Our orthopedic physicians focus on the diagnosis, prevention, correction and treatment of conditions and disorders for the musculoskeletal system.",
        bodyTextLine2: "General orthopedics is the basis of all specialized and advanced orthopedic study. Our highly trained doctors and surgeons stay up to date on the latest research thanks in part to our connection to the University of Nevada, Las Vegas. Our extensive and evidence-based practice focuses on the full spectrum of orthopedic care. Our doctors have the tools and training to deliver care that is personalized to each patient no matter what concerns they have.",
    },
    interiorSections: [
        {
            title: "Conditions",
            bodyCopy: "General orthopedic conditions we treat include: ",
            htmlCopy: "<ul><li>Arthritis of the upper and lower extremities </li><li>Bursitis</li><li>Cartilage injuries of the upper and lower extremities</li><li>Fractures of the upper and lower extremities </li><li>Muscle and ligament sprains, strains and tears of the upper and lower extremities</li><li>Osteoarthritis of the upper and lower extremities</li><li>Traumatic arthritis</li></ul>",
            imageUrl: image1,
            id: 1
        },
        {
            title: "Treatment",
            htmlCopy: "<ul><li>Arthroscopic surgery of the upper and lower extremities </li><li>Cartilage surgery of the upper and lower extremities</li><li>Fracture treatment of the upper and lower extremities</li><li>Imaging</li><li>Injections</li><li>Joint replacement of the upper and lower extremities</li><li>Nerve decompression surgery of the upper and lower extremities</li><li>Tendon repair of the upper and lower extremities</li><li>Trigger point injections</li></ul>",
            imageUrl: image3,
            customClassDiv: 'reverse-row',
            id: 2
        }
    ],
    textSection2: {
        bodyTextLine1: "At Optum Care Orthopaedics and Spine, our specialists have a deep understanding of orthopedics. We always recommend conservative or minimally invasive treatments first. When an injury or condition requires more advanced treatment, we strive to offer patients resources and options so they can make informed decisions based on their unique needs and goals. Our surgeons use innovative techniques and the latest technology with the goal of full recovery.",
    },
    providersCta: {
        htmlCopy: `<h3 class='dash'>Schedule an appointment</h3>
                    <p>Ready to talk with a top-rated orthopedic specialist in Las Vegas?</p>
                    <p class="small-text">Schedule an appointment with Optum Care Orthopaedics and Spine today.</p>
                `,
        //All providers listed below, per client request        
        providersList: [
            {
                id: 1,
                providerName: "Anthony Bratton, MD",
                link: "/providers/anthony-bratton"
            },
            {
                id: 2,
                providerName: "Benjamin J. Hansen, MD",
                link: "/providers/benjamin-hansen"
            },
            {
                id:3,
                providerName: "Eugene Libby, DO, F.A.C.O.S",
                link: "/providers/eugene-libby"
            },
            {
                id: 4,
                providerName:"Karen Nelson, DO",
                link: "/providers/karen-nelson"
            },
            {
                id:5,
                providerName: "Shannon Boffeli, APRN",
                link: "/providers/shannon-boffeli"
            },
            {
                id:6,
                providerName: "Brittany Fox Hough, PA-C",
                link: "/providers/brittany-hough"
            },
            {
                id: 7,
                providerName:"Lou Tayong, APRN",
                link: "/providers/lou-tayong"
            },

            
        ],
        button: {
            id: 1,
            link: 'tel:+17027248877',
            text: 'Call: 1-702-724-8877',
            class: 'btn-alt'
        }
    }
}

export default GENERAL_ORTHOPEDICS_DATA;
