import React from 'react';
import SpecialtyLayout from "../../../components/Layouts/SpecialtyPage.component";
import HIPS_AND_PELVIS_DATA from "./hips-and-pelvis.data";

class HipsAndPelvisPage extends React.Component {
    state = HIPS_AND_PELVIS_DATA;
    render() {
        return(
            <SpecialtyLayout data={this.state}/>
        );
    }
}

export default HipsAndPelvisPage;
