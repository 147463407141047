import React from 'react';
import HeaderComponent from "../../../components/header/header.component";

import headerImage from "../../../img/figma/foot_ankle_banner.png";
import ctaImage from "../../../img/cta-image.png";

import HOMEPAGE_DATA from "./foot-and-ankle.data";
import CTAProviderComponent from "../../../components/call-to-action/cta-provider/cta-provider.component";
import TextSectionButtonComponent from "../../../components/text-section-button/text-section-button.component";
import Interior5050Component from "../../../components/interior-50-50/interior-50-50.component";
import DividerArrowDownComponent from "../../../components/divider-arrow-down/divider-arrow-down.component";
import {hideMask} from "../../../components/navigation/navigation.component";

class FootAnklePage extends React.Component {
    state = HOMEPAGE_DATA;
    render() {
        return(
            <main className='page-body' onClick={hideMask}>
                <HeaderComponent imageUrl={headerImage}
                                 title="Foot and ankle"
                                 innerCopy="Care and treatment to relieve pain and improve your quality of life"
                                 shouldHideHomeButton={true}
                />
                <TextSectionButtonComponent
                    bodyTextLine1="The foot is a complex structure held together by many ligaments. The feet are used daily to walk and enjoy our favorite activities such as walking, traveling and playing sports. Because of the constant use and because its structure is full of many tiny bones and tissues, the foot can become more prone to aches and pains."
                    bodyTextLine2="Foot pain can vary from an injury that makes pain intense or pain that is chronic such as arthritis. Foot complications can also stem from other chronic health conditions such as diabetes. Depending on the condition, the level of pain and type of pain can vary greatly."
                    customDivClass='cards-no-border col-12 col-lg-9'
                    customSectionClass='container-fluid'
                />
                {
                    this.state.interiorSections.map(({id, ...otherSectionProps}) => (
                        <Interior5050Component key={id} {...otherSectionProps}/>
                    ))
                }
                <TextSectionButtonComponent
                    bodyTextLine1="At Optum Care Orthopaedics and Spine, our specialists understand that foot pain can prevent patients from doing daily activities. Our specialists offer conservative or minimally invasive treatments first. When an injury or condition requires more advanced treatment, we share educational resources to help patients make informed decisions based on their unique goals. Our surgeons use advanced technology with the goal of full recovery to get you back on your feet as quickly as possible."
                    customDivClass='cards-no-border col-12 col-lg-9 mt-5'
                    customSectionClass='container-fluid'
                />
                <DividerArrowDownComponent title="Foot and ankle specialist"/>
                <div className="cta-container">
                        <CTAProviderComponent
                            imageUrl={ctaImage}
                            htmlCopy={this.state.providersCta.htmlCopy}
                            providersList={this.state.providersCta.providersList}
                            button={this.state.providersCta.button}
                        />
                </div>
            </main>
        );
    }
}

export default FootAnklePage;
