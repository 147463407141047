import React from 'react';
import { withRouter } from 'react-router-dom';
import './header.styles.scss'

const HeaderComponent = ({ title, id, imageUrl, innerCopy, isTitleDash, history, linkUrl, match, topText, shouldHideHomeButton }) => (
    <header className="container-fluid">
        <div className="row justify-content-center">
            <div className="col-lg-5 col-12 left-column bg-grey mt-5 mt-md-0 bg-sm-none order-last order-lg-first">
                <div className="row h-100">
                    {!shouldHideHomeButton &&
                    < a href="/" className="header-breadcrumb">Home</a>
                    }
                    <div className="col-lg-8 col-12 left-column-content mt-xs-5 mt-md-0">
                        {topText && <h4 className="header-top-name">{topText}</h4>}
                        <h1 className={`${isTitleDash ? "header-title dash align--center": ""}`}>
                            {title}
                        </h1>
                        <p className="inner-copy">{innerCopy}</p>
                        <p>Optum accepts most major PPO HMO and POS health plans, Original Medicare and Medicare Advantage.</p>
                    </div>
                </div>
            </div>
            <div className=" col-lg-7 col-12 no-padding order-first order-lg-last">
                 <img src={`${imageUrl}`} className="img-fluid" width="100%" height="auto" alt=""/>
            </div>
     </div>
    </header>


)

export default withRouter(HeaderComponent);
