import React from 'react';
import {Card} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import ButtonComponent from "../../buttons/button.component";

const CardComponent = ({title, text, image, linkText, linkUrl, noBackground, buttonText, inRowSize, isFooter}) => {
    return(
        <div className={inRowSize ? (`col-md-${inRowSize} col-12`) : 'col-12 col-md-4'}>
            <div className={"col-12 justify-content-center"}>
                <Card style={{borderRadius: "10px", maxWidth:"408px", minHeight:"180px"}} className={noBackground ? "bg-transparent border-0" : ""}>
                    { image ? ( <Card.Img style={{borderTopLeftRadius: "10px", borderTopRightRadius: "10px"}} variant={"top"} src={image}></Card.Img>) : true }
                    <Card.Body>
                        <Card.Title>
                            <h6 className={"font-navy-blue font-body-2"}><b>{title}</b></h6>
                        </Card.Title>
                        <Card.Text>
                            <span className={isFooter ? ("font-body-3") : ("font-body-1")}>{text}</span>
                            {buttonText ? (<ButtonComponent title={buttonText} buttonLight={true}/>) : false }
                        </Card.Text>

                        { linkText ? (<Card.Text style={{background:"none"}}><h6 className={"font-body-2 font-blue mt-5"}><a href={linkUrl}><b>{linkText}</b><FontAwesomeIcon className={"ml-3"} icon={faChevronRight}/></a></h6> </Card.Text>) : true  }
                    </Card.Body>
                </Card>
            </div>

        </div>
    )
}

export default CardComponent