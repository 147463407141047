import React from 'react';
import SpecialtyLayout from "../../../components/Layouts/SpecialtyPage.component";
import TRAUMA_AND_FRACTURE_CARE_DATA from "./trauma-and-fracture-care.data";

class TraumaFracturePage extends React.Component {
    state = TRAUMA_AND_FRACTURE_CARE_DATA;
    render() {
        return(
            <SpecialtyLayout data={this.state}/>
        );
    }
}

export default TraumaFracturePage;
