import React from 'react';
import SpecialtyLayout from "../../../components/Layouts/SpecialtyPage.component";
import GENERAL_ORTHOPEDICS_DATA from "./general-orthopedics.data";

class GeneralOrthopedicsPage extends React.Component {
    state = GENERAL_ORTHOPEDICS_DATA;
    render() {
        return(
            <SpecialtyLayout data={this.state}/>
        );
    }
}

export default GeneralOrthopedicsPage;
