import React from 'react';
import HeaderComponent from "../../../components/header/header.component";
import heroImage from '../../../img/np-hero.png';
import ctaImage from '../../../img/new-patient-2.png'
import ButtonsDirectoryComponent from "../../../components/buttons-directory/buttons-directory.component";
import CTAComponent from "../../../components/call-to-action/cta-footer.component";

import NEW_PATIENT_INFO_DATA from './new-patient-information.data'
import ImageWithParagraphsComponent from "../../../components/image-with-paragraphs/image-with-paragraphs.component";
import {Helmet} from "react-helmet";

const pageTitle = "New patient information";

class NewPatientInformationPage extends React.Component {
    state = NEW_PATIENT_INFO_DATA;

    render() {
        return(
            <main>
                <Helmet>
                    <title>{pageTitle} | USONV</title>
                </Helmet>
            <HeaderComponent imageUrl={heroImage}
                             title="New Patient Information"
            />
            <div className='row justify-content-around'>
                <div className="col-md-8 col-sm-12">
                    <p className="text-centered">
                        <p className="text-centered">   Thank you for choosing Urology Specialists of Nevada.</p>
                        Our offices are open Monday-Friday, 8 a.m.-5 p.m. You have the choice of being served at any of our four locations. We look forward to see you!                    </p>
                </div>
            </div>
            <ButtonsDirectoryComponent buttons={this.state.buttons}/>
            {
                this.state.imageWithParagraphs.map( ({ id, ...otherSectionProps }) => (
                    <ImageWithParagraphsComponent key={id} {...otherSectionProps} />
                ))
            }
                <CTAComponent
                    imageUrl={ctaImage}
                    imageHeight='350'
                    />
            </main>
        )
    }
}

export default NewPatientInformationPage;
