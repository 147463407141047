import image1 from "../../../img/figma/sports_medicine-1.png";
import image2 from "../../../img/figma/sports_medicine-2.png";
import '../specialties.styles.scss';
import headerImage from "../../../img/interior-header.png";

const SPORTS_MEDICINE_DATA = {
    pageTitle: 'Sports medicine',
    headerComponent: {
        imageUrl: headerImage,
        title: "Sports medicine",
        innerCopy: "Care and treatment to relieve pain and improve your quality of life",
        shouldHideHomeButton: true
    },
    textSection1: {
        bodyTextLine1: "Sports and movement-based practices are a healthy part of any lifestyle. Unfortunately, there’s always the risk of injury or strain on the joints. The practice of sports medicine aims to rehabilitate injuries, improve athletic performance and prevent future injuries through treatment and patient education. Sports-related injuries can be frustrating because athletes are accustomed to an active lifestyle.",
        bodyTextLine2: "Different athletes are prone to different types of injuries. Runners, golfers and tennis players are more likely to develop repetitive stress injuries. Contact sports such as hockey and football put players at a higher risk of a tear or fracture. We have experience treating all types of athletes from weekend warriors to high school, collegiate and professional level players. Treatments for sports-related conditions may involve rest, orthotics, physical therapy or surgery.",
    },
    interiorSections: [
        {
            title: "Conditions",
            bodyCopy: "Sports medicine conditions we treat include: ",
            htmlCopy: "<ul><li>Ankle sprains or strains</li><li>Concussion</li><li>Finger and hand injuries</li><li>Fractures</li><li>Joint dislocation</li><li>Lateral epicondylitis (tennis elbow)</li><li>Ligament tears (ACL, MCL, PCL)</li><li>Medial epicondylitis (golfer's elbow)</li><li>Meniscus tears</li><li>Tendon ruptures</li><li>Throwing injuries, such as a rotator cuff tear</li></ul>",
            imageUrl: image1,
            id: 1
        },
        {
            title: "Treatment",
            htmlCopy: "<ul><li>Arthroscopy</li><li>Fracture care</li><li>Injections</li><li>Joint replacement or reconstruction </li><li>Ligament repair or reconstruction</li><li>Physical therapy</li><li>Ultrasound-assisted procedures</li></ul>",
            imageUrl: image2,
            customClassDiv: 'reverse-row',
            id: 2
        }
    ],
    textSection2: {
        bodyTextLine1: "As with sports teams, Optum Care Orthopaedics and Spine specialists work as a team with doctors, surgeons and physical therapists to deliver the best care for each patient’s situation. We strive to get athletes back to their favorite activities by recommending conservative or minimally invasive treatments first. If an injury or condition requires more advanced treatment, we offer patients resources and options so they can make informed decisions based on their unique needs and goals. Our surgeons use innovative techniques and advanced technology with the goal of full recovery.",
    },
    providersCta: {
        htmlCopy: `<h3>Schedule an appointment</h3>
                    <p>Ready to talk with a top-rated orthopedic specialist in Las Vegas?</p>
                    <p class="small-text">Schedule an appointment with Optum Care Orthopaedics and Spine today.</p>
                  `,
        providersList: [
            {
                id:1,
                providerName: "Anthony Bratton, MD",
                link: "/providers/anthony-bratton"
            },
        ],
        button: {
            id: 1,
            link: 'tel:+17027248877',
            text: 'Call: 1-702-724-8877'
        }
    }
}

export default SPORTS_MEDICINE_DATA;
