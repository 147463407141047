import React from 'react';
import locationsData from "../../../pages/locations/locations.data";

var locations = locationsData.sections;

const ProviderThumbnail = ({ title, id, npi, providerLocations, location, languages, imageUrl, specialty, linkUrl, providerName, providerTitle, history, match }) => (
    <div className="col-lg-3 col-md-4 col-6 mb-1 mb-lg-4 h-100" onClick={ () => history.push(`${match.url}${linkUrl}`) }>
        <a href={`/providers/${linkUrl}`}>
            <div className="card h-100">
                <img src={imageUrl}
                     className="card-img-top"
                     alt={`Provider ${providerName}'s profile image`}/>
                <div className="card-body my-2 py-2" style={{minHeight: "200px"}}>
                    <h6 className="font-body-1 font-navy-blue"><b>{providerName}, {providerTitle}</b></h6>
                    {specialty[0] !== "" && languages[0] !== "" ? <h6 className={"font-body-3"}>{specialty.map(spec => spec)}{" | " + languages.map(lang => " "+lang)}</h6> : null}
                    {specialty[0] !== "" && languages[0] === "" ? <h6 className={"font-body-3"}>{specialty.map(spec => spec)}</h6> : null}

                    <h6 className={"font-body-3"}><b>Location: </b>{ locations.find(loc => loc.id === providerLocations[0]).title }</h6>
                    <h6 className={"font-body-3"}>
                        { locations.find(loc => loc.id === providerLocations[0]).address1 } { locations.find(loc => loc.id === providerLocations[0]).address2 }
                    </h6>
                    <a className={"font-blue font-body-3"} href={`/providers/${linkUrl}`}>
                        <b>
                            Meet Dr. {providerName.split(" ").pop()}
                        </b>
                    </a>
                </div>
            </div>
        </a>
    </div>
)

export default ProviderThumbnail;
