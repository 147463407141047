import heroImage from '../../img/home/home-hero-header.png';

const REGIONS_DATA = {
    sections: [
        {
            id: 1,
            linkUrl: 'east-san-gabriel-valley',
            regionName: "East San Gabriel Valley",
            regionPhone: "1-844-947-1734",
        },
        {
            id: 2,
            linkUrl: 'la-downtown',
            regionName: "LA/Downtown",
            regionPhone: "1-844-326-7097",
        },
        {
            id: 3,
            linkUrl: 'san-fernando-valley',
            regionName: "San Fernando Valley",
            regionPhone: "1-855-464-6505",
        },
        {
            id: 4,
            linkUrl: 'long-beach',
            regionName: "Long Beach",
            regionPhone: "1-866-488-5203",
        },
        {
            id: 5,
            linkUrl: 'south-bay',
            regionName: "South Bay",
            regionPhone: "1-844-958-4189",
        },
        {
            id: 6,
            linkUrl: 'south-oc',
            regionName: "South OC",
            regionPhone: "1-844-939-3730",
        },
        {
            id: 7,
            linkUrl: 'north-oc',
            regionName: "North OC",
            regionPhone: "1-866-324-0903",
        },
        {
            id: 8,
            linkUrl: 'vista',
            regionName: "Vista",
            regionPhone: "1-833-934-3658",
        },
        {
            id: 9,
            linkUrl: 'west-san-gabriel-valley',
            regionName: "West San Gabriel Valley Providers",
            regionPhone: "1-844-947-1734",
        },
    ],
    buttons: [
        {
            id: 1,
            buttonCopy: "San Gabriel Valley",
            buttonLink: "#san-gabriel-valley",
            icon: "chevron-right"
        },
        {
            id: 2,
            buttonCopy: "LA/Downtown",
            buttonLink: "#la-downtown"
        },
        {
            id: 3,
            buttonCopy: "San Fernando Valley",
            buttonLink: "#san-fernando-valley"
        },
        {
            id: 4,
            buttonCopy: "South Bay",
            buttonLink: "#south-bay"
        },
        {
            id: 5,
            buttonCopy: "Long Beach",
            buttonLink: "#long-beach"
        },
        {
            id: 6,
            buttonCopy: "South OC",
            buttonLink: "#south-oc"
        },
        {
            id: 7,
            buttonCopy: "North OC",
            buttonLink: "#north-oc"
        },

    ],
    hero: {
        id: 56,
        title: 'We\'re welcoming new patients',
        text:'Discover why Optum was named the 2021 Best Medical Group of the Southland by readers of the L.A. Times *. Our team will take the time to get to know you — listening to you, answering your questions and helping you get care that meets your needs.',
        text2: 'Optum accepts most major PPO, HMO and POS health plans, Original Medicare and Medicare Advantage.',
        backgroundImg: heroImage,
        search: heroImage,
    }
}

export default REGIONS_DATA;

