import React from 'react';
import {connect} from "react-redux";
import {Route} from "react-router";
import {selectIndividualProvider} from "../../redux/provider/provider.selectors";
import './providers-template.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faBookReader,
    faBriefcaseMedical, faCertificate, faGlobeAmericas,
    faGraduationCap,
    faMapMarker,
    faUserMd,
    faMapMarkerAlt,
    faPhoneAlt, faStethoscope, faClock, faMale, faFemale, faMars, faVenus

} from "@fortawesome/free-solid-svg-icons";
import {Helmet} from "react-helmet";
import {Row} from "react-bootstrap";
import LocationsDetailCardComponent from "../../components/locations-detail-card/locations-detail-card.component";
import NavigationComponent, {hideMask} from "../../components/navigation/navigation.component";
import locationsData from '../locations/locations.data';
import regionsData from '../regional-pages/regions.data';
import ButtonComponent from "../../components/buttons/button.component";
import StickyCTA from "../../components/sticky-header/sticky-header.component";
import ModalComponent from "../../components/modal/modal.component";
import AuxiliaryComponent from "../../components/auxiliary/auxiliary-bar.component";
import FooterComponent from "../../components/footer/footer.component";


const ProviderTemplatePage = ({ provider, match }) => {
    const regions = regionsData;
    return (
        <>
            <Helmet>
                <title>{provider.providerName} | Optum Care</title>
            </Helmet>
            <StickyCTA/>
            <NavigationComponent/>
            <main className='page-body' onClick={hideMask}>

                <section className="container-fluid block-container-3 breathing-room provider-single pb-0">
                    <div className="row justify-content-center mb-5">
                        <a href="/providers" className="provider-single-breadcrumb d-none">Providers</a>
                        <div className="col-lg-4 col-md-4 col-sm-12 right-section-info">
                            <Row>
                                <img src={provider.providerInteriorImage}
                                     alt={"Image of Provider - " + provider.providerName}/>
                            </Row>
                            <Row className={"justify-content-center mt-3 d-none d-md-flex"}>
                                <ButtonComponent hasSecondLine={true} buttonCopy={`Call to schedule your visit`} buttonCopy2={regions.sections.find(region => region.regionName === provider.region[0]).regionPhone} buttonLink={"tel:+"+regions.sections.find(region => region.regionName === provider.region[0]).regionPhone.replaceAll("-", "")}/>
                                <div className={"row w-100 mt-2"}/>
                                <ModalComponent />
                            </Row>
                            <Row className={"d-none d-md-flex"}>
                                <h3 className="w-100 mt-4">Locations</h3>
                                {
                                    provider.providerLocations.map(function (item,index) {
                                        return (
                                            <>
                                                <div className={'locations-detail-card-container shadow-none mb-4 py-3'}>


                                                    <p><b>
                                                        { locationsData.sections[item - 1].id &&
                                                        locationsData.sections[item - 1].title
                                                        }</b>
                                                    </p>
                                                    <p>
                                                        <FontAwesomeIcon className="mr-1" icon={faMapMarkerAlt}/>

                                                        {/* <a href={locationsData.sections[item - 1].linkUrl} className="" target="_blank"> */}
                                                        {locationsData.sections[item - 1].address1}<br/>
                                                        <span className="ml-3"> {locationsData.sections[item - 1].address2} </span>
                                                        {/* <span className=""> {locationsData.sections[item - 1].address3} </span> */}
                                                        {/*  </a>*/}
                                                    </p>
                                                    <p>
                                                        <FontAwesomeIcon className="mr-1" icon={faPhoneAlt}/>
                                                        <a href={`tel:+${regions.sections.find(region => region.regionName === locationsData.sections[item - 1].region).regionPhone.replaceAll("-", "")}`} className="underline">
                                                            {regions.sections.find(region => region.regionName === locationsData.sections[item - 1].region).regionPhone}
                                                        </a>
                                                    </p>
                                                    <p>
                                                        <FontAwesomeIcon className="mr-1" icon={faClock}/>
                                                        {locationsData.sections[item - 1].hours}

                                                    </p>


                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </Row>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12 no-padding info">
                            <h1 className={"provider-header mt-0 align--center mb-5 ml-3"}>We're welcoming new patients</h1>
                            <h1 className={"provider-header mt-5 mt-md-0 font-gray"}>{provider.providerName}, {provider.providerTitle}</h1>
                            <h6 className={"font-body-2 mt-4"}><b>Specialty:</b> {provider.specialty.length > 1 ? (provider.specialty.map(spec => spec+" ")) : (provider.specialty.map(spec => spec)) }</h6>
                            <h6 className={"font-body-2 mt-4"}>
                                <b>About: </b> {provider.providerBio}
                            </h6>
                            <h6 className={"font-body-2 mt-4"}>
                                <b>Languages spoken: </b> {
                                provider.languages.map((language, index) => {
                                    if (index === 0) {
                                        return (
                                            <span>{language}</span>

                                        )
                                    } else {
                                        return (
                                            <span>, {language}</span>
                                        )
                                    }
                                })
                            }
                            </h6>
                            <h4 className={"font-body-2 my-4"}>
                                <b>Gender: </b> {provider.sex}
                            </h4>

                            <span className={"font-body-2 font-navy-blue"}><b>{provider.telehealth === 'Yes' ? "Virtual visits available": " "}</b></span>

                            <div className="row w-100">
                                <div className="col-lg-12 col-xl-12 col-md-12 mt-4 no-padding">
                                    <h3 className="w-100 mt-2 mb-4 font-gray">Qualifications</h3>
                                    {
                                        provider.qualifications.map(function (item, i) {
                                            return (
                                                <>
                                                    <div className="row w-100">
                                                        <div className="col-1 no-padding">
                                                            {(() => {
                                                                switch (item.type) {

                                                                    case 'Medical School':
                                                                        return (
                                                                            <FontAwesomeIcon className="mr-1 mt-2 big-icon"
                                                                                             icon={faGraduationCap}/>
                                                                        )
                                                                    case 'Internship':
                                                                        return (
                                                                            <FontAwesomeIcon className="mr-1 mt-2 big-icon"
                                                                                             icon={faBriefcaseMedical}/>
                                                                        )
                                                                    case 'Residency':
                                                                        return (
                                                                            <FontAwesomeIcon className="mr-1 mt-2 big-icon"
                                                                                             icon={faUserMd}/>
                                                                        )
                                                                    case 'Fellowship':
                                                                        return (
                                                                            <FontAwesomeIcon className="d-none mr-1 mt-2 big-icon"
                                                                                             icon={faBookReader}/>
                                                                        )
                                                                    case 'Board certifications':
                                                                        return (
                                                                            <FontAwesomeIcon className="mr-1 mt-2 big-icon"
                                                                                             icon={faCertificate}/>
                                                                        )
                                                                    default:

                                                                        return (

                                                                            <div></div>

                                                                        )

                                                                }
                                                            })()}
                                                        </div>
                                                        <div key={i} className="media col-11 pr-3 pl-0">
                                                            <p className={'font-body-2'}>
                                                                <b>{item.type !== 'Fellowship' ? item.type : ""}  </b>
                                                                <br/>
                                                                {item.type !== 'Fellowship' ? item.name : ""}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                </div>
                                <div className="col-lg-12 col-xl-6 col-md-12 no-padding">
                                    {provider.professionalAssociations.length > 0 &&
                                        <div>
                                            <h1>Professional Associations</h1>
                                            <ul>
                                                {
                                                    provider.professionalAssociations.map((association) => (
                                                        <li>{association}</li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className={"row w-100"}/>
                            <span className={"mb-5"}>
                                <ButtonComponent hasSecondLine={false} buttonCopy={`Browse other doctors`} buttonLink={'javascript:history.back()'}/>

                                </span>

                            <p className={"font-body-2 mt-5"}>
                                This program should not be used for emergency or urgent care needs. In an emergency, call 911 or go to the nearest emergency room. The information provided through this service is for your information only. It is provided as part of your health plan. Program nurses and other representatives cannot diagnose problems or suggest treatment. This program is not a substitute for your doctor’s care. Your health information is kept confidential in accordance with the law. This is not an insurance program and may be discontinued at any time.
                            </p>


                            {provider.providerPhilosophy.length > 0 &&
                                <div className={"d-none"}>
                                    <hr/>
                                    <h4 className='providerPhilosophy-heading'>Philosophy</h4>
                                    <div>
                                        {
                                            provider.providerPhilosophy.map(function (item, i) {
                                                return (
                                                    <p key={i}
                                                       className="providerPhilosophy-section">{provider.providerPhilosophy[i]}</p>
                                                )
                                            })
                                        }
                                    </div>
                                </div>}
                            {provider.organizations.length > 0 &&
                                <div className="d-none mb-3 organizations-section">
                                    <hr/>
                                    <h3 className='organizations-heading'>Professional Organizations</h3>
                                    <ul>
                                        {
                                            provider.organizations.map(function (item, i) {
                                                return (
                                                    <li key={i}>{provider.organizations[i]}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>}
                            <div className="d-none row w-100 justify-content-around border-bars pt-2">
                                <div className="col-lg-6 mt-2 no-padding">
                                    <h1 className="">Languages</h1>
                                    <p><FontAwesomeIcon className="mr-3 mt-2 big-icon" icon={faGlobeAmericas}/>I
                                        speak {
                                            provider.languages.map((language, index) => {
                                                if (index === 0) {
                                                    return (
                                                        <b>{language}</b>
                                                    )
                                                } else {
                                                    return (
                                                        <span>, <b>{language}</b></span>
                                                    )
                                                }
                                            })
                                        }
                                    </p>
                                </div>
                                <div className="col-lg-6 mt-2 pl-0 pl-md-5">
                                    <h3 className="">Gender</h3>
                                    <p>
                                        { provider.sex === 'Male' ? <FontAwesomeIcon className="mr-3 mt-2 big-icon" icon={faMars} style={{fontSize: "18px"}}/> : <FontAwesomeIcon className="mr-3 mt-2 big-icon" icon={faVenus}/> }
                                        {provider.sex}
                                    </p>
                                </div>
                            </div>
                            {provider.publicationsAndResearch.length > 0 &&
                                <div className="row mt-5 border-top w-100">
                                    <div className="col-lg-12 mt-5 no-padding">
                                        <h3>Publications and research</h3>
                                        <ul>
                                            {
                                                provider.publicationsAndResearch.map((item, index) => {
                                                    return (
                                                        <li key={index}><b>{item}</b></li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className={"col-lg-2 d-none d-md-flex"} style={{width:"200px"}} />
                    </div>

                    <Row className={"d-flex d-md-none"}>
                        <h3 className="w-100 mt-md-4">Locations</h3>
                        {
                            provider.providerLocations.map(function (item,index) {
                                return (
                                    <>
                                        <div className={'locations-detail-card-container shadow-none mb-4 py-3'}>
                                            <p>
                                                <b>
                                                {
                                                    locationsData.sections[item - 1].id &&
                                                    locationsData.sections[item - 1].title
                                                }
                                                </b>
                                            </p>
                                            <p>
                                                <FontAwesomeIcon className="mr-1 " icon={faMapMarkerAlt}/>

                                                <a href={locationsData.sections[item - 1].linkUrl} className="" target="_blank">
                                                    {locationsData.sections[item - 1].address1}<br/>
                                                    <span className="ml-3"> {locationsData.sections[item - 1].address2} </span>
                                                    {/* <span className=""> {locationsData.sections[item - 1].address3} </span> */}
                                                </a>
                                            </p>
                                            <p>
                                                <FontAwesomeIcon className="mr-1" icon={faPhoneAlt}/>
                                                <a href={`tel:${locationsData.sections[item - 1].phone}`} className="underline">
                                                    {locationsData.sections[item - 1].phone}
                                                </a>
                                            </p>
                                            <p>
                                                <FontAwesomeIcon className="mr-1" icon={faClock}/>
                                                {locationsData.sections[item - 1].hours}

                                            </p>


                                        </div>
                                    </>
                                )
                            })
                        }
                    </Row>
                    <div className={"row justify-content-center d-none"}>
                        <div className={"col-12 text-center d-none d-md-block"}>
                            <p> To learn more about all of our California locations and doctors <a className={"text-center"} href={'https://healthcarepartners.com/search/doctorsearch.aspx'} target={'_blank'}>click here.</a></p>
                        </div>
                    </div>

                    <Route path={`${match.path}/:providerId`}/>
                </section>
            </main>
        </>
    )
};

const mapToStateProps = (state, ownProps) => ({
    provider: selectIndividualProvider(ownProps.match.params.providerId)(state)
})

export default connect(mapToStateProps)(ProviderTemplatePage);
