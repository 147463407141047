import image1 from "../../../img/figma/foot_ankle-1.png";
import image2 from "../../../img/figma/foot_ankle-2.png";
import headerImage from "../../../img/figma/foot_ankle_banner.png";
import '../specialties.styles.scss';

const FOOT_AND_ANKLE_DATA = {
    pageTitle: 'Foot and ankle',
    headerComponent: {
        imageUrl: headerImage,
        title: "Foot and ankle",
        innerCopy: "Care and treatment to relieve pain and improve your quality of life",
        shouldHideHomeButton: true
    },
    textSection1: {
        bodyTextLine1: "The foot is a complex structure held together by many ligaments. The feet are used daily to walk and enjoy our favorite activities such as walking, traveling and playing sports. Because of the constant use and because its structure is full of many tiny bones and tissues, the foot can become more prone to aches and pains.",
        bodyTextLine2: "Foot pain can vary from an injury that makes pain intense or pain that is chronic such as arthritis. Foot complications can also stem from other chronic health conditions such as diabetes. Depending on the condition, the level of pain and type of pain can vary greatly."
    },
    interiorSections: [
        {
            title: "Conditions",
            bodyCopy: "Foot and ankle conditions we treat include: ",
            htmlCopy: "<ul><li>Arthritis</li><li>Bunions</li><li>Bursitis</li><li>Damaged tendons</li><li>Fractured foot or broken toe</li><li>Hammertoe</li><li>Plantar fasciitis</li><li>Sprains or strains</li></ul>",
            imageUrl: image1,
            id: 1
        },
        {
            title: "Treatment",
            htmlCopy: "<ul><li>Dislocation treatment</li><li>Fracture care and treatment</li><li>Physical therapy</li><li>Synovial biopsy</li><li>Viscosupplementation treatment</li></ul>",
            imageUrl: image2,
            customClassDiv: 'reverse-row',
            id: 2
        }
    ],
    textSection2: {
        bodyTextLine1: "At Optum Care Orthopaedics and Spine, our specialists understand that foot pain can prevent patients from doing daily activities. Our specialists offer conservative or minimally invasive treatments first. When an injury or condition requires more advanced treatment, we share educational resources to help patients make informed decisions based on their unique goals. Our surgeons use advanced technology with the goal of full recovery to get you back on your feet as quickly as possible.",
    },
    providersCta: {
        htmlCopy: "<h3 class='dash'>Schedule an appointment</h3><p>Ready to talk with a top-rated orthopedic specialist in Las Vegas?</p><p class='small-text'>Schedule an appointment with Optum Care Orthopaedics and Spine today.</p>",
        providersList: [
            {
                id:1,
                providerName: "Anthony Bratton, MD",
                link: "/providers/anthony-bratton"
            },
            {
                id:2,
                providerName: "Eugene Libby, DO, F.A.C.O.S",
                link: "/providers/eugene-libby"
            },
        ],
        button: {
            id: 1,
            link: 'tel:17027248877',
            text: 'Call: 1-702-724-8877'
        }
    }
}

export default FOOT_AND_ANKLE_DATA;
