import image1 from "../../../img/figma/elbow-1.png";
import image2 from "../../../img/figma/elbow-2.png";
import headerImage from "../../../img/figma/elbow_banner.png";
import '../specialties.styles.scss';

const ELBOW_DATA = {
    pageTitle: 'Elbow',
    headerComponent: {
        imageUrl: headerImage,
        title: "Elbow",
        innerCopy: "Care and treatment to relieve pain and improve your quality of life",
        shouldHideHomeButton: true
    },
    textSection1: {
        bodyTextLine1: "The elbow is a hinge joint that connects the upper and lower arm. The joint allows extension and flexion as well as twisting. The elbow is prone to sports-related injury as certain sports, such as tennis and golf, rely so heavily on it. Repetitive sport motions can do damage to the elbow over time. Fractures from falls and other accidents are also common as the body often uses the elbows to break a fall or protect itself from impact.",
        bodyTextLine2: "At Optum Care Orthopaedics and Spine, we perform a high volume of complex elbow revision surgeries. Our average is double the average of a standard practice, meaning that patient’s trust us to deliver the best results possible. After years of use and wear and tear, older individuals can often develop elbow pain due to arthritis. Depending on the condition, the level of pain and type of pain can vary greatly."
    },
    interiorSections: [
        {
            title: "Conditions",
            bodyCopy: "Elbow conditions we treat include: ",
            htmlCopy: "<ul><li>A tear to one of the biceps</li><li>Arthritis or bursitis</li><li>Cubital tunnel syndrome</li><li>Elbow fractures</li><li>Golfer's elbow</li><li>Hyperextended elbow</li><li>Injuries to the tendons</li><li>Sprained elbow</li><li>Tennis elbow</li><li>The bone seems out of place or dislocated</li><li>Ulnar collateral ligament (UCL) damage</li></ul>",
            imageUrl: image1,
            id: 1
        },
        {
            title: "Treatment",
            htmlCopy: "<ul><li>Arthroscopic surgery of the elbow</li><li>Elbow replacement</li><li>Fracture care</li><li>Injections</li><li>Ligament reconstruction</li><li>Reconstruction of the elbow</li><li>Tendon repair</li></ul>",
            imageUrl: image2,
            customClassDiv: 'reverse-row',
            id: 2
        }
    ],
    textSection2: {
        bodyTextLine1: "At Optum Care Orthopaedics and Spine, our specialists understand how frustrating elbow pain can be. Our process is to recommend conservative or minimally invasive treatments first. If more advanced treatment is required, we offer patients all the tools so they can make informed decisions based on their unique needs and goals. Our world-class surgeons use the latest technology with the goal of full recovery."
    },
    providersCta: {
        htmlCopy: "<h3>Schedule an appointment</h3><p>Ready to talk with a top-rated orthopedic specialist in Las Vegas?</p><p class='small-text'>Schedule an appointment with Optum Care Orthopaedics and Spine today.</p>",
        providersList: [
            {
                id:1,
                providerName: "Galen Kam, MD",
                link: "/providers/galen-kam"
            },

        ],
        button: {
            id: 1,
            link: 'tel:17027248877',
            text: 'Call: 1-702-724-8877'
        }
    }
}

export default ELBOW_DATA;
