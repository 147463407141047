import image1 from "../../../img/figma/knee-1.png";
import image2 from "../../../img/figma/knee-2.png";
import headerImage from "../../../img/figma/knee_banner.png";
import '../specialties.styles.scss';

const KNEE_DATA = {
    pageTitle: 'Knee',
    headerComponent: {
        imageUrl: headerImage,
        title: "Knee",
        innerCopy: "Care and treatment to relieve pain and improve your quality of life",
        shouldHideHomeButton: true
    },
    textSection1: {
        bodyTextLine1: "The knee contains some of the largest and most complex joints in the human body. We rely on the bones, tendons, ligaments and surrounding muscles that make up the knee to perform everyday tasks and activities. The knee joint supports our entire weight each day. It also allows flexible movement of the lower leg for walking, playing sports, going up stairs, dancing, and everything in between.",
        bodyTextLine2: "Over time and with enough continual stress, the knee can become vulnerable and prone to injury. Our highly-trained knee specialists aim to get you pain free and back to your active lifestyle. Our physicians offer a wide range of treatment options and are happy to explore nonsurgical options to suit your personalized needs."
    },
    interiorSections: [
        {
            title: "Conditions",
            bodyCopy: "Knee conditions we treat include: ",
            htmlCopy: "<ul><li>Arthritis</li><li>Sprains, strains, dislocations, and fractures</li><li>Ripped or torn tendons</li><li>Trauma or injury to the cartilage or bone</li><li>Patellar tracking disorder</li><li>Patellofemoral pain syndrome</li><li>Torn anterior cruciate ligament</li><li>Fracture to the patella (kneecap)</li><li>Anterior cruciate ligament (ACL) injury</li><li>Posterior cruciate ligament (PCL) injury</li><li>Sports-related knee Injury and overuse syndromes</li><li>MCL Tears</li><li>ACL Tears</li><li>Osteolysis</li></ul>",
            imageUrl: image1,
            id: 1
        },
        {
            title: "Treatment",
            htmlCopy: "<ul><li>Partial and total knee replacement</li><li>ACL, PCL, and MCL reconstruction</li><li>Arthroplasty</li><li>Arthroscopic knee surgery</li><li>Knee replacement and revision</li><li>Ligament repair and reconstruction</li><li>Meniscal repair</li></ul>",
            imageUrl: image2,
            customClassDiv: 'reverse-row',
            id: 2
        }
    ],
    textSection2: {
        bodyTextLine1: "At Optum Care Orthopaedics and Spine, our specialists understand that knee pain can affect a patient’s overall quality of life. We always recommend conservative or minimally invasive treatments first. When an injury or condition requires more advanced treatment, we strive to offer patients resources and options so they can make informed decisions based on their unique needs and goals. Our surgeons use advanced technology with the goal of full recovery."
    },
    providersCta: {
        htmlCopy: `<h3>Schedule an appointment</h3>
                    <p>Ready to talk with a top-rated orthopedic specialist in Las Vegas?</p>
                    <p class="small-text">Schedule an appointment with Optum Care Orthopaedics and Spine today.</p>
                  `,
        providersList: [
            {
                id:1,
                providerName: "Anthony Bratton, MD",
                link: "/providers/anthony-bratton"
            },
            {
                id:2,
                providerName: "Karen Nelson, DO",
                link: "/providers/karen-nelson"
            },
            {
                id: 3,
                providerName: "Benjamin Hansen, MD",
                link: "/providers/benjamin-hansen"
            },
            {
                id: 4,
                providerName: "Brittany Hough, PA-C",
                link: "/providers/brittany-hough"
            }
        ],
        button: {
            id: 1,
            link: 'tel:17027248877',
            text: 'Call: 1-702-724-8877'
        }
    }
}

export default KNEE_DATA;
