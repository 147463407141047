import { createSelector } from 'reselect';
import placeHolderMaleGrid from "../../img/providers/grid/male_placeholder_300x300.jpg";
import malePlaceholder from "../../img/figma/Male-Placeholder.png";
import placeHolderFemaleGrid from "../../img/providers/grid/female_placeholder_300x300.jpg";
import femalePlaceholder from "../../img/figma/Female-Placeholder.png";

const PROVIDER_ID_MAP = {
    'adrian-tripp': 1,
    'lorindha-argudo': 2,
    'marc-ghabbour': 3,
    'danny-lock': 4,
    'renee-poole': 5,
    'nicole-cheung': 6,
    'claudia-pfeil': 7,
    'ronica-patel': 8,
    'siran-sirop': 9,
    'gustavo-estrella': 10,
    'julio-hernandez': 11,
    'sarah-gahng': 12,
    'melvin-rivera': 13,
    'lilia-zamora': 14,
    'maria-garcia-sioson': 15,
    'vincent-wu': 16,
    'feliks-avanesyan': 17,
    'katarzyna-nasiadko': 18,
    'alwin-carl': 19,
    'taura-michelle': 20,
    'removed-001': 21,
    'cynthia-pike': 22,
    'javier-moncayo': 23,
    'removed-002': 24,
    'isil-smith': 25,
    'muhammad-nouh': 26,
    'haivy-nguyen': 27,
    'wail-shoga': 28,
    'johana-flores-rios': 29,
    'hung-phan': 30,
    'kevin-urmaza': 31,
    'virpal-gill': 32,
    'jan-nguyen': 33,
    'valerie-green': 34,
    'jeffrey-pan': 35,
    'stephanie-brianne': 36,
    'daniel-placik': 37,
    'keith-kagahastian': 38,
    'philip-simmons': 39,
    'rafik-shereen': 40,
    'john-d-ro': 41,
    'steven-j-becker': 42,
    'steven-l-smith': 43,
    'victor-caluya': 44,
    'kelly-gee': 45,
    'bakul-patel': 46,
    'tracy-pantig': 47,
    'mahsa-safavi': 48,
    'allen-jahroumi': 49,
    'michael-karp': 50,
    'lynette-asselin': 51,
    'diane-bedrosian': 52,
    'michael-krak': 53,
    'truongson-nguyen': 54,
    'daisha-ortega': 55,
    'patrick-soriente': 56,
    'stephanie-huang': 57,
    'zaw-ye': 58,
    'josh-baek': 59,
    'jamshid-akhavan': 60,
    'arpine-bagerdjian': 61,
    'jessica-dimian': 62,
    'yohannes-assefa': 63,
    'ben-nguyen': 64,
    'rolf-knapp': 65,
    'elmo-agatep': 66,
    'richard-aldana': 67,
    'gina-rocillo': 68,
    'susan-tang': 69,
    'pratik-ghandi': 70,
    'mahshid-elmzadeh': 71,
    'mitchell-wainwright': 72,
    'steve-yoo': 73,
    'faranak-ghazi': 74,
    'imran-shaikh': 75,
    'arielle-tucker-removed': 76,
    'patricio-guaiquil': 77,








}

const selectProviders = state => state.provider;

export const selectProviderSections = createSelector(
    [selectProviders],
    provider => provider.sections
)

export const selectIndividualProvider = providerUrlParam =>
    createSelector(
        [selectProviderSections],
        providers =>
            providers.find(
              provider => provider.id === PROVIDER_ID_MAP[providerUrlParam]
        )
    );
