import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
//import './index.css';
import reportWebVitals from './reportWebVitals';
import store from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import ReactGA from 'react-ga';
ReactGA.initialize(''); // add your tracking id here.
ReactGA.pageview(window.location.pathname + window.location.search);



ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a functionnp
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
