import image1 from "../../../img/figma/Joint_replacement-1.png";
import image2 from "../../../img/figma/Joint_replacement-2.png";
import headerImage from "../../../img/figma/Joint_replacement_banner.png";
import '../specialties.styles.scss';

const JOINT_REPLACEMENT_REVISION_DATA = {
    pageTitle: 'Joint replacement and revision',
    headerComponent: {
        imageUrl: headerImage,
        title: "Joint replacement and revision",
        innerCopy: "Care and treatment to relieve pain and improve your quality of life",
        shouldHideHomeButton: true
    },
    textSection1: {
        bodyTextLine1: "Joints are two or more bones joined by soft tissue. The three main functions include joining the bones together, as well as allowing the body to move and bear weight. Joint pain can come from repetitive stress, disease, injury, and the normal wear and tear that comes with age. We strive to find nonsurgical solutions and minimally invasive treatments. However, some cases require joint replacement to reduce pain and improve a patient’s quality of life.",
        bodyTextLine2: "At Optum Care Orthopaedics and Spine, we perform a high volume of complex revision surgeries. Our average is double the average of a standard practice, meaning that patients trust us to correct other surgeons’ mistakes. Each joint implant has a different lifespan, and occasionally, they need to be replaced. Joint revisions are performed when implants need to be replaced or when the implant doesn’t adapt to the surrounding structures due to various factors.",
    },
    interiorSections: [
        {
            title: "Conditions",
            bodyCopy: "Joint replacement and revision conditions we treat include: ",
            htmlCopy: "<ul><li>Cartilage degeneration</li><li>Deformities</li><li>Fractures</li><li>Joint dislocation</li><li>Osteoarthritis</li><li>Trauma-related injuries</li></ul>",
            imageUrl: image1,
            id: 1
        },
        {
            title: "Treatment",
            htmlCopy: "<ul><li>Direct anterior hip replacement </li><li>Hip replacement and revision</li><li>Injections</li><li>Knee replacement and revision</li><li>Total joint replacement of the hip or knee</li></ul>",
            imageUrl: image2,
            customClassDiv: 'reverse-row',
            id: 2
        }
    ],
    textSection2: {
        bodyTextLine1: "At Optum Care Orthopaedics and Spine, we provide you with the right joint replacement and revision specialist. Our specialists perform conservative or minimally invasive treatments first. When an injury or condition requires more advanced treatment, we offer patients resources and options so they can make informed decisions based on their unique needs and goals. Our surgeons use innovative techniques and the latest technology with the goal of full recovery.",
    },
    providersCta: {
        htmlCopy: `<h3>Schedule an appointment</h3><p>Ready to talk with a top-rated orthopedic specialist in Las Vegas?</p><p class="small-text">Schedule an appointment with Optum Care Orthopaedics and Spine today.</p>`,
        providersList: [
            {
                id:1,
                providerName: "Erik N. Kubiak, MD",
                link: "/providers/erik-kubiak"
            },
            {
                id: 2,
                providerName: "Benjamin Hansen, MD",
                link: "/providers/benjamin-hansen"
            }
        ],
        button: {
            id: 1,
            link: 'tel:+17027248877',
            text: 'Call: 1-702-724-8877'
        }
    }
}

export default JOINT_REPLACEMENT_REVISION_DATA;
