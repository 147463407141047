import React, {useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import FormComponent from "../form/firebase-form.component";

function ModalComponent() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <a className={"text-center font-body-3 font-blue"} onClick={handleShow}><b>Request a callback</b></a>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <FormComponent />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Back
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalComponent