const config = {
    apiKey: "AIzaSyAhIOVNgGEQ4ZfeELSmTcI8HAz-4k0wmEg",
    authDomain: "optum-ca-regional-microsite.firebaseapp.com",
    databaseURL: "https://optum-ca-regional-microsite-default-rtdb.firebaseio.com",
    projectId: "optum-ca-regional-microsite",
    storageBucket: "optum-ca-regional-microsite.appspot.com",
    messagingSenderId: "539798943660",
    appId: "1:539798943660:web:7a1d25546e91a4476c6c09",
    measurementId: "G-WV5BDEMSNW"
}

export default config

// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
/* const firebaseConfig = {
    apiKey: "AIzaSyAhIOVNgGEQ4ZfeELSmTcI8HAz-4k0wmEg",
    authDomain: "optum-ca-regional-microsite.firebaseapp.com",
    projectId: "optum-ca-regional-microsite",
    storageBucket: "optum-ca-regional-microsite.appspot.com",
    messagingSenderId: "539798943660",
    appId: "1:539798943660:web:7a1d25546e91a4476c6c09",
    measurementId: "G-WV5BDEMSNW"
};
*/

