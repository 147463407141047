import { createSelector } from 'reselect';

const REGION_ID_MAP = {
        'east-san-gabriel-valley': 1,
        'la-downtown': 2,
        'san-fernando-valley': 3,
        'long-beach': 4,
        'south-bay': 5,
        'south-oc': 6,
        'north-oc': 7,
        "vista": 8,
        'west-san-gabriel-valley': 9,
}

const selectRegions = state => state.region;

export const selectRegionSections = createSelector(
    [selectRegions],
    region => region.sections
)

export const selectIndividualRegion = regionUrlParam =>
    createSelector(
        [selectRegionSections],
        regions =>
            regions.find(
              region => region.id === REGION_ID_MAP[regionUrlParam]
        )
    );
