import image1 from "../../../img/figma/oncology5050_1.png";
import image2 from "../../../img/figma/oncology5050_2.png";
import headerImage from "../../../img/figma/oncology_banner.png";
import '../specialties.styles.scss';

const ONCOLOGY_DATA = {
    pageTitle: 'Oncology',
    headerComponent: {
        imageUrl: headerImage,
        title: "Oncology",
        innerCopy: "Care and treatment to improve your quality of life",
        shouldHideHomeButton: true
    },
    textSection1: {
        bodyTextLine1: "Oncologic orthopedics deals with diagnosing and treating conditions that affect soft tissue or the bone. Our team has extensive experience in treating all conditions of the bone for pediatric and adult patients. The specialist will review all your options and provide educational resources to develop a treatment plan personalized to your condition. For more complex cases, we take a multi-specialty approach which includes a tumor board of medical oncologists, radiation oncologists, radiologists and plastic surgeons working together to determine the best course of action.",
        bodyTextLine2: "Our focus is delivering the right care to every patient experiencing oncologic orthopedic-related conditions. We’re continuously learning, conducting research and testing the most innovative technologies. This ensures we’re meeting the highest standards of care that our patients deserve."
    },
    interiorSections: [
        {
            title: "Conditions",
            bodyCopy: "We treat the following conditions: ",
            htmlCopy: "<ul><li>Sarcomas</li><li>Benign bone cysts</li><li>Soft tissue masses</li></ul>",
            imageUrl: image1,
            id: 1
        },
        {
            title: "Treatment",
            htmlCopy: "<ul><li>Metastatic disease management</li><li>Bone stabilization surgery</li><li> Bone revision surgery</li><li>Limb sparing surgery</li></ul>",
            imageUrl: image2,
            customClassDiv: 'reverse-row',
            id: 2
        }
    ],
    textSection2: {
        bodyTextLine1: "At OptumCare Orthopaedics and Spine, our specialists understand the stress that comes with an oncologic orthopedic condition. We strive to treat conservatively with minimally invasive treatments. When a condition requires more advanced treatment, we offer patients options and tools to choose the best course of action for their unique health goals. Our specialists use the latest technology with the goal of complete recovery."
    },
    providersCta: {
        htmlCopy: "<h3>Schedule an appointment</h3><p>Ready to talk with a top-rated orthopedic oncologist in Las Vegas?</p><p class='small-text'>Schedule an appointment with Optum Care Orthopaedics and Spine today.</p>",
        providersList: [
            {
                id:1,
                providerName: "John DeVries, MD",
                link: "/providers/john-deVries"
            },

        ],
        button: {
            id: 1,
            link: 'tel:17027248877',
            text: 'Call: 1-702-724-8877'
        }
    }
}

export default ONCOLOGY_DATA;
