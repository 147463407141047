const LOCATIONS_DATA = {
    sections: [
            {
                id: 1,
                title: 'Optum - Covina Main',
                address1: '420 W. Rowland St. ',
                address2: 'Covina, CA 91723',
                phone: '1-626-331-6411ß',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided\r\nOn-site IV services available\r\nOn-site laboratory draw and X-ray services available\r\nUrgent care center on-site',
                region: 'San Gabriel Valley',
                type: 'Clinic'
            },
            {
                id: 2,
                title: 'Optum - Diamond Bar',
                address1: '750 N. Diamond Bar Blvd. #100',
                address2: 'Diamond Bar, CA 91765',
                phone: '1-909-861-2675',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided',
                region: 'San Gabriel Valley',
                type: 'Clinic'
            },
            {
                id: 3,
                title: 'Optum - Glendora Foothill',
                address1: '552 W. Foothill Blvd. #100',
                address2: 'Glendora, CA 91741',
                phone: '1-626-335-4079',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided',
                region: 'San Gabriel Valley',
                type: 'Clinic'
            },
            {
                id: 4,
                title: 'Optum - La Verne',
                address1: '3833 Emerald Ave. ',
                address2: 'La Verne, CA 91750',
                phone: '1-909-593-4531',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided',
                region: 'San Gabriel Valley',
                type: 'Clinic'
            },
            {
                id: 5,
                title: 'Optum - Pasadena Main',
                address1: '675 S. Arroyo Pkwy. #330',
                address2: 'Pasadena, CA 91105',
                phone: '1-844-947-1734',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nFree parking available with validation',
                region: 'San Gabriel Valley',
                type: 'Clinic'
            },
            {
                id: 6,
                title: 'Optum - Temple City',
                address1: '9810 Las Tunas Dr. ',
                address2: 'Temple City, CA 91780',
                phone: '1-626-309-7600',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided\r\nOn-site X-ray services available',
                region: 'San Gabriel Valley',
                type: 'Clinic'
            },
            {
                id: 7,
                title: 'Optum - Arcadia',
                address1: '450 E. Huntington Dr. ',
                address2: 'Arcadia, CA 91006',
                phone: '1-844-947-1734',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided\r\nOn-site X-ray services available',
                region: 'San Gabriel Valley',
                type: 'Clinic'
            },
            {
                id: 8,
                title: 'Optum - Glen Oaks',
                address1: '988 S. Fair Oaks Ave. ',
                address2: 'Pasadena, CA 91105',
                phone: '1-626-799-4191',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided\r\nOn-site X-ray services available',
                region: 'San Gabriel Valley',
                type: 'Clinic'
            },
            {
                id: 9,
                title: 'Optum - Verdugo',
                address1: '1808 Verdugo Blvd. #305',
                address2: 'Glendale, CA 91208',
                phone: '1-818-790-1103',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided',
                region: 'San Gabriel Valley',
                type: 'Clinic'
            },
            {
                id: 10,
                title: 'Optum - East Los Angeles',
                address1: '3800 E. First St. ',
                address2: 'Los Angeles, CA 90063',
                phone: '1-323-261-7520',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided\r\nOn-site laboratory draw available',
                region: 'LA/Downtown',
                type: 'Clinic'
            },
            {
                id: 11,
                title: 'Optum - Downtown LA',
                address1: '1120 W. Washington Blvd. ',
                address2: 'Los Angeles, CA 90015',
                phone: '1-213-623-2225',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided\r\nOn-site IV services available\r\nOn-site laboratory draw and X-ray services available\r\nUrgent care center on-site',
                region: 'LA/Downtown',
                type: 'Clinic'
            },
            {
                id: 12,
                title: 'Optum - Montebello',
                address1: '2603 Via Campo. ',
                address2: 'Montebello, CA 90640',
                phone: '1-323-720-1144',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided\r\nOn-site IV services available\r\nOn-site laboratory draw and X-ray available\r\nUrgent care center on-site',
                region: 'LA/Downtown',
                type: 'Clinic'
            },
            {
                id: 13,
                title: 'Optum - Monterey Park',
                address1: '880 S. Atlantic Blvd. #101',
                address2: 'Monterey Park, CA 91754',
                phone: '1-626-281-6969',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided\r\nOn-site laboratory draw and X-ray services available',
                region: 'LA/Downtown',
                type: 'Clinic'
            },
            {
                id: 14,
                title: 'Optum - Descanso',
                address1: '1818 Verdugo Blvd. #200',
                address2: 'Glendale, CA 91208',
                phone: '1-818-790-1088',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided\r\nOn-site IV services available\r\nOn-site laboratory draw available',
                region: 'LA/Downtown',
                type: 'Clinic'
            },
            {
                id: 15,
                title: 'Optum - Northridge',
                address1: '18546 Roscoe Blvd. #300',
                address2: 'Northridge, CA 91324',
                phone: '1-855-464-6505',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided',
                region: 'San Fernando Valley',
                type: 'Clinic'
            },
            {
                id: 16,
                title: 'Optum - Burbank',
                address1: '2211 W. Magnolia Blvd. #120',
                address2: 'Burbank, CA 91506',
                phone: '1-818-955-5773',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided\r\nOn-site laboratory draw available',
                region: 'San Fernando Valley',
                type: 'Clinic'
            },
            {
                id: 17,
                title: 'Optum - Valencia',
                address1: '23501 Cinema Dr. #100',
                address2: 'Valencia, CA 91355',
                phone: '1-855-464-6505',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: '\r\nEasy free on-site parking provided',
                region: 'San Fernando Valley',
                type: 'Clinic'
            },
            {
                id: 18,
                title: 'Optum - Van Nuys',
                address1: '14600 Sherman Way #300',
                address2: 'Van Nuys, CA 91405',
                phone: '1-818-781-7097',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided\r\nOn-site laboratory draw and X-ray services available',
                region: 'San Fernando Valley',
                type: 'Clinic'
            },
            {
                id: 19,
                title: 'Optum - West Hills',
                address1: '7301 Medical Center Dr. Ste. 500',
                address2: 'West Hills, CA 91307',
                phone: '1-818-226-3666',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Easy free on-site parking provided\r\nOn-site laboratory draw available',
                region: 'San Fernando Valley',
                type: 'Clinic'
            },
            {
                id: 20,
                title: 'Optum - San Gabriel',
                address1: '207 S. Santa Anita Ste. 205',
                address2: 'San Gabriel, CA 91776',
                phone: '1-844-947-1734',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nFree parking available with validation\r\nOn-site laboratory draw and X-ray services available',
                region: 'San Gabriel Valley',
                type: 'Clinic'
            },
            {
                id: 21,
                title: 'Optum - Monrovia',
                address1: '831 E Huntington Dr. Ste. 101',
                address2: 'Monrovia CA 91016',
                phone: '1-626-358-0269',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: '',
                region: 'San Gabriel Valley',
                type: 'Clinic'
            },
            {
                id: 22,
                title: 'Optum - Spring Street',
                address1: '6226 E. Spring St. #100',
                address2: 'Long Beach, CA 90815',
                phone: '1-562-420-1338',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided',
                region: 'Long Beach',
                type: 'Clinic'
            },
            {
                id: 23,
                title: 'Optum - Downtown Long Beach',
                address1: '1081 Long Beach Blvd. ',
                address2: 'Long Beach, CA 90813',
                phone: '1-562-432-5661',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided',
                region: 'Long Beach',
                type: 'Clinic'
            },
            {
                id: 24,
                title: 'Optum - Airport Plaza',
                address1: '4910 Airport Plaza Dr. #100',
                address2: 'Long Beach, CA 90815',
                phone: '1-562-429-2473',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided',
                region: 'Long Beach',
                type: 'Clinic'
            },
            {
                id: 25,
                title: 'Optum - Lakewood ',
                address1: '5001 Lakewood Blvd.',
                address2: 'Lakewood, CA 90712',
                phone: '1-866-488-5203',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided',
                region: 'Long Beach',
                type: 'Clinic'
            },
            {
                id: 26,
                title: 'Optum - Downey',
                address1: '8311 Florence Ave.',
                address2: 'Downey, CA 90240',
                phone: '1-866-488-5203',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided\r\nOn-site laboratory draw available',
                region: 'Long Beach',
                type: 'Clinic'
            },
            {
                id: 27,
                title: 'Optum - Willow',
                address1: '2600 Redondo Ave.',
                address2: 'Long Beach, CA 90806',
                phone: '1-562-988-7000',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided\r\nOn-site IV services available\r\nOn-site X-ray services available\r\nUrgent care center on-site',
                region: 'Long Beach',
                type: 'Clinic'
            },
            {
                id: 28,
                title: 'Optum - Bixby Knolls',
                address1: '3932 Long Beach Blvd.',
                address2: 'Long Beach, CA 90807',
                phone: '1-562-304-2100',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided',
                region: 'Long Beach',
                type: 'Clinic'
            },
            {
                id: 29,
                title: 'Optum - Compton',
                address1: '818 W. Alondra Blvd.  ',
                address2: 'Compton, CA 90220',
                phone: '1-310-537-1337',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided',
                region: 'Long Beach',
                type: 'Clinic'
            },
            {
                id: 30,
                title: 'Optum - Del Amo',
                address1: '3565 Del Amo Blvd. ',
                address2: 'Torrance, CA 90503',
                phone: '1-310-214-0811',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided\r\nOn-site X-ray services available',
                region: 'South Bay',
                type: 'Clinic'
            },
            {
                id: 31,
                title: 'Optum - Western San Pedro',
                address1: '900 N. Western Ave. ',
                address2: 'San Pedro, CA 90732',
                phone: '1-310-832-4225',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided\r\nOn-site X-ray services available',
                region: 'South Bay',
                type: 'Clinic'
            },
            {
                id: 32,
                title: 'Optum - Gardena',
                address1: '1212 W. Redondo Beach Blvd. ',
                address2: 'Gardena, CA 90247',
                phone: '1-310-324-2592',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided\r\nOn-site X-ray services available',
                region: 'South Bay',
                type: 'Clinic'
            },
            {
                id: 33,
                title: 'Optum - Carson',
                address1: '824 E. Carson St. #104',
                address2: 'Carson, CA 90745',
                phone: '1-310-830-9706',
                hours: 'Mon-Fri 9:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided\r\nOn-site X-ray services available',
                region: 'South Bay',
                type: 'Clinic'
            },
            {
                id: 34,
                title: 'Optum - Manhattan Beach',
                address1: '5400 W. Rosecrans Ave. #100',
                address2: 'Hawthorne, CA 90250',
                phone: '1-310-643-8500',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided\r\nOn-site X-ray services available',
                region: 'South Bay',
                type: 'Clinic'
            },
            {
                id: 35,
                title: 'Optum -Torrance',
                address1: '2200 Sepulveda Blvd. ',
                address2: 'Torrance, CA 90501',
                phone: '1-844-958-4189',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided\r\nOn-site X-ray services available',
                region: 'South Bay',
                type: 'Clinic'
            },
            {
                id: 36,
                title: 'NO LOCATION'
            },
            {
                id: 37,
                title: 'Optum - Seal Beach Village',
                address1: '13930 Seal Beach Blvd. ',
                address2: 'Seal Beach, CA 90740',
                phone: '1-866-324-0903',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided\r\nOn-site X-ray services available',
                region: 'North OC',
                type: 'Clinic'
            },
            {
                id: 38,
                title: 'Optum - Los Alamitos',
                address1: '3460 Katella Ave. ',
                address2: 'Los Alamitos, CA 90720',
                phone: '1-866-324-0903',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided\r\nOn-site X-ray services available',
                region: 'North OC',
                type: 'Clinic'
            },
            {
                id: 39,
                title: 'Optum - Buena Park',
                address1: '6900 Lincoln Ave. ',
                address2: 'Buena Park, CA 90620',
                phone: '1-866-324-0903',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided',
                region: 'North OC',
                type: 'Clinic'
            },
            {
                id: 40,
                title: ''
            },
            {
                id: 41,
                title: 'Optum - Laguna Hills',
                address1: '23521 Paseo De Valencia Ste. 311',
                address2: 'Laguna Hills, CA 92653',
                phone: '1-949-305-2660',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided',
                region: 'South OC',
                type: 'Clinic'
            },
            {
                id: 42,
                title: 'Optum - Foothill Ranch',
                address1: '26740 Towne Centre Dr. Bldg. C',
                address2: 'Foothill Ranch, CA 92610',
                phone: '1-949-588-9293',
                hours: 'Mon-Fri 7:30AM - 4:30PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided',
                region: 'South OC',
                type: 'Clinic'
            },
            {
                id: 43,
                title: 'Optum - San Clemente',
                address1: '724 South El Camino Real  ',
                address2: 'San Clemente, CA 92672',
                phone: '1-949-493-6113',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided',
                region: 'South OC',
                type: 'Clinic'
            },
            {
                id: 44,
                title: 'Optum - Rancho Santa Margarita',
                address1: '29873 Santa Margarita Pkwy. Ste. 100',
                address2: 'Rancho Santa Margarita, CA 92688',
                phone: '1-949-709-0988',
                hours: 'Mon-Fri 7:30AM - 4:30PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided',
                region: 'South OC',
                type: 'Clinic'
            },
            {
                id: 45,
                title: 'NO LOCATION'
            },
            {
                id: 46,
                title: 'NO LOCATION'
            },
            {
                id: 47,
                title: 'NO LOCATION'
            },
            {
                id: 48,
                title: 'Optum - Mission Viejo',
                address1: '26991 Crown Valley Pkwy #100',
                address2: 'Mission Viejo, CA 92691',
                phone: '1-844-939-3730',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided\r\nOn-site IV services available\r\nOn-site laboratory draw available',
                region: 'South OC',
                type: 'Clinic'
            },
            {
                id: 49,
                title: 'Optum Care Medical Group-Vista Pediatrics',
                address1: '2067 W. Vista Way Ste. 280',
                address2: 'Vista, CA 92083',
                phone: '1-833-934-3658',
                hours: 'Mon-Fri 8:30AM - 6:00PM',
                notes: '',
                region: 'Vista',
                type: 'Clinic'
            },
            {
                id: 50,
                title: 'Optum - Glendale',
                address1: '225 W. Broadway #100 ',
                address2: 'Glendale, CA 91204',
                phone: '1-844-326-7097',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided\r\nOn-site IV services available\r\nOn-site laboratory draw available',
                region: 'LA/Downtown',
                type: 'Clinic'
            },
            {
                id: 51,
                title: 'Optum - Canyon Country',
                address1: '17909 Soledad Canyon Rd. Ste. 100',
                address2: 'Canyon Country, CA 91387',
                phone: '1-661-367-3500',
                hours: 'Mon-Fri 9:00AM - 6:00PM',
                notes: 'Easy free on-site parking provided',
                region: 'San Fernando Valley',
                type: 'Clinic'
            },
            {
                id: 52,
                title: 'Optum - Vanowen',
                address1: '15216 Vanowen St. #1A',
                address2: 'Van Nuys, CA 91405',
                phone: '1-818-785-7875',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: 'Bilingual care team to support you\r\nEasy free on-site parking provided\r\nOn-site IV services available\r\nOn-site laboratory draw available',
                region: 'San Fernando Valley',
                type: 'Clinic'
            },
        {
            id: 53,
            title: 'Optum - Fairview',
            address1: '401 S. Fair Oaks Ave. ',
            address2: 'Pasadena, CA 91105',
            phone: '1-626-304-4400',
            hours: 'Mon-Fri 8:00AM - 5:00PM',
            notes: '',
            region: 'San Gabriel Valley',
            type: 'Clinic'
        },
        {
            id: 54,
            title: 'Optum - Glendora',
            address1: '2025 E. Route 66',
            address2: 'Glendora, CA 91740',
            phone: '1-626-335-4610',
            hours: 'Mon-Fri 8:00AM - 5:00PM',
            notes: '',
            region: 'San Gabriel Valley',
            type: 'Clinic'
        },
        {
            id: 55,
            title: 'Optum - Mission Hills',
            address1: '11600 Indian Hills Rd.',
            address2: 'Mission Hills CA 91345',
            phone: '1-818-838-4500',
            hours: 'Mon-Fri 8:00AM - 5:00PM',
            notes: '',
            region: 'San Fernando Valley',
            type: 'Clinic'
        },
        {
            id: 56,
            title: 'Optum - Redondo Beach 502',
            address1: '502 Torrance Blvd.',
            address2: 'Redondo Beach CA 90277',
            phone: '1-310-316-0811',
            hours: 'Mon-Fri 8:00AM - 5:00PM',
            notes: '',
            region: 'South Bay',
            type: 'Clinic'
        },
        {
            id: 57,
            title: 'Optum - Euclid',
            address1: '925 S Euclid St.',
            address2: 'Anaheim CA 92802',
            phone: '1-714-995-1000',
            hours: 'Mon-Fri 8:00AM - 5:00PM',
            notes: '',
            region: 'North OC',
            type: 'Clinic'
        },
        {
            id: 58,
            title: 'Optum - Cerritos',
            address1: '13357 South St.',
            address2: 'Cerritos CA 90703',
            phone: '1-562-379-8000',
            hours: 'Mon-Fri 8:00AM - 5:00PM',
            notes: '',
            region: 'North OC',
            type: 'Clinic'
        },
        {
            id: 59,
            title: 'Optum - Irvine',
            address1: '5581 Alton Pkwy.',
            address2: 'Irvine CA 92618',
            phone: '1-949-453-4308',
            hours: 'Mon-Fri 8:00AM - 5:00PM',
            notes: '',
            region: 'South OC',
            type: 'Clinic'
        },
        {
            id: 60,
            title: 'Optum - Ladera Ranch',
            address1: '800 Corporate Dr. Ste. 100',
            address2: 'Ladera Ranch CA 92694',
            phone: '1-949-364-9112',
            hours: 'Mon-Fri 8:00AM - 5:00PM',
            notes: '',
            region: 'South OC',
            type: 'Clinic'
        },
        {
            id: 61,
            title: 'Optum - Stanton',
            address1: '6261 Stanton Ave Fl 1 St.',
            address2: 'Buena Park	CA	90621',
            phone: '1-714-739-4325',
            hours: 'Mon-Fri 8:00AM - 5:00PM',
            notes: '',
            region: 'South OC',
            type: 'Clinic'
        },
        {
            id: 62,
            title: 'Optum - Lynwood',
            address1: '3628 E Imperial Hwy. Ste. 202',
            address2: 'Lynwood CA 90262',
            phone: '1-310-631-5000',
            hours: 'Mon-Fri 8:00AM - 5:00PM',
            notes: '',
            region: 'Long Beach',
            type: 'Clinic'
        },
{
            id: 63,
            title: 'Optum - Whittier',
            address1: '15111 Whittier Blvd. Ste. 102',
            address2: 'Whittier CA 90603',
            phone: '1-562-945-6440',
            hours: 'Mon-Fri 8:00AM - 5:00PM',
            notes: '',
            region: 'Long Beach',
            type: 'Clinic'
        },

        {
            id: 64,
            title: 'Optum - Orange',
            address1: '2540 N. Santiago Blvd.',
            address2: 'Orange CA 92867',
            phone: '1-714-921-1030',
            hours: 'Mon-Fri 8:00AM - 5:00PM',
            notes: '',
            region: 'North OC',
            type: 'Clinic'
        },
        {
            id: 65,
            title: 'Optum - Seal Beach Family',
            address1: '1198 Pacific Coast Hwy. Ste. I',
            address2: 'Seal Beach CA 90740',
            phone: '1-562-799-7071',
            hours: 'Mon-Fri 8:00AM - 5:00PM',
            notes: '',
            region: 'North OC',
            type: 'Clinic'
        },
        {
            id: 66,
            title: 'Optum - Cypress',
            address1: '10601 Walker St. Ste. 250',
            address2: 'Cypress CA 90630',
            phone: '1-714-252-8311',
            hours: 'Mon-Fri 8:00AM - 5:00PM',
            notes: '',
            region: 'North OC',
            type: 'Clinic'
        },
        {
            id: 67,
            title: 'Optum - Anaheim Hills',
            address1: '500 S Anaheim Hills Rd. Ste. 206',
            address2: 'Anaheim CA 92807',
            phone: '1-714-282-6934',
            hours: 'Mon-Fri 8:00AM - 5:00PM',
            notes: '',
            region: 'North OC',
            type: 'Clinic'
        },
        {
            id: 68,
            title: 'Optum - Roscoe',
            address1: '18546 Roscoe Blvd #300',
            address2: 'Northridge CA 91324',
            phone: '1-818-886-4028',
            hours: 'Mon-Fri 8:00AM - 5:00PM',
            notes: '',
            region: 'San Fernando Valley',
            type: 'Clinic'
        },


       /* {
            title: 'Optum - Covina Main',
            mapUrl: 'https://www.google.com/maps/embed/v1/place?q=4750%20W%20Oakey%20Blvd.%2C%20STE.%202B%20Las%20Vegas%2C%20NV%2089012&key=AIzaSyAjS1V-2CtBZj4tKu4Y4awhW6iWLti8D4E&zoom=15',
            linkUrl: 'https://www.google.com/maps/place/4750+W+Oakey+Blvd+%232b,+Las+Vegas,+NV+89102/@36.1522567,-115.2086582,17z/data=!3m1!4b1!4m5!3m4!1s0x80c8c143e13d9377:0x4af23471357d34ba!8m2!3d36.1522567!4d-115.2064695',
            address1: '420 W. Rowland St.',
            address2: 'Covina, CA 91723',
            address3: 'Located inside the Southwest Medical building, on the 2nd floor.',
            hours: 'Monday–Friday, 8 a.m–5 p.m.',
            phone: '1-702-724-8877',
            fax: '1-702-878-3952',
            services:'Elbow, General orthopedic care, Hip, Joints, Knee, Pelvis, Shoulder, Spine, Sports medicine, Trauma',
            id: 1,
            region: '',
        },
        {
            title: 'Oakey Healthcare Center',
            mapUrl: 'https://www.google.com/maps/embed/v1/place?q=4750%20W%20Oakey%20Blvd.%2C%20STE.%202B%20Las%20Vegas%2C%20NV%2089012&key=AIzaSyAjS1V-2CtBZj4tKu4Y4awhW6iWLti8D4E&zoom=15',
            linkUrl: 'https://www.google.com/maps/place/4750+W+Oakey+Blvd+%232b,+Las+Vegas,+NV+89102/@36.1522567,-115.2086582,17z/data=!3m1!4b1!4m5!3m4!1s0x80c8c143e13d9377:0x4af23471357d34ba!8m2!3d36.1522567!4d-115.2064695',
            address1: '4750 W. Oakey Blvd., Ste. 2B',
            address2: 'Las Vegas, NV 89012',
            address3: 'Located inside the Southwest Medical building, on the 2nd floor.',
            hours: 'Monday–Friday, 8 a.m–5 p.m.',
            phone: '1-702-724-8877',
            fax: '1-702-878-3952',
            services:'Elbow, General orthopedic care, Hip, Joints, Knee, Pelvis, Shoulder, Spine, Sports medicine, Trauma',
            id: 1
        },
        {
            title: 'Optum Care Cancer Center',
            mapUrl: 'https://www.google.com/maps/embed/v1/place?q=place_id:ChIJbwQdJenDyIAR4hiNqrAfAlI&key=AIzaSyAjS1V-2CtBZj4tKu4Y4awhW6iWLti8D4E&zoom=15',
            linkUrl: 'https://www.google.com/maps?ll=36.159456,-115.173352&z=15&t=m&hl=en-US&gl=US&mapclient=embed&q=2300+W+Charleston+Blvd+Las+Vegas,+NV+89102',
            address1: '2300 W. Charleston Blvd Ste. 2nd floor',
            address2: 'Las Vegas, NV 89102',
            address3: '',
            hours: 'Monday–Friday, 8 a.m–5 p.m.',
            phone: '1-702-724-8877',
            fax: '1-702-878-3952',
            services:'Orthopedic oncology',
            id: 2
        },
        {
            title: 'Eastern Healthcare Center',
            mapUrl: 'https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ71Sb4RjFyIARuW27YcbRNLU&key=AIzaSyAjS1V-2CtBZj4tKu4Y4awhW6iWLti8D4E',
            linkUrl: 'https://www.google.com/maps/place/Southwest+Medical+Eastern+Healthcare+Center/@36.1077501,-115.1228117,17z/data=!3m1!4b1!4m5!3m4!1s0x80c8c518e193758b:0x64d713ce69dd508d!8m2!3d36.1077501!4d-115.1206177',
            address1: '4475 S Eastern Ave',
            address2: 'Las Vegas, NV 89119 ',
            address3: 'Located inside the Southwest Medical building, on the 2nd floor.',
            hours: '',
            phone: '1-702-724-8877',
            fax: '1-702-878-3952',
            services:'General orthopedic care',
            id: 3

        }
        */
    ]
}

export default LOCATIONS_DATA;


