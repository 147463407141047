import React from 'react';
import ProviderThumbnail from "./provider-thumbnail/provider-thumbnail.component";
import "./provider-gallery.styles.scss"
import {connect} from "react-redux";
import { createStructuredSelector } from 'reselect';
import { selectProviderSections } from "../../redux/provider/provider.selectors";
import DividerArrowDownComponent from "../divider-arrow-down/divider-arrow-down.component";
// import { getProvidersBySpecialty } from "../../main";
import { getProvidersByRegion } from "../../main";

const ProviderGallery = ({ sections: providers }) => {




   /* var generalOrthopedics = getProvidersBySpecialty('General Orthopedics');

    var jointReplacementAndRevision = getProvidersBySpecialty('Joint replacement and revision');

    var sportsMedicine = getProvidersBySpecialty('Sports medicine');

    var shoulder = getProvidersBySpecialty('Shoulder');

    var elbow = getProvidersBySpecialty('Elbow');

    var wristAndHand = getProvidersBySpecialty('Wrist and hand');

    var spine = getProvidersBySpecialty('Spine');

    var hipsAndPelvis = getProvidersBySpecialty('Hips and pelvis');

    var knee = getProvidersBySpecialty('Knee');

    var footAndAnkle = getProvidersBySpecialty('Foot and ankle');

    var traumaAndFractureCare = getProvidersBySpecialty('Trauma and fracture care');

    var pediatric = getProvidersBySpecialty('Pediatric');
    
    var oncology = getProvidersBySpecialty('Oncology'); 
*/
        var sanGabrielValley = getProvidersByRegion('San Gabriel Valley');
        var sanGabrielValley2 = getProvidersByRegion('San Gabriel Valley2');
        var laDowntown = getProvidersByRegion('LA/Downtown');
        var sanFernandoValley = getProvidersByRegion('San Fernando Valley');
        var longBeach = getProvidersByRegion('Long Beach');
        var southBay = getProvidersByRegion('South Bay');
        var northOc = getProvidersByRegion('North OC');
        var southOc = getProvidersByRegion('South OC');

    return (
        <div className="provider-gallery-component">
            <DividerArrowDownComponent title="San Gabriel Valley" divId="san-gabriel-valley"/>
            <div className="row gallery-row centered-div">
                {
                    sanGabrielValley.map(({id, ...otherSectionProps}) => (
                        <ProviderThumbnail key={id} {...otherSectionProps} />
                    ))
                }
            </div>
            <DividerArrowDownComponent title="San Gabriel Valley" divId="san-gabriel-valley2"/>
            <div className="row gallery-row centered-div">
                {
                    sanGabrielValley2.map(({id, ...otherSectionProps}) => (
                        <ProviderThumbnail key={id} {...otherSectionProps} />
                    ))
                }
            </div>
            <DividerArrowDownComponent title="LA/Downtown"  divId="la-downtown"/>
            <div className="row gallery-row centered-div">
                {
                    laDowntown.map(({id, ...otherSectionProps}) => (
                        <ProviderThumbnail key={id} {...otherSectionProps} />
                    ))
                }
            </div>
            <DividerArrowDownComponent title="San Fernando Valley" divId="san-fernando-valley"/>
            <div className="row gallery-row centered-div">
                {
                    sanFernandoValley.map(({id, ...otherSectionProps}) => (
                        <ProviderThumbnail key={id} {...otherSectionProps} />
                    ))
                }
            </div>
            <DividerArrowDownComponent title="Long Beach" divId="long-beach"/>
            <div className="row gallery-row centered-div">
                {
                    longBeach.map(({id, ...otherSectionProps}) => (
                        <ProviderThumbnail key={id} {...otherSectionProps} />
                    ))
                }
            </div>
            <DividerArrowDownComponent title="South Bay" divId="south-bay"/>
            <div className="row gallery-row centered-div">
                {
                    southBay.map(({id, ...otherSectionProps}) => (
                        <ProviderThumbnail key={id} {...otherSectionProps} />
                    ))
                }
            </div>

                <DividerArrowDownComponent title="North OC" divId="north-oc"/>
                <div className="row gallery-row centered-div">
                        {
                                northOc.map(({id, ...otherSectionProps}) => (
                                    <ProviderThumbnail key={id} {...otherSectionProps} />
                                ))
                        }
                </div>
                <DividerArrowDownComponent title="South OC" divId="south-oc"/>
                <div className="row gallery-row centered-div">
                        {
                                southOc.map(({id, ...otherSectionProps}) => (
                                    <ProviderThumbnail key={id} {...otherSectionProps} />
                                ))
                        }
                </div>
        </div>)
};

const mapStateToProps = createStructuredSelector({
    sections: selectProviderSections
});

export default connect(mapStateToProps)(ProviderGallery);
