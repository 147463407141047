import React from 'react';
import CTAFooterTextComponent from "../cta-footer-text.component";
import ButtonComponent from "../../buttons/button.component";
import './cta-provider.styles.scss'

const CTAProviderComponent = ({imageUrl,htmlCopy, imageHeight, providersList, button }) => (
    <section className="bg-grey pt-0 mt-0 cta-footer cta-provider-component">
        <div className="row">
            <div className="col-lg-5 col-xl-4 col-md-5 col-12 no-padding">
                <img style={{height: imageHeight + 'px'}} src={`${imageUrl}`} className="img-fluid" width="100%" height="auto"
                     alt=""/>

            </div>
            <div className="col-12 col-lg-7 col-xl-8 col-md-7 interior-row-container text-div">
                {htmlCopy
                    ? < div dangerouslySetInnerHTML={{__html: htmlCopy}} />
                    : <CTAFooterTextComponent/>
                }
                <ul class = "cta-providers-list">
                    {
                        providersList.map( ({id, providerName, link}) => (
                            <li><a href={`${link ? `${link}` : '#' }`}>{providerName}</a></li>
                        ))
                    }
                </ul>
                <ButtonComponent
                    buttonCopy={button.text} buttonLink={button.link}
                    className="btn-alt"
                />
            </div>
        </div>
    </section>
);
export default CTAProviderComponent;
