import React from 'react';
import SpecialtyLayout from "../../../components/Layouts/SpecialtyPage.component";
import JOINT_REPLACEMENT_REVISION_DATA from "./joint-replacement-revision.data";

class JointReplacementRevisionPage extends React.Component {
    state = JOINT_REPLACEMENT_REVISION_DATA;
    render() {
        return(
            <SpecialtyLayout data={this.state}/>
        );
    }
}

export default JointReplacementRevisionPage;
