import image1 from "../../../img/figma/trauma_fracture-1.png";
import image2 from "../../../img/figma/trauma_fracture-2.png";
import '../specialties.styles.scss';
import headerImage from "../../../img/figma/wrist_hand_banner.png";

const TRAUMA_AND_FRACTURE_CARE_DATA = {
    pageTitle: 'Trauma and fracture care',
    headerComponent: {
        imageUrl: headerImage,
        title: "Trauma and fracture care",
        innerCopy: "Care and treatment to relieve pain and improve your quality of life",
        shouldHideHomeButton: true
    },
    textSection1: {
        bodyTextLine1: "Traumatic injuries are often unexpected. When they happen, we’re here for our patients. Whether injured on the job, on the road, at home or anywhere else, our highly specialized team can provide care when it’s most needed. We work on fractures both big and small, from casts to multiple injuries, and from surgery to complex issues such as non-healing and maligned fractures.",
        bodyTextLine2: "Our goal is to restore function as safely and quickly as possible. To ensure a smooth recovery, we collaborate integrally with a team of surgeons, physical therapists, medical consultants, pain specialists and plastic surgeons to deliver best-in-class care in every way possible. Our trauma specialists have received training with a focus on fractured bones and realigning joints in order to promote a recovery that is safe while enabling the body parts to regain function.",
    },
    interiorSections: [
        {
            title: "Conditions",
            bodyCopy: "Trauma and fracture care conditions we treat include: ",
            htmlCopy: "<ul><li>Acute injuries: accidents, falls, and high-impact collisions</li><li>Amputation care</li><li>Joint drainage</li><li>Limb malalignment</li><li>Non-healing fractures</li><li>Non-union</li><li>Resection or ablation of bone tumor, partial or complete resection of bone, debridement of bone</li></ul>",
            imageUrl: image1,
            id: 1
        },
        {
            title: "Treatment",
            htmlCopy: "<ul><li>Dislocation treatment</li><li>Fracture care and treatment</li><li>Physical therapy</li><li>Simple fracture care and casting</li><li>Surgery</li><li>Synovial biopsy</li><li>Viscosupplementation treatment</li></ul>",
            imageUrl: image2,
            customClassDiv: 'reverse-row',
            id: 2
        }
    ],
    textSection2: {
        bodyTextLine1: "At Optum Care Orthopaedics and Spine, our specialists understand that wrist and hand pain can affect many aspects of daily life. We always recommend conservative or minimally invasive treatments first. When an injury or condition requires more advanced treatment, we provide patients the resources and options to make an informed decision based on their unique needs and goals. Our surgeons use innovative techniques and the latest technology with the goal of full recovery.",
    },
    providersCta: {
        htmlCopy: `<h3>Schedule an appointment</h3>
                    <p>Ready to talk with a top-rated orthopedic specialist in Las Vegas?</p>
                    <p class="small-text">Schedule an appointment with Optum Care Orthopaedics and Spine today.</p>
                  `,
        providersList: [
            {
                id:1,
                providerName: "Brock Wentz, MD",
                link: "/providers/brock-wentz"
            },
            {
                id:2,
                providerName: "Karen Nelson, DO",
                link: "/providers/karen-nelson"
            },
            {
                id:3,
                providerName: "Shannon Boffeli, APRN",
                link: "/providers/shannon-boffeli"
            },
            {
                id:4,
                providerName: "Iain Elliott, MD",
                link: "/providers/iain-elliott"
            }
        ],
        button: {
            id: 1,
            link: 'tel:+17027248877',
            text: 'Call: 1-702-724-8877'
        }
    }
}

export default TRAUMA_AND_FRACTURE_CARE_DATA;
