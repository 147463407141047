import REGIONS_DATA from "../../pages/regional-pages/regions.data";

const INITIAL_STATE = REGIONS_DATA;

const regionReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        default:
            return state;
    }

}

export default regionReducer;
