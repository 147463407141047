import image1 from "../../../img/figma/pediatric-1.png";
import image2 from "../../../img/figma/pediatric-2.png";
import '../specialties.styles.scss';
import headerImage from "../../../img/figma/pediatric_banner.png";

const PEDIATRIC_DATA = {
    pageTitle: 'Pediatric',
    headerComponent: {
        imageUrl: headerImage,
        title: "Pediatric",
        innerCopy: "Care and treatment to relieve pain and improve your quality of life",
        shouldHideHomeButton: true
    },
    textSection1: {
        bodyTextLine1: "Children’s bodies are constantly growing and developing. For this reason, children require a unique and specialized approach to orthopedic care. Because joint pain is more common in adults, it can also be challenging to distinguish growing pains from more serious orthopedic concerns. The best way to respond to their concerns is to set up a consultation. Joint pain in children can be acute, chronic or congenital, such as birth defects like scoliosis or clubfoot.",
        bodyTextLine2: "Our advanced team of pediatric specialists understand childhood conditions and are trained to create a space of compassion and education while minimizing anxiety for both the child and their caregivers. This training, combined with state-of-the-art technology and technical expertise aims to keep children happy, healthy and active.",
    },
    interiorSections: [
        {
            title: "Conditions",
            bodyCopy: "Pediatric conditions we treat include: ",
            htmlCopy: "<ul><li>Amyoplasia/arthrogryposis</li><li>Benign bone tumors and cysts</li><li>Bone deformities</li><li>Cerebral palsy</li><li>Clubfoot</li><li>Constricting ring/band syndrome</li><li>Hip dysplasia</li><li>Foot malalignment (flatfeet, juvenile bunions, etc.)</li><li>Fractures and sprains</li><li>Joint dislocation</li><li>Juvenile idiopathic athritis</li><li>Infections of the bone</li><li>Limb malalignment (bowed legs, knock knees)</li><li>Limb length discrepancies</li><li>Myelomeningocele/spina bifida</li><li>Radical clubhand</li><li>Scoliosis</li><li>Sports- or trauma-related injuries</li><li>Toe walking</li><li>Trigger thumb/finger</li><li>Walking (gait) abnormalities</li></ul>",
            imageUrl: image1,
            id: 1
        },
        {
            title: "Treatment",
            htmlCopy: "<ul><li>Corrective surgery</li><li>Dislocation treatment</li><li>Fracture care and treatment</li><li>Synovial biopsy</li><li>Viscosupplementation treatment</li></ul>",
            imageUrl: image2,
            customClassDiv: 'reverse-row',
            id: 2
        }
    ],
    textSection2: {
        bodyTextLine1: "Optum Care Orthopaedics and Spine specialists understand that orthopedic concerns can affect the quality of a child’s life. We work to deliver conservative or minimally invasive treatments first. If more advanced treatment is required, we offer patients and their families guidance and options to make the best decisions for their loved ones. We help rehabilitate children and adolescents with the goal of restoring function as well as confidence.",
    },
    providersCta: {
        htmlCopy: `<h3>Schedule an appointment</h3>
                    <p>Ready to talk with a top-rated orthopedic specialist in Las Vegas?</p>
                    <p class="small-text">Schedule an appointment with Optum Care Orthopaedics and Spine today.</p>
                  `,
        providersList: [
            {
                id: 1,
                providerName: "Abby McCain Howenstein, MD",
                link: "/providers/abby-howenstein"
            }
        ],
        button: {
            id: 1,
            link: 'tel:17027248877',
            text: 'Call: 1-702-724-8877'
        }
    }
}

export default PEDIATRIC_DATA;
