import React from 'react';
import SpecialtyLayout from "../../../components/Layouts/SpecialtyPage.component";
import WRIST_AND_HAND_DATA from "./wrist-and-hand.data";

class WristHandPage extends React.Component {
    state = WRIST_AND_HAND_DATA;
    render() {
        return(
            <SpecialtyLayout data={this.state}/>
        );
    }
}

export default WristHandPage;
