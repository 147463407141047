import React from 'react';
import { withRouter } from 'react-router-dom';
import "./auxiliary.styles.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {faFacebook, faFacebookSquare, faInstagram, faYelp} from "@fortawesome/free-brands-svg-icons"


const AuxiliaryComponent = () => (
    <div className="container-fluid top-bar">
        <div className="row">
            <div className="d-flex col-12 justify-content-end">
                <ul className="social hide-mobile d-none">
                    <li>
                        <a href="https://instagram.com/optum" target="_blank">
                            <FontAwesomeIcon icon={faInstagram} size="lg"/>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.yelp.com/biz/optumcare-orthopaedics-and-spine-las-vegas" target="_blank">
                            <FontAwesomeIcon icon={faYelp} size="lg" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/myOptum/" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faFacebookSquare} size="lg"/>
                        </a>
                    </li>
                </ul>
                <span className="auxiliary-text-patient-portal"><a href = "https://www.optum.com/covid-19.html" target="_blank">ABOUT COVID-19</a></span>
                <span className="auxiliary-text-patient-portal"><a href = "https://cx.optum.com/content/engagement/contact-us/en.html" target="_blank">CONTACT US</a></span>

            </div>
        </div>
    </div>



)

export default withRouter(AuxiliaryComponent);
