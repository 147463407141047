import heroImage from '../../img/home/home-hero-header.png';

const HEADER_HERO_DATA = {
    info: [
        {
            id: 1,
            title: 'Classic Care Plan 25 (HMO)',
            text: "If you don't see your plan here, call our customer care line at 1-951-788-9800, TTY 711, Monday–Friday, 8:00 a.m.–5:00 p.m.* Dual Medi-Medi Plans Available",
            backgroundImg: heroImage,
            search: heroImage,
        },
    ],

}

export default HEADER_HERO_DATA;
